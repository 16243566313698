import * as pbi from 'powerbi-client';
import { Report, VisualDescriptor } from 'powerbi-client';
import {
  PowerBiSlicerFilterState,
  powerBiTheme,
  setCurrentPowerBIPage,
  setLoadingStatus,
  setPbiSlicerFilterStates,
  setPowerBiPages,
  useAppDispatch,
  useAppSelector,
  useCreateUserAuditLogMutation,
  useGetPowerBiQuery,
  UserAuditLog,
  UserAuditLogAction,
} from 'api';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISlicerState } from 'powerbi-models';

export const PowerBIReport = () => {
  // Hooks
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { mockUser } = useAppSelector(state => state.appUser);
  const mid = currentMenuItem?.id.toString() ?? '';
  const uid = mockUser?.id.toString() ?? '';
  const { currentPage, pbiSlicerFilterStates } = useAppSelector(
    state => state.powerBi
  );
  const [createAction] = useCreateUserAuditLogMutation();
  const dispatch = useAppDispatch();
  // TODO:: If we don't have a mid, we need to show an error
  const { data } = useGetPowerBiQuery({ mid, uid }, { skip: !mid || !uid });
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageParam = searchParams.get('Page');

  const embedContainer = useRef(null);
  const [currReport, setCurrReport] = useState<pbi.Embed>();
  const [, setNewPbiSlicerFilterState] =
    useState<PowerBiSlicerFilterState | undefined>();

  useEffect(() => {
    if (data && data.powerBISlicerState) {
      const initialPowerBiSlicerStates = JSON.parse(
        data.powerBISlicerState.slicerState
      );
      dispatch(setPbiSlicerFilterStates(initialPowerBiSlicerStates));
    }
    // eslint-disable-next-line
  }, [data?.powerBISlicerState]);

  useEffect(() => {
    if (data) {
      const embedConfig = {
        type: 'report',
        id: data?.reportId,
        embedUrl: data?.embedUrl,
        accessToken: data?.embedToken?.token,
        tokenType: pbi.models.TokenType.Embed,
        permissions: pbi.models.Permissions.All,
        theme: powerBiTheme,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
            pageNavigation: { visible: false },
          },
          background: pbi.models.BackgroundType.Transparent,
          customLayout: {
            displayOption: pbi.models.DisplayOption.FitToWidth,
          },
        },
      };

      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      if (embedContainer.current) {
        powerbi.reset(embedContainer.current);

        let report = powerbi.embed(embedContainer.current, embedConfig);
        setCurrReport(report);

        report?.on('loaded', event => {
          const element = document.getElementsByClassName('report-style-class');
          (element[0] as HTMLElement)!.style.visibility = 'visible';
          dispatch(setLoadingStatus(false));

          let currPowerBiSlicerStates: PowerBiSlicerFilterState[];

          if (pbiSlicerFilterStates && pbiSlicerFilterStates.length > 0) {
            currPowerBiSlicerStates = pbiSlicerFilterStates;
          } else if (data.powerBISlicerState) {
            currPowerBiSlicerStates = JSON.parse(
              data.powerBISlicerState.slicerState
            );
          }

          (report as Report)
            .getPages()
            .then(resp => {
              let slicerStates: PowerBiSlicerFilterState[] = [];

              resp.forEach(page => {
                if (page.isActive) {
                  page
                    .getVisuals()
                    .then((visuals: VisualDescriptor[]) => {
                      visuals.forEach((visual: VisualDescriptor) => {
                        if (visual.type === 'slicer') {
                          const foundSlicerState =
                            currPowerBiSlicerStates?.find(
                              x => x.VisualName === visual.name
                            );

                          if (foundSlicerState)
                            visual.setSlicerState({
                              filters: [foundSlicerState.FilterState],
                            } as ISlicerState);
                          else
                            visual
                              .getSlicerState()
                              .then((slicerState: ISlicerState) => {
                                if (slicerState.filters.length > 0) {
                                  slicerStates.push({
                                    VisualName: visual.name,
                                    FilterState: slicerState.filters[0],
                                  });
                                  setNewPbiSlicerFilterState({
                                    VisualName: visual.name,
                                    FilterState: slicerState.filters[0],
                                  });
                                }
                              })
                              .finally(() => {
                                dispatch(
                                  setPbiSlicerFilterStates([
                                    ...pbiSlicerFilterStates,
                                    ...slicerStates,
                                  ])
                                );
                              })
                              .catch(e => console.log(e));
                        }
                      });
                    })
                    .catch(e => console.log(e));
                }
              });

              let powerBiPage;

              if (pageParam) {
                const found = resp?.find(
                  page =>
                    page.displayName.toLocaleLowerCase() ===
                    pageParam.toLocaleLowerCase()
                );
                if (found) powerBiPage = found;
                else powerBiPage = resp[0];
              } else powerBiPage = resp[0];

              const auditLog: UserAuditLog = {
                action: UserAuditLogAction.View,
                target: '',
                metadata: `PowerBi report page initialized: ${powerBiPage.displayName}`,
              };
              createAction({ auditLog: auditLog });

              (report as Report)
                .setPage(powerBiPage.name)
                .then(() => {})
                .catch(errors => {
                  console.log(errors);
                });

              dispatch(setCurrentPowerBIPage(powerBiPage));
              dispatch(setPowerBiPages(resp));
              navigate(
                `/powerbi/${currentMenuItem?.id}?Page=${powerBiPage.displayName}`
              );
            })
            .catch(e => {
              console.log(e);
            });
        });

        report.on('error', (event: any) => {
          console.error(event.detail);
        });

        report.on('dataSelected', (event: any) => {
          let updatedSlicerStates: PowerBiSlicerFilterState[] = [];
          let slicerStatePromises: Promise<void>[] = [];

          (report as Report)
            .getPageByName(event.detail.page.name)
            .then(page => {
              return page.getVisuals();
            })
            .then((visuals: VisualDescriptor[]) => {
              visuals.forEach((visual: VisualDescriptor) => {
                if (visual.type === 'slicer') {
                  let slicerStatePromise = visual
                    .getSlicerState()
                    .then((slicerState: ISlicerState) => {
                      if (slicerState.filters.length > 0) {
                        updatedSlicerStates.push({
                          VisualName: visual.name,
                          FilterState: slicerState.filters[0],
                        });
                      } else {
                        updatedSlicerStates.push({
                          VisualName: visual.name,
                          FilterState: undefined,
                        });
                      }
                    });
                  slicerStatePromises.push(slicerStatePromise);
                }
              });

              return Promise.all(slicerStatePromises);
            })
            .then(() => {
              dispatch(setPbiSlicerFilterStates(updatedSlicerStates));
            })
            .catch((e: any) => {
              console.log(e);
            });
        });
      }
    }
    // eslint-disable-next-line
  }, [data, dispatch, mid, uid]);

  useEffect(() => {
    if (data && currentPage?.name && currReport) {
      (currReport as Report)
        .setPage(currentPage?.name)
        .then(() => {})
        .catch(errors => {
          console.log(errors);
        });

      const powerBiUrl =
        `/powerbi/${currentMenuItem?.id}?Page=${currentPage.displayName}` +
        (data?.powerBISlicerState?.powerBISlicerStateId
          ? `&SlicerStateId=${data?.powerBISlicerState?.powerBISlicerStateId}`
          : '');
      navigate(powerBiUrl);
    }
    // eslint-disable-next-line
  }, [currentPage]);

  if (!data?.reportId || !data?.embedUrl || !data?.embedToken) return <></>;

  return (
    <div
      id="reportContainer"
      ref={embedContainer}
      className="report-style-class"
    />
  );
};
