import { useEffect, useState } from 'react';
import { GridRowModel } from '@mui/x-data-grid-pro';
import {
  setAlert,
  useAppDispatch,
  useAppSelector,
  BaseRequestParams,
  TableData,
  initializeTableData,
  resetTableData,
  isValueOption,
  ValueOption,
  generateColumns,
  updateTableRows,
} from 'api';
import { AlertSeverity } from 'api/models/alert';
import {
  Provider,
  useGetProvidersQuery,
  useUpdateProviderMutation,
} from 'api/models/databricks/provider';
import { ActionType, DataGridTable } from 'app/common';
import { v4 as uuidv4 } from 'uuid';

export const ProviderTable = () => {
  // States
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // Hooks
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { mockUser } = useAppSelector(state => state.appUser);
  const mid = currentMenuItem?.id.toString() ?? '';
  const uid = mockUser?.id.toString() ?? '';
  const { data, isLoading, isFetching } = useGetProvidersQuery(
    { mid: mid, uid },
    { skip: mid === '-1' || uid === '-1' || isLoaded }
  );
  const { rows, columns } = useAppSelector(state => state.dataGridTable);

  const [updateProvider, { isLoading: isCreating }] =
    useUpdateProviderMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) setIsLoaded(true);
    dispatch(resetTableData());

    const tableData: TableData = {
      rows: data?.rows,
      columns: generateColumns(data?.columns),
    };

    dispatch(initializeTableData(tableData));
  }, [dispatch, data]);

  useEffect(() => {
    setIsRefetching(false);
  }, [mid]);

  const processRowUpdate = async (rowUpdate: GridRowModel) => {
    let updatedRow = { ...rowUpdate, isNew: false };
    const providerCertValueOptions = (
      columns.find(x => x.field === 'ooProviderCertification') as any
    )?.valueOptions;
    const providerSpecialtyValueOptions = (
      columns.find(x => x.field === 'ooProviderSpecialty') as any
    )?.valueOptions;

    const ooProviderCertification = !isValueOption(
      (rowUpdate as Provider).ooProviderCertification
    )
      ? providerCertValueOptions.find(
          (x: ValueOption) => x.value === rowUpdate.ooProviderCertification
        )
      : (rowUpdate as Provider).ooProviderCertification;
    const ooProviderSpecialty = !isValueOption(
      (rowUpdate as Provider).ooProviderSpecialty
    )
      ? providerSpecialtyValueOptions.find(
          (x: ValueOption) => x.value === rowUpdate.ooProviderSpecialty
        )
      : (rowUpdate as Provider).ooProviderSpecialty;

    if (mid) {
      const reqBody = {
        request: { mid, uid } as BaseRequestParams,
        provider: {
          ...(rowUpdate as Provider),
          ooProviderKey: rowUpdate.ooProviderKey.trim(),
          ooProviderCertification: ooProviderCertification,
          ooProviderSpecialty: ooProviderSpecialty,
        },
      };
      try {
        await updateProvider(reqBody)
          .unwrap()
          .then((resp: Provider[]) => {
            if (rows) {
              const updatedRows = rows.map((row: Provider) => {
                if (row.npi === resp[0].npi)
                  return { ...resp[0], id: uuidv4() };
                return row;
              });
              dispatch(updateTableRows(updatedRows));
            }

            dispatch(
              setAlert({
                message: 'Row updated.',
                severity: AlertSeverity.Success,
              })
            );
          });
      } catch (err: any) {
        dispatch(
          setAlert({
            message: err.response.data,
            severity: AlertSeverity.Error,
          })
        );
        return;
      }
    }

    return updatedRow;
  };

  return (
    <DataGridTable
      cellActions={[ActionType.Edit]}
      processRowUpdate={processRowUpdate}
      isBusy={isFetching || isLoading || isCreating}
      toolbarProps={{ enableFilter: true }}
      isRefetching={isRefetching}
    />
  );
};
