import { createApi } from '@reduxjs/toolkit/query/react';
import {
  convertMenuItemsToDisplay,
  convertMenuItemsToGridData,
  MenuItem,
  MenuItemRequest,
  MenuItems,
} from '.';
import { axiosBaseQuery, GridData } from '../../.';

export const menuItemApi = createApi({
  reducerPath: 'menuItemApi',
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    getMenuItems: builder.query<MenuItems, number | undefined>({
      query: userId => ({
        url: `menuItems/user/${userId}`,
        method: 'GET',
      }),
      transformResponse: (menuItems: MenuItem[]) =>
        convertMenuItemsToDisplay(menuItems),
    }),
    getMenuItemsByType: builder.query<GridData, number | undefined>({
      query: type => ({
        url: `menuItems/type/${type}`,
        method: 'GET',
      }),
      transformResponse: (menuItems: MenuItem[]) =>
        convertMenuItemsToGridData(menuItems),
    }),
    getAllMenuItems: builder.query<GridData, null>({
      query: () => ({
        url: 'menuItems',
        method: 'GET',
      }),
      transformResponse: (menuItems: MenuItem[]) =>
        convertMenuItemsToGridData(menuItems),
    }),
    getAllChildMenuItemsByParentId: builder.query<
      MenuItem[],
      number | undefined
    >({
      query: parentId => ({
        url: `menuItems/${parentId}/children`,
        method: 'GET',
      }),
    }),
    upsertMenuItem: builder.mutation<MenuItem, MenuItemRequest>({
      query: request => ({
        url: 'menuItems',
        method: 'PUT',
        data: request,
      }),
    }),
    addUserFavoriteMenuItem: builder.mutation<void, number>({
      query: mid => ({
        url: `menuItems/${mid}/favorite`,
        method: 'POST',
      }),
    }),
    deleteUserFavoriteMenuItem: builder.mutation<void, number>({
      query: mid => ({
        url: `menuItems/${mid}/favorite`,
        method: 'DELETE',
      }),
    }),
    getRecentlyViewedMenuItems: builder.query<MenuItem[], number | undefined>({
      query: userId => ({
        url: `menuItems/recently-viewed/${userId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetMenuItemsQuery,
  useGetMenuItemsByTypeQuery,
  useGetAllMenuItemsQuery,
  useGetAllChildMenuItemsByParentIdQuery,
  useUpsertMenuItemMutation,
  useAddUserFavoriteMenuItemMutation,
  useDeleteUserFavoriteMenuItemMutation,
  useGetRecentlyViewedMenuItemsQuery,
  endpoints: {
    getMenuItems: { useQueryState: useGetMenuItemsQueryState },
    getAllChildMenuItemsByParentId: {
      useQueryState: useGetParentMenuItemsQueryState,
    },
  },
} = menuItemApi;
