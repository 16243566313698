import { Check } from '@mui/icons-material';
import {
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import {
  setCurrentStep,
  setFirstStepState,
  setSecondStepState,
  setThirdStepState,
  useAppDispatch,
  useAppSelector,
} from 'api';
import { PageContainer } from 'app/common';
import React, { useEffect } from 'react';
import { PatientMappingFirstStep } from './wizard/PatientMappingFirstStep';
import { useLocation, useNavigate } from 'react-router-dom';
import { PatientMappingSecondStep } from './wizard/PatientMappingSecondStep';
import { PatientMappingThirdStep } from './wizard/PatientMappingThirdStep';

export const PatientMappingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentStep, firstStepState, secondStepState, thirdStepState } =
    useAppSelector(state => state.patientMapping);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (firstStepState) dispatch(setFirstStepState(firstStepState));
    if (secondStepState) dispatch(setSecondStepState(secondStepState));
    if (thirdStepState) dispatch(setThirdStepState(thirdStepState));

    if (location.state) {
      if (location.state.step === 1 && firstStepState)
        dispatch(setCurrentStep({ step: 1, data: { ...firstStepState } }));
      else if (location.state.step === 2 && secondStepState)
        dispatch(setCurrentStep({ step: 2, data: { ...secondStepState } }));
      else if (location.state.step === 3 && thirdStepState)
        dispatch(setCurrentStep({ step: 3, data: { ...thirdStepState } }));
      else dispatch(setCurrentStep(location.state));
    } else {
      navigate(`/datamaintenance/patientmapping/${currentMenuItem?.id}`, {
        state: { step: 1, data: undefined },
      });
    }
    // eslint-disable-next-line
  }, [location.state]);

  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#1B806D',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#1B806D',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#1B806D',
      }),
      '& .QontoStepIcon-completedIcon': {
        color: '#1B806D',
        zIndex: 1,
        fontSize: 18,
      },
      '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
    })
  );

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const steps = [
    'Choose a source patient',
    'Choose a target patient',
    'Confirm mapping',
  ];

  const renderPatientMappingStep = () => {
    if (currentStep) {
      switch (currentStep.step) {
        case 2:
          return <PatientMappingSecondStep />;
        case 3:
          return <PatientMappingThirdStep />;
      }
    }

    return <PatientMappingFirstStep />;
  };

  return (
    <PageContainer
      minWidth="800px"
      m="20px auto 0 auto !important"
      maxWidth="1000px"
    >
      <Typography variant="subtitle1">{currentMenuItem?.text}</Typography>
      <Box display="flex" flexDirection="column" margin="auto">
        <Stepper
          alternativeLabel
          activeStep={currentStep ? currentStep.step - 1 : 0}
          connector={<QontoConnector />}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderPatientMappingStep()}
      </Box>
    </PageContainer>
  );
};
