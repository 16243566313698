import { GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ValueOption } from './table';

type DataGridState = {
  rows: any[];
  columns: GridColDef[];
  defaultValues?: Record<string, string | number | boolean | ValueOption>;
  openTableModal: boolean;
  selectedRow?: GridRowModel;
};

export type TableData = {
  rows: any[] | undefined;
  columns: GridColDef[];
  defaultValues?: Record<string, string | number | boolean | ValueOption>;
};

const initialState: DataGridState = {
  rows: [],
  columns: [],
  openTableModal: false,
  selectedRow: undefined,
};

export const dataGridTableSlice = createSlice({
  name: 'dataGridTableSlice',
  initialState,
  reducers: {
    initializeTableData: (state, action: PayloadAction<TableData>) => {
      const { rows, columns, defaultValues } = action.payload;

      state.rows = rows ? [...rows] : [];
      state.columns = [...columns];
      state.defaultValues = defaultValues;
    },
    updateTableRows: (state, action: PayloadAction<any[]>) => {
      state.rows = [...action.payload];
    },
    updateTableColumns: (state, action: PayloadAction<GridColDef[]>) => {
      state.columns = [...action.payload];
    },
    resetTableData: state => {
      state.rows = [];
      state.columns = [];
      state.defaultValues = undefined;
    },
    setOpenTableModal: (state, action: PayloadAction<boolean>) => {
      state.openTableModal = action.payload;
    },
    setSelectedRow: (state, action: PayloadAction<GridRowModel>) => {
      state.selectedRow = action.payload;
    },
    resetTableModal: state => {
      state.selectedRow = undefined;
    },
  },
});

export const {
  initializeTableData,
  updateTableRows,
  updateTableColumns,
  resetTableData,
  setOpenTableModal,
  setSelectedRow,
  resetTableModal,
} = dataGridTableSlice.actions;
