import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/axiosBaseQuery';
import {
  convertWorklistToGridData,
  Worklist,
  WorklistResponse,
  WorklistV2,
} from './worklist';
import { GridData } from '../table';

export const worklistApi = createApi({
  reducerPath: 'worklistApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['WorkListItems'],
  endpoints: builder => ({
    getWorklist: builder.query<GridData, { wlid: string; uid: string }>({
      query: ({ wlid, uid }) => ({
        url: `worklist/${wlid}/${uid}`,
        method: 'GET',
      }),
      transformResponse: (worklist: WorklistResponse, meta, request) =>
        convertWorklistToGridData(worklist),
    }),
    getWorklistV2: builder.query<WorklistV2, { wlid: string; uid: string }>({
      query: ({ wlid, uid }) => ({
        url: `worklist/v2/${wlid}/${uid}`,
        method: 'GET',
      }),
    }),
    updateWorklist: builder.mutation<
      void,
      { wlid: string; uid: string; worklist: Worklist }
    >({
      query: ({ wlid, uid, worklist }) => {
        return {
          url: `worklist/${wlid}/${uid}`,
          method: 'PUT',
          data: [
            {
              id: worklist.id,
              status: worklist.status,
              lastNote: worklist.lastNote,
            },
          ],
        };
      },
    }),
  }),
});

export const {
  useGetWorklistQuery,
  useGetWorklistV2Query,
  useUpdateWorklistMutation,
  endpoints: {
    getWorklist: { useQueryState: useGetWorklistQueryState },
  },
} = worklistApi;
