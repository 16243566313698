import { Box, List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TabPanelTitle = styled(Typography)(({ theme }) => ({
  color: '#666',
  fontSize: '16px',
  fontWeight: 700,
  padding: 1,
  margin: '1em 0 0.5em 1em',
}));

export const TabPanelList = styled(List)(({ theme }) => ({
  paddingTop: 0,
  listStyleType: 'disc',
  color: '#1B806D',
  overflow: 'auto',
  height: '415px',
}));

export const TabPanelMenuItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0.5em 0.5em 0.5em 1em',
  margin: '0.5em',
  '&:hover': { cursor: 'pointer' },
  borderRadius: '6px',
  background: '#1B806D',
  height: '57px',
  width: '395px',
}));

export const TabPanelMenuItemContent = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '60px 1fr 84px',
  width: '100%',
}));

export const TabPanelMenuItemIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#FFF',
  minWidth: 0,
  justifyContent: 'center',
  paddingRight: '0.7em',
}));

export const TabPanelMenuItemTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const TabPanelMenuItemTitle = styled(Typography)(({ theme }) => ({
  color: '#FFF',
  fontFamily: 'Basis Grotesque',
  fontSize: '15px',
  fontWeight: 700,
}));

export const TabPanelMenuItemText = styled(Typography)(({ theme }) => ({
  color: '#FFF',
  fontSize: '12px',
  fontWeight: 400,
}));

export const TabPanelMenuItemTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifySelf: 'self-end',
  alignSelf: 'end',
}));

export const TabPanelMenuItemTimeText = styled(Typography)(({ theme }) => ({
  color: '#FFF',
  fontSize: '11px',
  fontWeight: 400,
  whiteSpace: 'nowrap',
}));
