import { Box, Tooltip, Typography } from '@mui/material';

export interface BasicTableHeaderProps {
  columns: string[];
}

export const BasicTableHeader: React.FC<BasicTableHeaderProps> = ({
  columns,
}) => {
  return (
    <Box
      sx={{
        borderRadius: '7px 7px var(--none, 0px) 7px',
        minHeight: '40px',
        background: '#47A493',
        display: 'grid',
        gridTemplateColumns: `repeat(${columns.length}, 1fr)`,
        width: '100%',
      }}
    >
      {columns.map(column => (
        <Tooltip
          title={column}
          PopperProps={{
            sx: { marginTop: '-10px !important' },
          }}
        >
          <Typography
            m="auto"
            sx={{
              color: '#FFF',
              fontFamily: 'Basis Grotesque',
              fontSize: '15px',
              fontWeight: 400,
              margin: '0.7em',
              textAlign: 'center',
              alignSelf: 'center',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {column}
          </Typography>
        </Tooltip>
      ))}
    </Box>
  );
};
