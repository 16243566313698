import { Box, BoxProps, Typography } from '@mui/material';
import { BasicTableHeader } from 'app';

interface PatientMappingTableProps extends BoxProps {
  columns: string[];
  processRows: () => React.ReactNode;
}

export const PatientMappingTable: React.FC<PatientMappingTableProps> = ({
  children,
  columns,
  processRows,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <Typography variant="h6" m="1em">
        Please select a patient from the list below to continue the next step:
      </Typography>
      <BasicTableHeader columns={columns} />
      {processRows()}
    </Box>
  );
};
