import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  setSelectedPublication,
  useAppDispatch,
  useAppSelector,
  useGetOaInsightPublicationsQuery,
} from 'api';
import { useState } from 'react';
import { PageContainer } from 'app';
import { OaInsightTable } from './OaInsightTable';

export const OaInsightPage = () => {
  // Hooks
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { data: publications } = useGetOaInsightPublicationsQuery();
  const { selectedPublication } = useAppSelector(state => state.oaInsight);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleChange = (event: SelectChangeEvent<number>) => {
    const found = publications?.find(
      publication => publication.oaInsightPublicationKey === event.target.value
    );
    if (found) dispatch(setSelectedPublication(found));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <PageContainer>
      <Typography variant="subtitle1">{currentMenuItem?.text}</Typography>
      <Box display="flex" flexDirection="column">
        <Box
          sx={{
            width: '100%',
            maxWidth: '1200px',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <FormControl sx={{ marginTop: '20px', width: '300px' }}>
            <InputLabel id="oa-publication-dropdown-label">
              Publication
            </InputLabel>
            <Select
              labelId="oa=publication-dropdown-label"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={
                selectedPublication?.oaInsightPublicationKey
                  ? selectedPublication?.oaInsightPublicationKey
                  : 0
              }
              label="Publications"
              onChange={handleChange}
            >
              <MenuItem value={0}>None</MenuItem>
              {publications &&
                publications.map(publication => (
                  <MenuItem value={publication.oaInsightPublicationKey}>
                    {publication.description}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <OaInsightTable />
      </Box>
    </PageContainer>
  );
};
