import { useEffect } from 'react';
import { GridRowModel } from '@mui/x-data-grid-pro';
import {
  setAlert,
  useAppDispatch,
  useAppSelector,
  TableData,
  initializeTableData,
  updateTableRows,
  resetTableData,
  generateColumns,
  useGetAllActiveMapConceptsQuery,
  MapConcept,
  useDeleteMapConceptMutation,
  useUpdateMapConceptMutation,
  ValueOption,
} from 'api';
import { AlertSeverity, closeAlert } from 'api/models/alert';
import { ActionType, DataGridTable } from 'app/common';

export const DatabricksMappingTable = () => {
  // Hooks
  const { rows, columns } = useAppSelector(state => state.dataGridTable);
  const { mockUser } = useAppSelector(state => state.appUser);
  const uid = mockUser?.id.toString() ?? '';
  const { selectedConceptDomain } = useAppSelector(state => state.databricks);
  const { data, isLoading, isFetching } = useGetAllActiveMapConceptsQuery(
    { uid, dcKey: selectedConceptDomain.ooConceptDomainKey.toLocaleString() },
    { skip: !uid || !selectedConceptDomain.ooConceptDomainKey }
  );

  const [updateAction, { isLoading: isUpdating }] =
    useUpdateMapConceptMutation();
  const [deleteAction, { isLoading: isDeleting }] =
    useDeleteMapConceptMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetTableData());

    if (data && data.rows && data.rows.length > 0) {
      let defaultValueTmp: Record<
        string,
        string | number | boolean | ValueOption
      > = {};
      data.columns.reduce((data, col) => {
        if (!data[col.name]) {
          data[col.name] = col.defaultValue;
        }
        return data;
      }, defaultValueTmp);

      const tableData: TableData = {
        rows: data.rows,
        columns: generateColumns(data?.columns),
        defaultValues: defaultValueTmp,
      };
      dispatch(initializeTableData(tableData));
    }
  }, [dispatch, data]);

  const handleDelete = (deletedRow: GridRowModel) => async () => {
    // Only run if we have the menu item id and table is populated
    const reqBody = {
      request: { uid },
      mapConcept: deletedRow.row as MapConcept,
    };

    try {
      await deleteAction(reqBody)
        .unwrap()
        .then(() => {
          dispatch(
            updateTableRows(rows.filter((row: any) => row.id !== deletedRow.id))
          );
          dispatch(
            setAlert({
              message: 'Row deleted.',
              severity: AlertSeverity.Success,
            })
          );
        });
    } catch (e) {
      closeAlert();
      dispatch(
        setAlert({
          message: 'Something went wrong.',
          severity: AlertSeverity.Error,
        })
      );
    }
  };

  const processRowUpdate = async (rowUpdate: GridRowModel) => {
    // Only run if we have the menu item id and table is populated
    let dispatchMessage = '';
    const updatedRow = { ...rowUpdate, isNew: false };
    const reqBody = {
      request: { uid },
      mapConcept: rowUpdate as MapConcept,
    };

    try {
      await updateAction(reqBody)
        .unwrap()
        .then(() => {
          const index = rows.findIndex(row => row.id === rowUpdate.id);

          if (index !== -1) {
            const rowsCpy = [...rows];
            const valueOption: ValueOption = (
              columns.find(col => col.field === 'ooConcept') as any
            )?.valueOptions.find(
              (option: ValueOption) =>
                option.value === (updatedRow as any).ooConcept
            );
            (updatedRow as any).ooConcept = valueOption;
            rowsCpy[index] = updatedRow;
            updateTableRows(rowsCpy);
          }
          dispatchMessage = 'Row updated.';
        });
    } catch (e) {
      dispatch(
        setAlert({
          message: 'Something went wrong.',
          severity: AlertSeverity.Error,
        })
      );
      return;
    }

    dispatch(
      setAlert({
        message: dispatchMessage,
        severity: AlertSeverity.Success,
      })
    );
    return updatedRow;
  };

  return (
    <DataGridTable
      cellActions={[ActionType.Delete, ActionType.Edit]}
      handleDelete={handleDelete}
      processRowUpdate={processRowUpdate}
      isBusy={isFetching || isLoading || isUpdating || isDeleting}
      toolbarProps={{ enableFilter: true }}
      fullWidth
    />
  );
};
