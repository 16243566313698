import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Box, Typography, Link } from '@mui/material';
import { useGetHelpContentQuery } from 'api/models/help';
import {
  useAppSelector,
  useGetPowerBiQuery,
} from 'api';
import {
  ModalContainerStyled,
  ModalHeaderStyled,
} from '../../common/modal/Modal.styled';

type PowerBIReportHelpModalProps = {
  open: boolean;
  handleCloseModal: () => void;
};

export const PowerBIReportHelpModal = ({
  open,
  handleCloseModal,
}: PowerBIReportHelpModalProps) => {
  // Hooks
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { mockUser } = useAppSelector(state => state.appUser);
  const mid = currentMenuItem?.id.toString() ?? '';
  const uid = mockUser?.id.toString() ?? '';
  // TODO:: If we don't have a mid, we need to show an error
  const { data: helpContent } = useGetHelpContentQuery(
    { mid, uid },
    { skip: !mid || !uid }
  );
  const { data: powerBiEmbedProps } = useGetPowerBiQuery(
    { mid, uid },
    { skip: !mid || !uid }
  );

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainerStyled
        style={{
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
        }}
      >
        <ModalHeaderStyled>
          <Typography variant="subtitle1">
            {powerBiEmbedProps?.reportName}
          </Typography>
          <Link component="button" mr="10px" onClick={handleCloseModal}>
            <FontAwesomeIcon icon={faXmark} size="2x" color="#d8d3d3" />
          </Link>
        </ModalHeaderStyled>
        <Box
          flexDirection="column"
          gap="20px"
          p="1rem"
          height="500px"
          display="block"
          overflow="auto"
        >
          {helpContent}
        </Box>
      </ModalContainerStyled>
    </Modal>
  );
};
