import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseRequestParams, GridData } from 'api';
import { axiosBaseQuery } from 'api/axiosBaseQuery';
import {
  convertProvidersToDataGrid,
  Provider,
  ProviderResponse,
} from './provider';

export const providerApi = createApi({
  reducerPath: 'providerApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ProviderItems'],
  endpoints: builder => ({
    getProviders: builder.query<GridData, BaseRequestParams>({
      query: request => ({
        url: `databricks/provider/${request.mid}/${request.uid}`,
        method: 'GET',
      }),
      transformResponse: (providerResponse: ProviderResponse, meta, request) =>
        convertProvidersToDataGrid(providerResponse),
      providesTags: providers => [{ type: 'ProviderItems', id: 'LIST' }],
    }),
    updateProvider: builder.mutation<
      Provider[],
      { request: BaseRequestParams; provider: Provider }
    >({
      query: ({ request, provider }) => ({
        url: `databricks/provider/${request.mid}/${request.uid}`,
        method: 'PUT',
        data: provider,
      }),
      invalidatesTags: [{ type: 'ProviderItems', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useUpdateProviderMutation,
  endpoints: {
    getProviders: { useQueryState: useGetProvidersQueryState },
  },
} = providerApi;
