import { useEffect } from 'react';
import { GridRowModel } from '@mui/x-data-grid-pro';
import {
  setAlert,
  useAppDispatch,
  useAppSelector,
  TableData,
  initializeTableData,
  updateTableRows,
  resetTableData,
  generateColumns,
  ValueOption,
  useGetOaInsightSubscribersQuery,
  useUpsertOaInsightSubscriberMutation,
  OaInsightSubscriber,
  useDeleteOaInsightSubscriberMutation,
  convertOaInsightSubscribersToDataGrid,
} from 'api';
import { AlertSeverity, closeAlert } from 'api/models/alert';
import { ActionType, DataGridTable } from 'app/common';

export const OaInsightTable = () => {
  // Hooks
  const { selectedPublication } = useAppSelector(state => state.oaInsight);
  const { data, isLoading, isFetching } = useGetOaInsightSubscribersQuery(
    {
      publicationKey: selectedPublication.oaInsightPublicationKey,
    },
    { skip: !selectedPublication.oaInsightPublicationKey }
  );

  const [updateAction, { isLoading: isUpdating }] =
    useUpsertOaInsightSubscriberMutation();
  const [deleteAction, { isLoading: isDeleting }] =
    useDeleteOaInsightSubscriberMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetTableData());

    if (data && data.rows && data.rows.length > 0) {
      let defaultValueTmp: Record<
        string,
        string | number | boolean | ValueOption
      > = {};
      data.columns.reduce((data, col) => {
        if (!data[col.name]) {
          data[col.name] = col.defaultValue;
        }
        return data;
      }, defaultValueTmp);

      const tableData: TableData = {
        rows: data.rows,
        columns: generateColumns(data?.columns),
        defaultValues: defaultValueTmp,
      };

      dispatch(initializeTableData(tableData));
    }
  }, [dispatch, data]);

  const handleDelete = (deletedRow: GridRowModel) => async () => {
    // Only run if we have the menu item id and table is populated
    const reqBody = {
      subscriberKey: (deletedRow.row as OaInsightSubscriber)
        .oaInsightSubscriberKey,
      publicationKey: (deletedRow.row as OaInsightSubscriber)
        .oaInsightPublicationKey,
    };

    try {
      await deleteAction(reqBody)
        .unwrap()
        .then((response: any) => {
          const updatedTableData = convertOaInsightSubscribersToDataGrid(
            response as OaInsightSubscriber[]
          ) as any;
          dispatch(updateTableRows(updatedTableData.rows));
          dispatch(
            setAlert({
              message: 'Row deleted.',
              severity: AlertSeverity.Success,
            })
          );
        });
    } catch (e: any) {
      closeAlert();
      dispatch(
        setAlert({
          message: `Something went wrong: ${e.message}`,
          severity: AlertSeverity.Error,
        })
      );
    }
  };

  const processRowUpdate = async (rowUpdate: GridRowModel) => {
    // Only run if we have the menu item id and table is populated
    let dispatchMessage = '';
    const updatedRow = { ...rowUpdate, isNew: false } as any;
    if (!updatedRow.oaInsightPublicationKey) {
      updatedRow.oaInsightPublicationKey =
        selectedPublication.oaInsightPublicationKey;
    }

    const reqBody = {
      subscriber: {
        ...(updatedRow as OaInsightSubscriber),
        enabledFlag:
          typeof rowUpdate.enabledFlag === 'object'
            ? rowUpdate.enabledFlag.props?.value
            : rowUpdate.enabledFlag,
      },
    };

    try {
      await updateAction(reqBody)
        .unwrap()
        .then((response: any) => {
          const updatedTableData = convertOaInsightSubscribersToDataGrid(
            response as OaInsightSubscriber[]
          ) as any;

          dispatch(updateTableRows(updatedTableData.rows));
          dispatchMessage = 'Row updated.';
        });
    } catch (e: any) {
      dispatch(
        setAlert({
          message: `Something went wrong: ${e.message}`,
          severity: AlertSeverity.Error,
        })
      );
      return;
    }

    dispatch(
      setAlert({
        message: dispatchMessage,
        severity: AlertSeverity.Success,
      })
    );
    return;
  };

  return (
    <DataGridTable
      cellActions={[ActionType.Delete, ActionType.Edit]}
      handleDelete={handleDelete}
      processRowUpdate={processRowUpdate}
      isBusy={isFetching || isLoading || isUpdating || isDeleting}
      toolbarProps={{ enableAddButton: true, enableFilter: true }}
      fullWidth
    />
  );
};
