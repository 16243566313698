import { styled } from '@mui/material/styles';
import { Box, FormControlLabel } from '@mui/material';

export const ModalContainerStyled = styled(Box)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '15px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#d6dee1',
    borderRadius: '20px',
    border: '6px solid transparent',
    backgroundClip: 'content-box',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#a8bbbf',
  },
  '&:hover': {
    overflowY: 'auto',
  },
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: 'white',
  borderRadius: '6px',
}));

export const ModalHeaderStyled = styled(Box)(({ theme }) => ({
  justifyContent: 'space-between',
  borderTopLeftRadius: '0.3rem',
  borderTopRightRadius: '0.3rem',
  padding: '1rem 1rem',
  borderWidth: '0 0 1px',
  borderStyle: 'solid',
  whiteSpace: 'nowrap',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  alignItems: 'center',
  backgroundColor: '#1B806D',
  color: '#ffffff',
}));

export const FormWithLabel = styled(FormControlLabel)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px',
  margin: 0,
}));
