import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAppSelector, logoutUser, ServerApiUrl } from 'api';
import { ImpersonateButton } from 'app/App.styled';
import { useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';

export const ReauthenticateBanner: React.FC = () => {
  const { user } = useAppSelector(state => state.appUser);
  const [showBanner, setShowBanner] = useState(false);
  const [time, setTime] = useState(-1);
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime(time => time - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [time]);

  const minutesLeft = Math.floor(time / 60);
  const minutesLeftStr = minutesLeft.toString().padStart(2, '0');
  const secondsLeft = time % 60;
  const secondsLeftStr = secondsLeft.toString().padStart(2, '0');

  useEffect(() => {
    setIsTimeout(minutesLeft < 15 && minutesLeft >= 0 && secondsLeft >= 0);
    if (minutesLeft === 0 && secondsLeft === 0) handleReauthenticate();
  }, [minutesLeft, secondsLeft]);

  useEffect(() => {
    if (user && user.authTimestamp) {
      const currDate = new Date();

      const currTimestampUtc = Date.UTC(
        currDate.getUTCFullYear(),
        currDate.getUTCMonth(),
        currDate.getUTCDate(),
        currDate.getUTCHours(),
        currDate.getUTCMinutes(),
        currDate.getUTCSeconds(),
        currDate.getUTCMilliseconds()
      );
      const diff = user!.authTimeoutTimestamp - currTimestampUtc;
      const minutes = Math.floor(diff / 1000 / 60);
      const seconds = Math.floor(diff / 1000) % 60;

      setTime(minutes * 60 + seconds);
    }
  }, [user]);

  function handleReauthenticate() {
    window.location.href = ServerApiUrl + 'users/logout';
    logoutUser();
    setShowBanner(false);
  }

  if (!showBanner && !isTimeout) return null;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="7px"
      sx={{ backgroundColor: '#fcf0db' }}
      padding="0.5em 1em 0.5em 1em"
    >
      <Typography>
        You will be logged out in {minutesLeftStr}:{secondsLeftStr}.
      </Typography>
      <Box display="flex" gap="1em" alignItems="center">
        <ImpersonateButton
          variant="contained"
          onClick={handleReauthenticate}
          size="small"
        >
          Reauthenticate
        </ImpersonateButton>
        <Link component="button" mr="10px" onClick={() => setShowBanner(false)}>
          <FontAwesomeIcon icon={faXmark} size="2x" color={grey[700]} />
        </Link>
      </Box>
    </Box>
  );
};
