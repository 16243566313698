import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OneOncologyPatient,
  PatientMappingFirstStepState,
  PatientMappingSecondStepState,
  PatientMappingThirdStepState,
  UnmappedPatient,
} from './patientMapping';

type PatientMappingState = {
  currentStep?: PatientMappingStep;
  firstStepState?: PatientMappingFirstStepState;
  secondStepState?: PatientMappingSecondStepState;
  thirdStepState?: PatientMappingThirdStepState;
  selectedUnmappedPatient?: UnmappedPatient;
  selectedOneOncologyPatient?: OneOncologyPatient;
  isLoading: boolean;
};

type PatientMappingStep = {
  step: number;
  data?:
    | PatientMappingFirstStepState
    | PatientMappingSecondStepState
    | PatientMappingThirdStepState;
};

const initialState: PatientMappingState = {
  currentStep: { step: 1, data: undefined },
  isLoading: false,
};

export const patientMappingSlice = createSlice({
  name: 'patientMappingSlice',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<PatientMappingStep>) => {
      state.currentStep = action.payload;
    },
    setFirstStepState: (
      state,
      action: PayloadAction<PatientMappingFirstStepState>
    ) => {
      state.firstStepState = action.payload;
    },
    setSecondStepState: (
      state,
      action: PayloadAction<PatientMappingSecondStepState>
    ) => {
      state.secondStepState = action.payload;
    },
    setThirdStepState: (
      state,
      action: PayloadAction<PatientMappingThirdStepState>
    ) => {
      state.thirdStepState = action.payload;
    },
    setSelectedUnmappedPatient: (
      state,
      action: PayloadAction<UnmappedPatient>
    ) => {
      state.selectedUnmappedPatient = action.payload;
    },
    setSelectedOneOncologyPatient: (
      state,
      action: PayloadAction<OneOncologyPatient>
    ) => {
      state.selectedOneOncologyPatient = action.payload;
    },
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetPatientMappingState: state => {
      state.currentStep = undefined;
      state.firstStepState = undefined;
      state.secondStepState = undefined;
      state.thirdStepState = undefined;
      state.selectedUnmappedPatient = undefined;
      state.selectedUnmappedPatient = undefined;
      state.isLoading = false;
    },
  },
});

export const {
  setCurrentStep,
  setFirstStepState,
  setSecondStepState,
  setThirdStepState,
  setSelectedUnmappedPatient,
  setSelectedOneOncologyPatient,
  setLoadingState,
  resetPatientMappingState,
} = patientMappingSlice.actions;
