import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PowerBiReportPage, PowerBiSlicerFilterState } from './powerBi';

type PowerBiState = {
  currentPage: PowerBiReportPage | undefined;
  pages: PowerBiReportPage[] | undefined;
  loading: boolean;
  containerHeight: number;
  pbiSlicerFilterStates: PowerBiSlicerFilterState[];
};

const initialState: PowerBiState = {
  currentPage: { name: '', displayName: '', visibility: 0 },
  pages: [{ name: '', displayName: '', visibility: 0 }],
  loading: false,
  containerHeight: 0,
  pbiSlicerFilterStates: [],
};

export const powerBiSlice = createSlice({
  name: 'powerBiSlice',
  initialState,
  reducers: {
    setCurrentPowerBIPage: (
      state,
      action: PayloadAction<PowerBiReportPage | undefined>
    ) => {
      state.currentPage = action.payload;
    },
    setPowerBiPages: (
      state,
      action: PayloadAction<PowerBiReportPage[] | undefined>
    ) => {
      state.pages = action.payload?.map(x => x);
    },
    setLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageContainerHeight: (state, action: PayloadAction<number>) => {
      state.containerHeight = action.payload;
    },
    setPbiSlicerFilterStates: (
      state,
      action: PayloadAction<PowerBiSlicerFilterState[]>
    ) => {
      state.pbiSlicerFilterStates = action.payload;
    },
  },
});

export const {
  setCurrentPowerBIPage,
  setPowerBiPages,
  setLoadingStatus,
  setPageContainerHeight,
  setPbiSlicerFilterStates,
} = powerBiSlice.actions;
