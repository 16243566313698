import {
  Box,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  useAppSelector,
  useAppDispatch,
  setCurrentPowerBIPage,
  PowerBiPageVisibility,
  UserAuditLog,
  UserAuditLogAction,
  useCreateUserAuditLogMutation,
} from 'api';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const PowerBiPageDropdown: React.FC = () => {
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { currentPage, pages } = useAppSelector(state => state.powerBi);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [createAction] = useCreateUserAuditLogMutation();
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const found = pages?.find(page => page.name === event.target.value);

    if (found) {
      const auditLog: UserAuditLog = {
        action: UserAuditLogAction.View,
        target: '',
        metadata: `PowerBi report switched to report page: ${found.displayName}`,
      };
      createAction({ auditLog: auditLog });
      dispatch(setCurrentPowerBIPage(found));
      navigate(`/powerbi/${currentMenuItem?.id}?Page=${found.displayName}`);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '10px',
        gap: '1em',
      }}
    >
      <Typography fontWeight={900} fontSize="1.2em">
        Select Report Page:
      </Typography>
      <FormControl sx={{ width: '400px' }} size="small">
        <Select
          labelId="powerbi-dropdown-label"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={currentPage ? currentPage.name : ''}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            sx: { mt: '-10px', ml: '0px' },
          }}
        >
          {pages &&
            pages.map(
              page =>
                page.visibility === PowerBiPageVisibility.Visible && (
                  <MenuItem key={page.name} value={page.name}>
                    {page.displayName}
                  </MenuItem>
                )
            )}
        </Select>
      </FormControl>
    </Box>
  );
};
