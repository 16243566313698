import { GridPreProcessEditCellProps } from '@mui/x-data-grid-pro';

export enum DataGridColumnType {
  Unknown = 'unknown',
  Date = 'date',
  SingleSelect = 'singleSelect',
  Number = 'number',
  String = 'string',
}

function dateValidator(params: GridPreProcessEditCellProps) {
  const hasError = params.props.value === undefined;

  return { ...params.props, error: hasError };
}
function numberValidator(params: GridPreProcessEditCellProps) {
  const hasError = params.hasChanged && params.props.value === undefined;

  return { ...params.props, error: hasError };
}

function singleSelectValidator(params: GridPreProcessEditCellProps) {
  const hasError =
    params.hasChanged && params.props.value === undefined
      ? 'Please make a selection'
      : null;

  return { ...params.props, error: hasError };
}

function stringValidator(params: GridPreProcessEditCellProps) {
  let error;
  if (params.hasChanged) {
    error =
      params.props.value === undefined ||
      params.props.value === null ||
      params.props.value === ''
        ? 'Value cannot be empty.'
        : false;

    error =
      params.props.value?.trim() === '' ? 'Value must contain text.' : false;
  }

  return { ...params.props, error: error };
}

function returnProps(params: GridPreProcessEditCellProps) {
  return { ...params.props, error: false };
}

export function preProcessEditCellPropsValidator(type: string): any {
  switch (type) {
    case DataGridColumnType.Date:
      return dateValidator;
    case DataGridColumnType.Number:
      return numberValidator;
    case DataGridColumnType.SingleSelect:
      return singleSelectValidator;
    case DataGridColumnType.String:
      return stringValidator;
    default:
      return returnProps;
  }
}

export function isEmpty(obj: {}) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function isEmptyString(value?: string) {
  return value ? true : false;
}
