import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface ListItemButtonStyledProps extends ListItemButtonProps {
  child?: boolean;
  selected?: boolean;
}

export const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'child' && prop !== 'selected',
})<ListItemButtonStyledProps>(({ theme, child, selected }) => ({
  '&:hover': {
    backgroundColor: selected ? 'rgba(0, 0, 0, 0.14)' : 'rgba(0, 0, 0, 0.04)',
  },
  display: child ? 'block' : 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: '36px 170px 30px',
  padding: child ? '0.2rem 1.5rem 0.2rem 2.0rem' : '0.625rem 0 0.625rem 1rem',
  marginLeft: child ? '1.85rem' : '10px',
  minHeight: child ? 20 : 48,
  height: 'auto',
  maxWidth: child ? '235px' : '255px',
  justifyContent: 'initial',
  backgroundColor: selected ? 'rgba(0, 0, 0, 0.14)' : 'inherit',
  marginTop: '5px',
}));

export const MenuItemListItemStyled = styled(ListItemText, {
  shouldForwardProp: prop => prop !== 'drawerWidth',
})(({ theme }) => ({
  fontSize: 13,
  color: '#fff',
  fontWeight: 400,
  fontFamily: 'arial',
  textDecorationThickness: 'auto',
  textSizeAdjust: '100%',
  lineHeight: '19.5px',
  whiteSpace: 'normal',
}));

export const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 0,
  justifyContent: 'center',
  paddingRight: '5px',
  color: '#e7f2f0',
  transform: 'scale(1.5)',
}));
