import { Box, Tooltip } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { ValueOption, isValueOption } from 'api';
import moment from 'moment';

export function renderBasicCell(params: GridRenderEditCellParams) {
  if (params.colDef.type === 'date') {
    if (params.value)
      return (
        <Box>{moment.utc(params.value).format('YYYY-MM-DD').toString()}</Box>
      );
  }

  if (params.colDef.type === 'singleSelect') {
    let valueOption;
    if (isValueOption(params.row[params.field])) {
      valueOption = params.row[params.field];
    } else {
      valueOption = (params.colDef as any).valueOptions?.find(
        (x: ValueOption) => x.value === params.row[params.field]
      );

      return <Box>{valueOption.value}</Box>;
    }

    return (
      <Box>{valueOption && valueOption.label ? valueOption.label : ''}</Box>
    );
  }
  return (
    <Box overflow="hidden" textOverflow="ellipsis">
      {params.value}
    </Box>
  );
}

export function renderBasicCellWithTooltip(params: GridRenderEditCellParams) {
  return (
    <Tooltip title={params.value}>
      <Box overflow="hidden" textOverflow="ellipsis">
        {params.value}
      </Box>
    </Tooltip>
  );
}
