import {
  GridColDef,
  GridComparatorFn,
  GridFilterItem,
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { preProcessEditCellPropsValidator } from 'api/helper';
import { TextInputValue } from 'app';
import {
  renderBasicCell,
  renderBasicCellWithTooltip,
} from 'app/common/table/BasicCell';
import parse from 'html-react-parser';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { Checkbox } from '@mui/material';

export interface GridData {
  mid?: string;
  columns: ColumnConfiguration[];
  rows: any[];
}

export interface ColumnConfiguration {
  name: string;
  displayName: string;
  type: string;
  required: boolean;
  defaultValue: string | boolean | ValueOption;
  sortDirection?: string;
  valueOptions?: ValueOption[];
  editable: boolean;
  width?: number;
  flex?: number;
  cellClassName?: string;
  resizeable?: boolean;
  minWidth?: number;
  enableTooltip?: boolean;
}

export type ValueOption = {
  value: string | number;
  label: string;
};

function CheckboxEditComponent(props: GridRenderEditCellParams) {
  const { id, field, value } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;

    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
    });
  };

  return (
    <Checkbox
      value={Boolean(
        value?.props?.value !== undefined ? value?.props?.value : value
      )}
      checked={Boolean(
        value?.props?.value !== undefined ? value?.props?.value : value
      )}
      onChange={handleValueChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
}

export const generateColumns = (
  columns: ColumnConfiguration[] | undefined
): GridColDef[] => {
  if (!columns) return [];
  let gridColDefs: GridColDef[] = columns.map(column => {
    let newColumn: any = { ...column };
    if (column.type === 'html') {
      newColumn = {
        field: column.name,
        display: 'flex',
        headerName: column.displayName,
        flex: column.flex ? column.flex : undefined,
        type: column.type,
        editable: column.editable,
        defaultValue: column.defaultValue,
        preProcessEditCellProps: preProcessEditCellPropsValidator(column.type),
        sortable: true,
        sortDirection: column.sortDirection,
        width: column.width ? column.width : undefined,
        sortComparator: linkComparator,
        filterOperators: linkOperators,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.value) return null;
          return <div>{parse(params.value)}</div>;
        },
        valueGetter: (value: any, row: any, column: any, apiRef: any) => {
          if (column.type === 'date') return value ? new Date(value) : null;
          if (column.type === 'singleSelect') {
            return value ? value.value : null;
          }
          return value;
        },
        minWidth: column.minWidth,
        resizeable: column.resizeable,
      };
    } else if (column.type === 'actions') {
      newColumn = {
        display: 'flex',
        field: column.name,
        type: column.type,
        headerName: column.displayName,
        width: column.width,
        cellClassName: column.cellClassName,
      };
    } else if (column.type === 'boolean') {
      newColumn = {
        display: 'flex',
        field: column.name,
        headerName: column.displayName,
        flex: column.flex ? column.flex : undefined,
        type: column.type,
        editable: column.editable,
        defaultValue: column.defaultValue,
        preProcessEditCellProps: preProcessEditCellPropsValidator(column.type),
        sortable: true,
        sortDirection: column.sortDirection,
        width: column.width ? column.width : undefined,
        renderEditCell: (params: GridRenderEditCellParams) => {
          return <CheckboxEditComponent {...params} />;
        },
        renderCell: renderBasicCell,
        valueGetter: (value: any, row: any, column: any, apiRef: any) => {
          if (value) return <CheckIcon value={value} {...value} />;
          else return <ClearIcon value={value} {...value} />;
        },
        minWidth: column.minWidth,
        resizeable: column.resizeable,
      };
    } else {
      newColumn = {
        display: 'flex',
        field: column.name,
        headerName: column.displayName,
        flex: column.flex ? column.flex : undefined,
        type: column.type,
        editable: column.editable,
        valueOptions:
          column.type === 'singleSelect' ? column.valueOptions : undefined,
        defaultValue: column.defaultValue,
        preProcessEditCellProps: preProcessEditCellPropsValidator(column.type),
        sortable: true,
        sortDirection: column.sortDirection,
        width: column.width ? column.width : undefined,
        renderCell: column.enableTooltip
          ? renderBasicCellWithTooltip
          : renderBasicCell,
        valueGetter: (value: any, row: any, column: any, apiRef: any) => {
          if (column.type === 'date') return value ? new Date(value) : null;
          if (column.type === 'singleSelect') {
            return value.value;
          }
          return value;
        },
        minWidth: column.minWidth,
        resizeable: column.resizeable,
      };
    }
    return newColumn;
  });

  return gridColDefs;
};

export function isValueOption(object: any): boolean {
  const model: ValueOption = {
    value: 0,
    label: '',
  };

  if (typeof object !== typeof model) {
    return false;
  }

  if (typeof object !== 'object' || object === null) {
    return false;
  }

  for (const key in model) {
    if (!(key in object)) {
      return false;
    }
  }

  return true;
}

const linkComparator: GridComparatorFn<string> = (v1, v2) => {
  const regex = /<a[^>]*>([^<]*)<\/a>/;
  const match1 = v1 ? v1.match(regex) : null;
  const match2 = v2 ? v2.match(regex) : null;
  const anchorText1 = match1 ? match1[1] : '';
  const anchorText2 = match2 ? match2[1] : '';
  return anchorText1.localeCompare(anchorText2);
};

const linkOperators = [
  {
    label: 'Contains',
    value: 'contains',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (params: any): boolean => {
        const regex = /<a[^>]*>([^<]*)<\/a>/;
        const match = params.value ? params.value.match(regex) : null;
        const searchText = filterItem.value;
        const mainText = match ? match[1] : '';
        return mainText.includes(searchText);
      };
    },
    InputComponent: TextInputValue,
    InputComponentProps: { type: 'string' },
    getValueAsString: (value: string) => value,
  },
];
