import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { RoleClaimsTabPanel } from './RoleClaimsTabPanel';
import { AssignedUsersTabPanel } from './AssignedUsersTabPanel';

export const RoleMaintenanceTabContainer: React.FC = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: '1em',
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Assigned Users" {...a11yProps(0)} />
          <Tab label="Role Claims" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <AssignedUsersTabPanel value={value} index={0} />
      <RoleClaimsTabPanel value={value} index={1} />
    </Box>
  );
};
