import { Box, Tooltip, Typography } from '@mui/material';
import { PatientMappingTable } from '../table/PatientMappingTable';
import React from 'react';
import {
  PatientTableRow,
  PatientTableCell,
  PatientTableCellText,
} from '../table/PatientMappingTable.styled';
import { useNavigate } from 'react-router-dom';
import {
  PatientMappingFirstStepState,
  PatientType,
  UnmappedPatient,
  generatePatientMappingColumns,
  setSelectedUnmappedPatient,
  useAppDispatch,
  useAppSelector,
} from 'api';
import moment from 'moment';
import { formatSSN } from 'api/helper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { BasicTableHeader, BasicTableSkeleton } from 'app';
import { PatientMappingFirstStepForm } from './PatientMappingFirstStepForm';

export const PatientMappingFirstStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { currentStep, selectedUnmappedPatient, isLoading } = useAppSelector(
    state => state.patientMapping
  );
  const firstStepState = currentStep?.data as PatientMappingFirstStepState;
  const unmappedPatients = firstStepState?.unmappedPatients;
  const navigate = useNavigate();

  function processRows() {
    function handleRowClick(row: UnmappedPatient) {
      navigate(`/datamaintenance/patientmapping/${currentMenuItem?.id}`, {
        state: {
          step: 2,
          data: {
            sourcePatient: row,
            searchParams: firstStepState?.searchParams,
          },
        },
      });

      dispatch(setSelectedUnmappedPatient(row));
    }

    const renderRow = (row: UnmappedPatient) => {
      const keys = Object.keys(row) as Array<keyof UnmappedPatient>;
      return (
        <Box display="flex" flexDirection="row" gap="1em" alignItems="center">
          {selectedUnmappedPatient &&
            selectedUnmappedPatient.ooPatientIKey === row.ooPatientIKey && (
              <Box ml="-40px">
                <CheckCircleIcon sx={{ color: '#47A493' }} />
              </Box>
            )}
          <PatientTableRow
            onClick={() => handleRowClick(row)}
            sx={{ width: '100%' }}
            columnCount={keys.length}
          >
            {keys.map((key, index) => {
              const rowAsString = row[key] as string;
              const value: string = moment(
                rowAsString,
                'YYYY-MM-DDTHH:mm:ssZ',
                true
              ).isValid()
                ? moment.utc(rowAsString).format('MM/DD/YYYY')
                : key.toLocaleLowerCase() === 'ssn'
                ? formatSSN(rowAsString)
                : rowAsString;

              return (
                <PatientTableCell noBorder={index === 0}>
                  <Tooltip
                    title={value}
                    PopperProps={{
                      sx: { marginTop: '-10px !important' },
                    }}
                  >
                    <PatientTableCellText
                      sx={{
                        paddingRight: index === keys.length - 1 ? '10px' : '',
                      }}
                    >
                      {value ? value : ''}
                    </PatientTableCellText>
                  </Tooltip>
                </PatientTableCell>
              );
            })}
          </PatientTableRow>
        </Box>
      );
    };

    return <>{unmappedPatients.map(patient => renderRow(patient))}</>;
  }

  return (
    <>
      <PatientMappingFirstStepForm />

      {isLoading ? (
        <BasicTableSkeleton />
      ) : (
        unmappedPatients &&
        unmappedPatients.length > 0 && (
          <PatientMappingTable
            minWidth="800px"
            columns={generatePatientMappingColumns(
              unmappedPatients,
              PatientType.UnmappedPatient
            )}
            processRows={processRows}
          />
        )
      )}

      {unmappedPatients && unmappedPatients.length === 0 && (
        <Box minWidth="800px">
          <BasicTableHeader columns={[]} />
          <PatientTableRow>
            <PatientTableCell noBorder sx={{ textAlign: 'center' }}>
              <Typography variant="h6">
                No results found based on the search criteria.
              </Typography>
            </PatientTableCell>
          </PatientTableRow>
        </Box>
      )}
    </>
  );
};
