import {
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FilterOptionsState,
  Autocomplete,
} from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

type AutoCompleteFieldProps<T> = {
  nameLabel: string;
  optionKeys: Array<keyof T>;
  selectedValue: T | null;
  displayOptions: T[];
  handleAutocompleteChange: (param: string | T | null) => boolean;
  handleFilterOptions: (param1: T[], param2: FilterOptionsState<T>) => T[];
  allowCreate: boolean;
  handleCreateNewItem: null | ((newItemValue: string | null) => void);
  newItemValue: null | string;
  handleNewItemValueChange: null | ((param: string) => void);
};

export const isString = (item: any): item is string => {
  return typeof item === 'string';
};

export const AutocompleteField = <T extends {}>({
  nameLabel,
  optionKeys,
  selectedValue,
  displayOptions,
  handleAutocompleteChange,
  handleFilterOptions,
  allowCreate,
  handleCreateNewItem,
  newItemValue,
  handleNewItemValueChange,
}: AutoCompleteFieldProps<T>): React.ReactElement => {
  const [open, toggleOpen] = useState(false);

  const getOptionValue = (option: T | string) => {
    const val = isString(option)
      ? option
      : optionKeys.length > 1
      ? combineProperties(option)
      : optionKeys.length === 1
      ? option[optionKeys[0]]
      : '';
    return isString(val) ? val : '';
  };

  const combineProperties = (option: T) => {
    let combinedString = '';
    optionKeys.forEach((optionKey, index) => {
      if (index === 0) combinedString = combinedString + option[optionKey];
      else combinedString = combinedString + ' - ' + option[optionKey];
    });

    return combinedString;
  };

  const getOptionKey = (option: T): React.Key => {
    if ('id' in option) {
      const val = option['id'];

      if (isString(val)) return val;
      else return val as number;
    }

    return getOptionValue(option);
  };

  const handleClose = () => {
    toggleOpen(false);
  };

  const handleAddNewItem = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (handleCreateNewItem) {
      handleCreateNewItem(newItemValue);
    }

    handleClose();
  };

  const handleChange = (newValue: string | T | null) => {
    const isNewItem = handleAutocompleteChange(newValue);

    if (isNewItem && allowCreate) {
      toggleOpen(true);
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={selectedValue}
        onChange={(e, newValue) => handleChange(newValue)}
        filterOptions={handleFilterOptions}
        getOptionLabel={option => getOptionValue(option)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="autocomplete-select"
        options={displayOptions}
        renderOption={(props, option) => (
          <li {...props} key={getOptionKey(option)}>
            {getOptionValue(option)}
          </li>
        )}
        freeSolo={allowCreate}
        renderInput={params => (
          <TextField
            {...params}
            label="Type to search..."
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      {allowCreate && (
        <Dialog open={open} onClose={handleClose}>
          <form onSubmit={handleAddNewItem}>
            <DialogTitle>{`Add a new ${nameLabel}`}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="new-role-name"
                value={newItemValue}
                onChange={e =>
                  handleNewItemValueChange &&
                  handleNewItemValueChange(e.target.value)
                }
                label={`New ${nameLabel} ${optionKeys[0].toString()}`}
                type="text"
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Add</Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </React.Fragment>
  );
};
