import { styled } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';

export const BusyIndicatorBox = styled(Box)(() => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 4,
  outline: 'none',
}));

export const BusyIndicatorCircularProgress = styled(CircularProgress)(() => ({
  margin: 'auto',
  outline: 'none',
}));
