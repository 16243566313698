import { styled, alpha } from '@mui/material/styles';
import {
  AppBar,
  AppBarProps,
  Link,
  Menu,
  MenuProps,
  Toolbar,
  ToolbarProps,
} from '@mui/material';

interface TopBarStyledBarProps extends AppBarProps {
  isDrawerOpen: boolean;
}

export const TopbarStyled = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'isDrawerOpen',
})<TopBarStyledBarProps>(({ theme, isDrawerOpen }) => ({
  position: 'fixed',
  width: 'auto',
  left: isDrawerOpen ? '282px' : '66px',
  backgroundColor: 'white',
  boxShadow: 'none',
  color: 'black',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['left', 'width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const LinkStyled = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'rgb(55, 65, 81)',
}));

export const ToolbarStyled = styled(Toolbar)<ToolbarProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 !important',
  height: 70,
}));

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    keepMounted
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
