import { useState, useRef } from 'react';
import { List, Popover } from '@mui/material';
import {
  MenuItemDisplay,
  useAppDispatch,
  useAppSelector,
  setSelectedMenuItem,
  MenuItemTypes,
  defaultMenuItem,
  setCurrentMenuItem,
} from 'api';
import {
  CollapsedListItemButtonStyled,
  CollapsedListItemIconStyled,
  CollapsedMenuItemHeader,
  CollapsedMenuItemListItemStyled,
} from '.';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

type CollapsedMenuItemProps = {
  menuItem: MenuItemDisplay;
  popoverChild?: boolean;
};

const useStyles = makeStyles((theme: any) => ({
  popover: {
    pointerEvents: 'none',
    left: '4.5px',
  },
  popoverContent: {
    pointerEvents: 'auto',
    backgroundColor: '#1b806d',
    boxShadow: 'none',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
}));

export const CollapsedMenuItem = ({
  menuItem,
  popoverChild,
}: CollapsedMenuItemProps) => {
  // Hooks
  const { selectedMenuItem, currentMenuItem } = useAppSelector(
    state => state.menuItem
  );
  const popoverAnchor = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  // States
  const [openedPopover, setOpenedPopover] = useState(false);

  const isSelected =
    selectedMenuItem?.id === menuItem.id ||
    selectedMenuItem?.parentId === menuItem.id ||
    currentMenuItem?.id === menuItem.id ||
    currentMenuItem?.parentId === menuItem.id;

  const menuItemClicked = () => {
    dispatch(
      setSelectedMenuItem(
        menuItem.children.length > 0 && menuItem.id === selectedMenuItem?.id
          ? defaultMenuItem
          : menuItem
      )
    );

    if (menuItem.type === MenuItemTypes.powerBI) {
      navigate(`/powerbi/${menuItem.id}`);
      dispatch(setCurrentMenuItem(menuItem));
    } else if (menuItem.type === MenuItemTypes.flexData) {
      navigate(`/flexdata/${menuItem.id}`);
      dispatch(setCurrentMenuItem(menuItem));
    } else if (menuItem.type === MenuItemTypes.generic) {
      navigate(menuItem.url);
      dispatch(setCurrentMenuItem(menuItem));
    }
  };

  const popoverEnter = ({ currentTarget }: any) => {
    setOpenedPopover(true);
  };

  const popoverLeave = ({ currentTarget }: any) => {
    setOpenedPopover(false);
  };

  return (
    <>
      <CollapsedListItemButtonStyled
        popoverChild={popoverChild}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
        ref={popoverAnchor}
        selected={isSelected}
      >
        {!popoverChild && (
          <CollapsedListItemIconStyled>
            <i className={menuItem.iconClass}></i>
          </CollapsedListItemIconStyled>
        )}

        {!popoverChild && menuItem.children.length > 0 && (
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.popoverContent,
            }}
            open={openedPopover}
            anchorEl={popoverAnchor.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              onMouseEnter: popoverEnter,
              onMouseLeave: popoverLeave,
            }}
          >
            <List component="div" disablePadding>
              <CollapsedMenuItemHeader
                sx={{
                  fontWeight: 600,
                  padding: '12px 20px',
                }}
              >
                {menuItem.text}
              </CollapsedMenuItemHeader>
              {menuItem.children.map(ci => (
                <CollapsedMenuItem key={ci.id} menuItem={ci} popoverChild />
              ))}
            </List>
          </Popover>
        )}

        {popoverChild && (
          <CollapsedMenuItemListItemStyled
            primary={menuItem.text}
            disableTypography={true}
            onClick={menuItemClicked}
          />
        )}
      </CollapsedListItemButtonStyled>
    </>
  );
};
