import { useEffect } from 'react';
import { GridRowModel } from '@mui/x-data-grid-pro';
import {
  setAlert,
  useAppDispatch,
  useAppSelector,
  BaseRequestParams,
  TableData,
  initializeTableData,
  useGetChargeCodeReferencesQuery,
  useCreateChargeCodeReferenceMutation,
  ChargeCodeReference,
  resetTableData,
  generateColumns,
  ValueOption,
} from 'api';
import { AlertSeverity } from 'api/models/alert';
import { ActionType, DataGridTable } from 'app/common';

export const ChargeCodeReferenceTable = () => {
  // Hooks
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { rows } = useAppSelector(state => state.dataGridTable);
  const { mockUser } = useAppSelector(state => state.appUser);
  const mid = currentMenuItem?.id.toString() ?? '';
  const uid = mockUser?.id.toString() ?? '';
  const { data, isLoading, isFetching } = useGetChargeCodeReferencesQuery(
    { mid: mid, uid },
    { skip: mid === '-1' || uid === '-1' }
  );

  const [createChargeCodeReference, { isLoading: isCreating }] =
    useCreateChargeCodeReferenceMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetTableData());
    let defaultValuesTmp: Record<
      string,
      string | number | boolean | ValueOption
    > = {};
    data?.columns.reduce((data, col) => {
      if (!data[col.name]) {
        data[col.name] = col.defaultValue;
      }
      return data;
    }, defaultValuesTmp);

    const tableData: TableData = {
      rows: data?.rows,
      columns: generateColumns(data?.columns),
      defaultValues: defaultValuesTmp,
    };

    dispatch(initializeTableData(tableData));
  }, [dispatch, data]);

  const processRowUpdate = async (rowUpdate: GridRowModel) => {
    let updatedRow = { ...rowUpdate, isNew: false };

    if (rowExists(rowUpdate as ChargeCodeReference)) {
      dispatch(
        setAlert({
          message: 'Row already exists.',
          severity: AlertSeverity.Error,
        })
      );
      return;
    }
    if (rowUpdate.isNew) {
      const reqBody = {
        request: { mid, uid } as BaseRequestParams,
        chargeCodeReference: {
          ...(rowUpdate as ChargeCodeReference),
          chargeCode: rowUpdate.chargeCode.trim(),
        },
      };
      try {
        await createChargeCodeReference(reqBody)
          .unwrap()
          .then(() =>
            dispatch(
              setAlert({
                message: 'Row added.',
                severity: AlertSeverity.Success,
              })
            )
          );
      } catch (err: any) {
        dispatch(
          setAlert({
            message: err.response.data,
            severity: AlertSeverity.Error,
          })
        );
        return;
      }
    }

    return updatedRow;
  };

  function rowExists(newRow: ChargeCodeReference) {
    let found = false;
    rows.forEach((row: ChargeCodeReference) => {
      if (
        row.chargeCode &&
        row.chargeCode.toUpperCase() === newRow.chargeCode.toUpperCase()
      ) {
        found = true;
        return;
      }
    });
    return found;
  }

  return (
    <DataGridTable
      cellActions={[ActionType.SaveOnly]}
      processRowUpdate={processRowUpdate}
      isBusy={isFetching || isLoading || isCreating}
      toolbarProps={{ enableAddButton: true, enableFilter: true }}
    />
  );
};
