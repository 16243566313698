import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role, RoleClaim } from 'api';

type RoleMaintenanceState = {
  selectedRole: Role | null;
  currentRoleClaims?: RoleClaim[];
};

const initialState: RoleMaintenanceState = {
  selectedRole: null,
  currentRoleClaims: undefined,
};

export const roleMaintenanceSlice = createSlice({
  name: 'roleMaintenanceSlice',
  initialState,
  reducers: {
    setSelectedRole: (state, action: PayloadAction<Role | null>) => {
      state.selectedRole = action.payload;
    },
    setCurrentRoleClaims: (
      state,
      action: PayloadAction<RoleClaim[] | undefined>
    ) => {
      state.currentRoleClaims = action.payload;
    },
  },
});

export const { setSelectedRole, setCurrentRoleClaims } =
  roleMaintenanceSlice.actions;
