import { useEffect, useState } from 'react';
import { GridRowModel } from '@mui/x-data-grid-pro';
import {
  TableData,
  ValueOption,
  generateColumns,
  initializeTableData,
  resetTableData,
  setAlert,
  updateTableRows,
  useAppDispatch,
  useAppSelector,
} from 'api';
import { AlertSeverity } from 'api/models/alert';
import {
  Worklist,
  useGetWorklistQuery,
  useUpdateWorklistMutation,
} from 'api/models/worklist';
import { ActionType, DataGridTable } from 'app/common';
import { Box, FormControlLabel, Switch } from '@mui/material';

export const WorklistTable = () => {
  // Hooks
  const { currentMenuItem, selectedMenuItem } = useAppSelector(
    state => state.menuItem
  );
  const { mockUser } = useAppSelector(state => state.appUser);
  const uid = mockUser?.id.toString() ?? '';
  const match = currentMenuItem?.url.match(/worklist\/([0-9]+)\/([0-9]+)/);
  const wlid = match ? match[2] : '';
  const { data, isLoading, isFetching, refetch } = useGetWorklistQuery(
    { wlid, uid },
    { skip: !wlid || !uid }
  );

  const [updateAction, { isLoading: isUpdating }] = useUpdateWorklistMutation();
  const dispatch = useAppDispatch();

  // States
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const [originalData, setOriginalData] = useState<Worklist[]>();

  useEffect(() => {
    dispatch(resetTableData());
    let defaultValueTmp: Record<
      string,
      string | number | boolean | ValueOption
    > = {};
    data?.columns.reduce((data, col) => {
      if (!data[col.name]) {
        data[col.name] = col.defaultValue;
      }
      return data;
    }, defaultValueTmp);

    const tableData: TableData = {
      rows: data?.rows.filter(row => !row.isTerminalState),
      columns: generateColumns(data?.columns),
      defaultValues: defaultValueTmp,
    };
    dispatch(initializeTableData(tableData));

    setOriginalData(data?.rows);
    // eslint-disable-next-line
  }, [data, currentMenuItem, selectedMenuItem]);

  useEffect(() => {
    setIsRefetching(false);
  }, [wlid]);

  useEffect(() => {
    if (originalData && originalData.length > 0) {
      if (checked) {
        dispatch(updateTableRows(originalData));
      } else {
        const updatedRows = originalData.filter(
          (row: Worklist) => !row.isTerminalState
        );

        dispatch(updateTableRows(updatedRows));
      }
    }
  }, [checked, originalData, dispatch]);

  const handleChecked = () => {
    setChecked(!checked);
  };

  const processRowUpdate = async (rowUpdate: GridRowModel) => {
    const worklistOptions = data?.columns?.find(
      col => col.name === 'status'
    )?.valueOptions;

    const worklistStatus = worklistOptions?.find(
      opt =>
        opt.value.toString().toUpperCase() ===
        rowUpdate.status.toString().toUpperCase()
    );
    const updatedRow: Worklist = {
      ...(rowUpdate as Worklist),
      status: worklistStatus,
    };

    if (wlid) {
      const reqBody = {
        wlid,
        uid,
        worklist: updatedRow,
      };

      try {
        await updateAction(reqBody)
          .unwrap()
          .then(resp => {
            dispatch(
              setAlert({
                message: 'Row updated.',
                severity: AlertSeverity.Success,
              })
            );

            refetch();
            setIsRefetching(true);
          });
      } catch (e) {
        dispatch(
          setAlert({
            message: 'Something went wrong.',
            severity: AlertSeverity.Error,
          })
        );
        return;
      }

      return updatedRow;
    }
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: '1200px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '-20px',
        }}
      >
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleChecked} />}
          label="Show previously reviewed items"
        />
      </Box>
      <DataGridTable
        cellActions={[ActionType.Edit]}
        processRowUpdate={processRowUpdate}
        isBusy={isFetching || isLoading || isUpdating}
        toolbarProps={{ enableFilter: true }}
        isRefetching={isRefetching}
      />
    </>
  );
};
