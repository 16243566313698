import { Typography } from '@mui/material';
import { useAppSelector } from 'api';
import { FlexDataTable } from '.';
import { PageContainer } from 'app';

export const FlexDataPage = () => {
  const { currentMenuItem } = useAppSelector(state => state.menuItem);

  return (
    <PageContainer>
      <Typography variant="subtitle1">{currentMenuItem?.text}</Typography>
      <FlexDataTable />
    </PageContainer>
  );
};
