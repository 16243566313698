import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertContent, AlertSeverity } from './alert';

type AlertState = {
  alertContent: AlertContent;
  openAlert: boolean;
};

const initialState: AlertState = {
  alertContent: { message: '', severity: AlertSeverity.Info },
  openAlert: false,
};

export const alertSlice = createSlice({
  name: 'alertSlice',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<AlertContent>) => {
      state.alertContent = action.payload;
      state.openAlert = true;
    },
    closeAlert: state => {
      state.openAlert = false;
    },
  },
});

export const { setAlert, closeAlert } = alertSlice.actions;
