import {
  MenuItem,
  setCurrentMenuItem,
  useAppDispatch,
  useAppSelector,
  useGetMenuItemsQuery,
  useGetRecentlyViewedMenuItemsQuery,
} from 'api';
import { TabPanelContainer } from 'app/common';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  TabPanelTitle,
  TabPanelList,
  TabPanelMenuItem,
  TabPanelMenuItemContent,
  TabPanelMenuItemIcon,
  TabPanelMenuItemTextContainer,
  TabPanelMenuItemTitle,
  TabPanelMenuItemText,
  TabPanelMenuItemTime,
  TabPanelMenuItemTimeText,
} from './TabPanel.styled';
import moment from 'moment';
import { DefaultTabPanelMenuItem } from './DefaultTabPanelMenuItem';

export const RecentlyViewedTabPanel: React.FC<{
  value: number;
  index: number;
}> = ({ value, index }) => {
  const { mockUser } = useAppSelector(state => state.appUser);
  const { data: recentlyViewedMenuItems } = useGetRecentlyViewedMenuItemsQuery(
    mockUser?.id,
    {
      skip: !mockUser,
    }
  );
  const { data: menuItems } = useGetMenuItemsQuery(mockUser?.id, {
    skip: !mockUser,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <TabPanelContainer index={index} value={value}>
      {recentlyViewedMenuItems && recentlyViewedMenuItems?.length && (
        <TabPanelTitle>Recently Viewed Reports:</TabPanelTitle>
      )}
      <TabPanelList>
        {recentlyViewedMenuItems && recentlyViewedMenuItems?.length > 0 ? (
          recentlyViewedMenuItems.map((mi: MenuItem) => {
            const originalMenuItem = menuItems?.originalMenuItems.find(
              omi => omi.id === mi.id
            );
            const parentMenuItem = menuItems?.originalMenuItems.find(
              omi => omi.id === mi.parentId
            );

            const utcDate = moment.utc(mi.lastViewed);
            const timeAgo = utcDate.fromNow();

            return (
              <TabPanelMenuItem
                component="a"
                onClick={() => {
                  navigate(`/powerbi/${mi.id}`);
                  dispatch(setCurrentMenuItem(originalMenuItem));
                }}
              >
                <TabPanelMenuItemContent>
                  <TabPanelMenuItemIcon>
                    <i
                      className={parentMenuItem?.iconClass}
                      style={{ fontSize: '24px' }}
                    />
                  </TabPanelMenuItemIcon>
                  <TabPanelMenuItemTextContainer>
                    <TabPanelMenuItemTitle>{mi.text}</TabPanelMenuItemTitle>
                    <TabPanelMenuItemText>
                      {parentMenuItem?.text}
                    </TabPanelMenuItemText>
                  </TabPanelMenuItemTextContainer>
                  <TabPanelMenuItemTime>
                    <RemoveRedEyeIcon sx={{ color: '#FFF', height: '0.6em' }} />
                    <TabPanelMenuItemTimeText>
                      {timeAgo}
                    </TabPanelMenuItemTimeText>
                  </TabPanelMenuItemTime>
                </TabPanelMenuItemContent>
              </TabPanelMenuItem>
            );
          })
        ) : (
          <DefaultTabPanelMenuItem title={'No Recently Viewed At This Time'} />
        )}
      </TabPanelList>
    </TabPanelContainer>
  );
};
