import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/axiosBaseQuery';
import { convertFlexToGridData, FlexData, FlexDataResponse } from '.';
import { BaseRequestParams } from '../requestParams';
import { GridData } from '../table';

export const flexDataApi = createApi({
  reducerPath: 'flexDataApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['FlexDataItems'],
  endpoints: builder => ({
    getFlexData: builder.query<GridData, BaseRequestParams>({
      query: request => ({
        url: `flexdata/${request.mid}/${request.uid}`,
        method: 'GET',
      }),
      transformResponse: (flexDataResponse: FlexDataResponse, meta, request) =>
        convertFlexToGridData(request.mid, flexDataResponse),
      providesTags: flexItems => [{ type: 'FlexDataItems', id: 'LIST' }],
    }),
    updateFlexData: builder.mutation<
      void,
      { request: BaseRequestParams; flexData: FlexData }
    >({
      query: ({ request, flexData }) => {
        const { id, practice, ...data } = flexData;
        return {
          url: `flexdata/${request.mid}/${request.uid}/${id}`,
          method: 'PUT',
          data: {
            practice,
            data,
          },
        };
      },
      invalidatesTags: [{ type: 'FlexDataItems', id: 'LIST' }],
    }),
    createFlexData: builder.mutation<
      void,
      { request: BaseRequestParams; flexData: FlexData }
    >({
      query: ({ request, flexData }) => {
        const { id, practice, ...data } = flexData;
        return {
          url: `flexdata/${request.mid}/${request.uid}`,
          method: 'POST',
          data: {
            practice,
            data,
          },
        };
      },
      invalidatesTags: [{ type: 'FlexDataItems', id: 'LIST' }],
    }),
    deleteFlexData: builder.mutation<
      FlexData,
      { request: BaseRequestParams; flexData: FlexData }
    >({
      query: ({ request, flexData }) => ({
        url: `flexdata/${request.mid}/${request.uid}/${flexData.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'FlexDataItems', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetFlexDataQuery,
  useUpdateFlexDataMutation,
  useCreateFlexDataMutation,
  useDeleteFlexDataMutation,
  endpoints: {
    getFlexData: { useQueryState: useGetFlexDataQueryState },
  },
} = flexDataApi;
