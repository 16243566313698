import { Box, Tooltip, Typography } from '@mui/material';
import { PatientMappingTable } from '../table/PatientMappingTable';
import React from 'react';
import {
  PatientTableRow,
  PatientTableCell,
  PatientTableCellText,
} from '../table/PatientMappingTable.styled';
import {
  OneOncologyPatient,
  PatientMappingSecondStepState,
  PatientType,
  generatePatientMappingColumns,
  setSelectedOneOncologyPatient,
  useAppDispatch,
  useAppSelector,
} from 'api';
import { useNavigate } from 'react-router-dom';
import { formatSSN } from 'api/helper';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { BasicTableHeader, BasicTableSkeleton } from 'app';
import { PatientMappingSecondStepForm } from './PatientMappingSecondStepForm';

export const PatientMappingSecondStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { currentStep, firstStepState, selectedOneOncologyPatient, isLoading } =
    useAppSelector(state => state.patientMapping);
  const secondStepState = currentStep?.data as PatientMappingSecondStepState;
  const oneOncologyPatients = secondStepState?.oneOncologyPatients;
  const navigate = useNavigate();

  function processRows() {
    function handleRowClick(row: OneOncologyPatient) {
      navigate(`/datamaintenance/patientmapping/${currentMenuItem?.id}`, {
        state: {
          step: 3,
          data: {
            ooPatient: row,
            sourcePatient: secondStepState?.sourcePatient,
            clientKey: firstStepState?.searchParams.client!,
            dataPartnerKey: firstStepState?.searchParams.dataPartner!,
          },
        },
      });

      dispatch(setSelectedOneOncologyPatient(row));
    }

    const renderRow = (row: OneOncologyPatient) => {
      const keys = Object.keys(row) as Array<keyof OneOncologyPatient>;

      return (
        <Box display="flex" flexDirection="row" gap="1em" alignItems="center">
          {selectedOneOncologyPatient &&
            selectedOneOncologyPatient.ooPatientIKey === row.ooPatientIKey && (
              <Box ml="-40px">
                <CheckCircleIcon sx={{ color: '#47A493' }} />
              </Box>
            )}
          <PatientTableRow
            onClick={() => handleRowClick(row)}
            sx={{ width: '100%' }}
            columnCount={keys.length}
          >
            {keys.map((key, index) => {
              const rowAsString = row[key] as string;
              const value: string = moment(
                rowAsString,
                'YYYY-MM-DDTHH:mm:ssZ',
                true
              ).isValid()
                ? moment(rowAsString).format('MM/SS/YYYY')
                : key.toLocaleLowerCase() === 'ssn'
                ? formatSSN(rowAsString)
                : rowAsString;

              return (
                <PatientTableCell noBorder={index === 0}>
                  <Tooltip
                    title={value}
                    PopperProps={{
                      sx: { marginTop: '-10px !important' },
                    }}
                  >
                    <PatientTableCellText
                      sx={{
                        paddingRight: index === keys.length - 1 ? '10px' : '',
                      }}
                    >
                      {value ? value : ''}
                    </PatientTableCellText>
                  </Tooltip>
                </PatientTableCell>
              );
            })}
          </PatientTableRow>
        </Box>
      );
    };

    return <>{oneOncologyPatients?.map(patient => renderRow(patient))}</>;
  }
  return (
    <>
      <PatientMappingSecondStepForm />

      {isLoading ? (
        <BasicTableSkeleton />
      ) : (
        oneOncologyPatients &&
        oneOncologyPatients.length > 0 && (
          <PatientMappingTable
            minWidth="800px"
            columns={generatePatientMappingColumns(
              oneOncologyPatients,
              PatientType.OneOncologyPatient
            )}
            processRows={processRows}
          />
        )
      )}

      {oneOncologyPatients && oneOncologyPatients.length === 0 && (
        <Box minWidth="800px">
          <BasicTableHeader columns={[]} />
          <PatientTableRow>
            <PatientTableCell noBorder sx={{ textAlign: 'center' }}>
              <Typography variant="h6">
                No results found based on the search criteria.
              </Typography>
            </PatientTableCell>
          </PatientTableRow>
        </Box>
      )}
    </>
  );
};
