import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import {
  appUserApi,
  flexDataApi,
  layoutSlice,
  menuItemApi,
  powerBiApi,
  helpApi,
  menuItemSlice,
  alertSlice,
  appUserSlice,
  userAuditLogApi,
  powerBiSlice,
  ipaApi,
  payerGroupApi,
  payerTypeApi,
  worklistApi,
  roleApi,
  dataGridTableSlice,
  chargeCodeReferenceApi,
  providerApi,
  databricksMappingApi,
  databricksSlice,
  patientMappingSlice,
  patientMappingApi,
  roleMaintenanceSlice,
  oaInsightApi,
  oaInsightSlice,
  homeApi,
} from '.';

const loggerBlacklist = ['__rtkq/focused'];

const loggerMiddleware =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? createLogger({
        collapsed: true,
        predicate: (getState, action) => {
          return (
            typeof action !== 'function' &&
            !loggerBlacklist.includes(action.type)
          );
        },
      })
    : undefined;

export const store = configureStore({
  reducer: {
    layout: layoutSlice.reducer,
    menuItem: menuItemSlice.reducer,
    appUser: appUserSlice.reducer,
    alert: alertSlice.reducer,
    powerBi: powerBiSlice.reducer,
    dataGridTable: dataGridTableSlice.reducer,
    databricks: databricksSlice.reducer,
    patientMapping: patientMappingSlice.reducer,
    roleMaintenance: roleMaintenanceSlice.reducer,
    oaInsight: oaInsightSlice.reducer,
    [appUserApi.reducerPath]: appUserApi.reducer,
    [menuItemApi.reducerPath]: menuItemApi.reducer,
    [powerBiApi.reducerPath]: powerBiApi.reducer,
    [flexDataApi.reducerPath]: flexDataApi.reducer,
    [userAuditLogApi.reducerPath]: userAuditLogApi.reducer,
    [payerTypeApi.reducerPath]: payerTypeApi.reducer,
    [payerGroupApi.reducerPath]: payerGroupApi.reducer,
    [ipaApi.reducerPath]: ipaApi.reducer,
    [worklistApi.reducerPath]: worklistApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [chargeCodeReferenceApi.reducerPath]: chargeCodeReferenceApi.reducer,
    [helpApi.reducerPath]: helpApi.reducer,
    [providerApi.reducerPath]: providerApi.reducer,
    [databricksMappingApi.reducerPath]: databricksMappingApi.reducer,
    [patientMappingApi.reducerPath]: patientMappingApi.reducer,
    [oaInsightApi.reducerPath]: oaInsightApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    loggerMiddleware
      ? getDefaultMiddleware({
          serializableCheck: false,
        }).concat(
          loggerMiddleware,
          appUserApi.middleware,
          menuItemApi.middleware,
          powerBiApi.middleware,
          flexDataApi.middleware,
          userAuditLogApi.middleware,
          payerTypeApi.middleware,
          ipaApi.middleware,
          worklistApi.middleware,
          roleApi.middleware,
          chargeCodeReferenceApi.middleware,
          helpApi.middleware,
          providerApi.middleware,
          databricksMappingApi.middleware,
          payerGroupApi.middleware,
          patientMappingApi.middleware,
          oaInsightApi.middleware,
          homeApi.middleware
        )
      : getDefaultMiddleware({
          serializableCheck: false,
        }).concat(
          appUserApi.middleware,
          menuItemApi.middleware,
          powerBiApi.middleware,
          flexDataApi.middleware,
          userAuditLogApi.middleware,
          payerTypeApi.middleware,
          ipaApi.middleware,
          worklistApi.middleware,
          roleApi.middleware,
          chargeCodeReferenceApi.middleware,
          helpApi.middleware,
          providerApi.middleware,
          databricksMappingApi.middleware,
          payerGroupApi.middleware,
          patientMappingApi.middleware,
          oaInsightApi.middleware,
          homeApi.middleware
        ),
  enhancers: getDefaultEnhancers =>
    getDefaultEnhancers({
      autoBatch: { type: 'timer', timeout: 1 },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
