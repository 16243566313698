import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseRequestParams, GridData } from 'api';
import { axiosBaseQuery } from 'api/axiosBaseQuery';
import { convertIpaResponse, convertIpaToDataGrid, Ipa } from './ipa';

export const ipaApi = createApi({
  reducerPath: 'ipaApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['IpaItems'],
  endpoints: builder => ({
    getIpa: builder.query<GridData, BaseRequestParams>({
      query: request => ({
        url: `databricks/ipa/${request.mid}/${request.uid}`,
        method: 'GET',
      }),
      transformResponse: (ipas: Ipa[], meta, request) =>
        convertIpaToDataGrid(ipas),
      providesTags: ipas => [{ type: 'IpaItems', id: 'LIST' }],
    }),
    createIpa: builder.mutation<
      Ipa[],
      { request: BaseRequestParams; ipa: Ipa }
    >({
      query: ({ request, ipa }) => ({
        url: `databricks/ipa/${request.mid}/${request.uid}`,
        method: 'POST',
        data: ipa,
      }),
      transformResponse: (ipas: Ipa[], meta, request) =>
        convertIpaResponse(ipas),
    }),
  }),
});

export const {
  useGetIpaQuery,
  useCreateIpaMutation,
  endpoints: {
    getIpa: { useQueryState: useGetIpaQueryState },
  },
} = ipaApi;
