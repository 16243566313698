import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AllMapConceptResponse,
  GridData,
  MapConcept,
  OoConceptDomain,
  convertMapConceptToDataGrid,
} from 'api';
import { axiosBaseQuery } from 'api/axiosBaseQuery';

export const databricksMappingApi = createApi({
  reducerPath: 'databricksMappingApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['DatabricksMappingItems'],
  endpoints: builder => ({
    getAllActiveMapConcepts: builder.query<
      GridData,
      { uid: string; dcKey: string }
    >({
      query: request => ({
        url: `databricks/mapping/mapconcepts/${request.uid}/${request.dcKey}`,
        method: 'GET',
      }),
      transformResponse: (response: AllMapConceptResponse, meta, request) =>
        convertMapConceptToDataGrid(response),
      providesTags: mapConcepts => [
        { type: 'DatabricksMappingItems', id: 'LIST' },
      ],
    }),
    updateMapConcept: builder.mutation<
      void,
      { request: { uid: string }; mapConcept: MapConcept }
    >({
      query: ({ request, mapConcept }) => {
        return {
          url: `databricks/mapping/updatemapconcept/${request.uid}/${mapConcept?.ooConcept}`,
          method: 'PUT',
          data: { ...mapConcept },
        };
      },
    }),
    deleteMapConcept: builder.mutation<
      MapConcept,
      { request: { uid: string }; mapConcept: MapConcept }
    >({
      query: ({ request, mapConcept }) => ({
        url: `databricks/mapping/deletemapconcept/${request.uid}`,
        method: 'PUT',
        data: { ...mapConcept },
      }),
    }),
    getConceptDomains: builder.query<OoConceptDomain[], string>({
      query: uid => ({
        url: `databricks/mapping/conceptdomains/${uid}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAllActiveMapConceptsQuery,
  useUpdateMapConceptMutation,
  useDeleteMapConceptMutation,
  useGetConceptDomainsQuery,
  endpoints: {
    getAllActiveMapConcepts: {
      useQueryState: useGetAllActiveMapConceptsQueryState,
    },
  },
} = databricksMappingApi;
