import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ErrorPageContainer, MessageContainer } from './Error.styled';
import { BiMeh } from 'react-icons/bi';

export type ErrorPageProps = {
  title: string;
  message: string;
  refreshButton?: boolean;
};

export const ErrorPage: React.FC<ErrorPageProps> = ({
  title,
  message,
  refreshButton,
}) => {
  return (
    <ErrorPageContainer>
      <Box pt="200px">
        <BiMeh color="#0EAA7C" size="150px" />
      </Box>
      <MessageContainer>
        <Typography variant="h4" color="text.secondary" pb="10px">
          {title}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          {message}
        </Typography>
        {refreshButton && (
          <Button variant="contained" onClick={() => window.location.reload()}>
            Refresh Page
          </Button>
        )}
      </MessageContainer>
    </ErrorPageContainer>
  );
};
