import { useEffect } from 'react';
import { GridRowModel } from '@mui/x-data-grid-pro';
import {
  useGetFlexDataQuery,
  useCreateFlexDataMutation,
  useDeleteFlexDataMutation,
  useUpdateFlexDataMutation,
  setAlert,
  useAppDispatch,
  useAppSelector,
  BaseRequestParams,
  TableData,
  initializeTableData,
  updateTableRows,
  resetTableData,
  FlexData,
  generateColumns,
  ValueOption,
} from 'api';
import { AlertSeverity, closeAlert } from 'api/models/alert';
import { ActionType, DataGridTable } from 'app/common';

export const FlexDataTable = () => {
  // Hooks
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { rows } = useAppSelector(state => state.dataGridTable);
  const { mockUser } = useAppSelector(state => state.appUser);
  const mid = currentMenuItem?.id.toString() ?? '';
  const uid = mockUser?.id.toString() ?? '';
  const { data, isLoading, isFetching, refetch } = useGetFlexDataQuery(
    { mid: mid, uid },
    { skip: !mid || !uid }
  );
  const [updateAction, { isLoading: isUpdating }] = useUpdateFlexDataMutation();
  const [createAction, { isLoading: isCreating }] = useCreateFlexDataMutation();
  const [deleteAction, { isLoading: isDeleting }] = useDeleteFlexDataMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetTableData());

    if (data && data.rows && data.rows.length > 0) {
      let defaultValueTmp: Record<
        string,
        string | number | boolean | ValueOption
      > = {};
      data.columns.reduce((data, col) => {
        if (!data[col.name]) {
          data[col.name] = col.defaultValue;
        }
        return data;
      }, defaultValueTmp);

      const tableData: TableData = {
        rows: data.rows,
        columns: generateColumns(data?.columns),
        defaultValues: defaultValueTmp,
      };
      dispatch(initializeTableData(tableData));
    }
  }, [dispatch, data]);

  const handleDelete = (deletedRow: GridRowModel) => async () => {
    // Only run if we have the menu item id and table is populated
    if (mid) {
      const reqBody = {
        request: { mid, uid } as BaseRequestParams,
        flexData: deletedRow as FlexData,
      };
      try {
        await deleteAction(reqBody)
          .unwrap()
          .then(() => {
            dispatch(
              updateTableRows(
                rows.filter((row: any) => row.id !== deletedRow.id)
              )
            );
            dispatch(
              setAlert({
                message: 'Row deleted.',
                severity: AlertSeverity.Success,
              })
            );
          });
      } catch (e) {
        closeAlert();
        dispatch(
          setAlert({
            message: 'Something went wrong.',
            severity: AlertSeverity.Error,
          })
        );
      }
    }
  };

  const processRowUpdate = async (rowUpdate: GridRowModel) => {
    // Only run if we have the menu item id and table is populated
    let dispatchMessage = '';
    if (mid) {
      const updatedRow = { ...rowUpdate, isNew: false };
      const reqBody = {
        request: { mid, uid } as BaseRequestParams,
        flexData: rowUpdate as FlexData,
      };

      try {
        if (rowUpdate.isNew)
          await createAction(reqBody)
            .unwrap()
            .then(() => (dispatchMessage = 'Row added.'));
        else
          await updateAction(reqBody)
            .unwrap()
            .then(() => (dispatchMessage = 'Row updated.'));
      } catch (e) {
        dispatch(
          setAlert({
            message: 'Something went wrong.',
            severity: AlertSeverity.Error,
          })
        );
        return;
      }

      refetch();
      dispatch(
        setAlert({
          message: dispatchMessage,
          severity: AlertSeverity.Success,
        })
      );
      return updatedRow;
    }
  };

  return (
    <DataGridTable
      cellActions={[ActionType.Add, ActionType.Delete, ActionType.Edit]}
      handleDelete={handleDelete}
      processRowUpdate={processRowUpdate}
      isBusy={isFetching || isLoading || isUpdating || isCreating || isDeleting}
      toolbarProps={{ enableAddButton: true, enableFilter: true }}
    />
  );
};
