import React from 'react';
import { Check, Clear } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  AlertSeverity,
  RoleClaim,
  UpdateRoleRequest,
  setAlert,
  useAppDispatch,
  useAppSelector,
  useGetRoleClaimsForRoleQuery,
  useUpdateRoleClaimsMutation,
} from 'api';

export const RoleMaintenanceSaveButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const [updateAction, { isLoading: isUpdating, isSuccess, isError }] =
    useUpdateRoleClaimsMutation();
  const { selectedRole, currentRoleClaims } = useAppSelector(
    state => state.roleMaintenance
  );
  const { refetch } = useGetRoleClaimsForRoleQuery(selectedRole?.id, {
    skip: !selectedRole,
  });

  async function handleSave() {
    if (selectedRole) {
      const roleClaims: RoleClaim[] | undefined = currentRoleClaims
        ?.map(rc => (rc.active ? rc : undefined))
        .filter((x): x is RoleClaim => x !== undefined);

      const updateRoleRequest: UpdateRoleRequest = {
        rid: selectedRole?.id,
        roleClaims: roleClaims ? roleClaims : [],
      };

      if (roleClaims) {
        await updateAction(updateRoleRequest)
          .unwrap()
          .then(() => {
            dispatch(
              setAlert({
                message: 'Roles saved.',
                severity: AlertSeverity.Success,
              })
            );
            refetch();
          })
          .catch(() => {
            dispatch(
              setAlert({
                message: 'Something went wrong.',
                severity: AlertSeverity.Error,
              })
            );
          });
      }
    }
  }

  const getStartIcon = () => {
    if (isUpdating)
      return <CircularProgress size={20} sx={{ color: grey[50] }} />;
    else if (isSuccess) return <Check sx={{ color: grey[50] }} />;
    else if (isError) return <Clear sx={{ color: grey[50] }} />;
  };

  return (
    <Box display="flex" justifyContent="center" paddingTop="5px">
      {currentRoleClaims && selectedRole && (
        <Button
          variant="contained"
          size="large"
          sx={{ width: '300px', display: 'flex', alignItems: 'center' }}
          onClick={handleSave}
          startIcon={getStartIcon()}
        >
          <Typography>Save</Typography>
        </Button>
      )}
    </Box>
  );
};
