import { ColumnConfiguration, GridData } from 'api';
import { v4 as uuidv4 } from 'uuid';

export type OaInsightPublication = {
  oaInsightPublicationKey: number;
  oaInsightkey: string;
  description: string;
  sendCronExpression: string;
  parms: string;
  ooClientKeyList: string;
  enabledFlag: boolean;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
  oaInsightPublicationGroupKey: number;
};

export type OaInsightSubscriber = {
  oaInsightSubscriberKey: number;
  oaInsightPublicationKey: number;
  ooUserID: string;
  subscriberAddress: string;
  subscriberFieldType: string;
  enabledFlag: boolean;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
};

const oaInsightSubscriberColumns: ColumnConfiguration[] = [
  {
    width: 150,
    name: 'oaInsightPublicationKey',
    displayName: 'Publication Key',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: true,
  },
  {
    width: 250,
    name: 'subscriberAddress',
    displayName: 'Subscriber Address',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: true,
  },
  {
    width: 150,
    name: 'subscriberFieldType',
    displayName: 'Subscriber Field Type',
    type: 'singleSelect',
    valueOptions: [
      { value: 'EMAILTO', label: 'EMAILTO' },
      { value: 'EMAILCC', label: 'EMAILCC' },
      { value: 'EMAILBCC', label: 'EMAILBCC' },
    ],
    required: true,
    defaultValue: { value: 'EMAILTO', label: 'EMAILTO' },
    editable: true,
  },
  {
    name: 'enabledFlag',
    displayName: 'Enabled',
    type: 'boolean',
    required: true,
    defaultValue: false,
    editable: true,
    width: 150,
  },
  {
    name: 'ooUserID',
    displayName: 'User ID',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    width: 150,
  },
  {
    name: 'createdBy',
    displayName: 'Created By',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    minWidth: 150,
    enableTooltip: true,
  },
  {
    name: 'createdDate',
    displayName: 'Date Created',
    type: 'date',
    required: true,
    defaultValue: new Date().toUTCString(),
    editable: false,
    width: 200,
  },
  {
    name: 'updatedBy',
    displayName: 'Last Updated By',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    minWidth: 150,
    enableTooltip: true,
  },
  {
    name: 'updatedDate',
    displayName: 'Last Updated',
    type: 'date',
    required: true,
    defaultValue: new Date().toUTCString(),
    editable: false,
    minWidth: 120,
  },
  {
    name: 'actions',
    type: 'actions',
    displayName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    required: true,
    editable: false,
    defaultValue: '',
  },
];

export const convertOaInsightSubscribersToDataGrid = (
  subscribers: OaInsightSubscriber[]
): GridData => {
  const rows = subscribers.map(subscriber => ({
    ...subscriber,
    subscriberFieldType: {
      value: subscriber.subscriberFieldType,
      label: subscriber.subscriberFieldType,
    },
    id: uuidv4(),
    isNew: false,
  }));

  return {
    columns: oaInsightSubscriberColumns,
    rows: rows,
  };
};
