import { OoConcept } from './ooConcept';
import { OoConceptDomain } from './ooConceptDomain';
import {
  AllMapConceptResponse,
  ColumnConfiguration,
  GridData,
  ValueOption,
} from 'api';
import { v4 as uuidv4 } from 'uuid';

export type MapConcept = {
  ooConceptKey?: number | null;
  ooConceptDomainKey: number;
  ooClientKey: string;
  ooDataPartnerKey: string;
  srcConceptKey: string;
  enabledFlag: boolean;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
  ooConcept?: OoConcept;
  ooConceptDomain?: OoConceptDomain;
};

const mapConceptColumns: ColumnConfiguration[] = [
  {
    name: 'ooClientKey',
    displayName: 'Client',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    minWidth: 100,
  },
  {
    name: 'ooDataPartnerKey',
    displayName: 'Data Partner',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    minWidth: 120,
  },
  {
    name: 'srcConceptKey',
    displayName: 'Source Key',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: true,
    flex: 1,
  },
  {
    name: 'ooConcept',
    displayName: 'Concept',
    type: 'singleSelect',
    required: true,
    defaultValue: '',
    editable: true,
    flex: 1,
  },
  {
    name: 'updatedBy',
    displayName: 'Last Updated By',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    minWidth: 150,
    enableTooltip: true,
  },
  {
    name: 'updatedDate',
    displayName: 'Last Updated',
    type: 'date',
    required: true,
    defaultValue: new Date().toUTCString(),
    editable: false,
    minWidth: 120,
  },
  {
    name: 'actions',
    type: 'actions',
    displayName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    required: true,
    editable: false,
    defaultValue: '',
  },
];

export const convertMapConceptToDataGrid = (
  mapConceptResponse: AllMapConceptResponse
): GridData => {
  let conceptValueOptions = mapConceptResponse.concepts.map(concept => {
    const valueOption: ValueOption = {
      value: concept.ooConceptKey,
      label: concept.conceptName,
    };
    return valueOption;
  });
  conceptValueOptions.unshift({
    value: 0,
    label: 'None',
  });

  const rows = mapConceptResponse.mapConcepts.map(mapConcept => ({
    ...mapConcept,
    ooConceptKey: mapConcept.ooConceptKey ? mapConcept.ooConceptKey : 0,
    ooConcept: mapConcept.ooConcept
      ? ({
          value: mapConcept.ooConcept?.ooConceptKey,
          label: mapConcept.ooConcept?.conceptName,
        } as ValueOption)
      : ({
          value: 0,
          label: 'None',
        } as ValueOption),
    id: uuidv4(),
    isNew: false,
  }));

  const columns = mapConceptColumns.map(column => {
    if (column.name === 'ooConcept')
      return {
        ...column,
        valueOptions: conceptValueOptions,
      };

    return column;
  });

  return {
    columns: columns,
    rows: rows,
  };
};
