import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/axiosBaseQuery';
import { Role, UpdateRoleRequest } from './role';
import { RoleClaim, addActiveStatusToRoleClaims } from '.';

export const roleApi = createApi({
  reducerPath: 'userRoleApi',
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    getRoles: builder.query<Role[], void>({
      query: () => ({
        url: 'roles',
        method: 'GET',
      }),
    }),
    getRoleClaimsForRole: builder.query<RoleClaim[], number | undefined>({
      query: rid => ({
        url: `roles/${rid}/claims`,
        method: 'GET',
      }),
    }),
    getAllRoleClaims: builder.query<RoleClaim[], void>({
      query: () => ({
        url: `roles/claims/all`,
        method: 'GET',
      }),
      transformResponse: (roleClaims: RoleClaim[], meta, request) =>
        addActiveStatusToRoleClaims(roleClaims),
    }),
    updateRoleClaims: builder.mutation<void, UpdateRoleRequest>({
      query: req => ({
        url: `roles/${req.rid}/claims`,
        method: 'PUT',
        data: req.roleClaims,
      }),
    }),
    addRole: builder.mutation<Role, Role>({
      query: req => ({
        url: 'roles',
        method: 'POST',
        data: req
      })
    }),
  }),
});

export const {
  endpoints: {
    getRoles: { useQueryState: useGetRolesQueryState },
  },
  useGetRolesQuery,
  useGetRoleClaimsForRoleQuery,
  useGetAllRoleClaimsQuery,
  useUpdateRoleClaimsMutation,
  useAddRoleMutation,
} = roleApi;
