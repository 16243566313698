import { Box, List } from '@mui/material';
import { useAppSelector, useGetMenuItemsQuery } from '../../../api';
import { CollapsedMenuItem } from '.';

export const CollapsedMenu = () => {
  // Hooks
  const { mockUser } = useAppSelector(state => state.appUser);
  const { data: menuItems } = useGetMenuItemsQuery(mockUser?.id, {
    skip: !mockUser,
  });

  return (
    <List>
      {menuItems?.menuItemsDisplay?.map((mi, index) => (
        <Box key={mi.id} width="50px" ml="7px">
          {mi.children.map(ci => (
            <CollapsedMenuItem key={ci.id} menuItem={ci} />
          ))}
        </Box>
      ))}
    </List>
  );
};
