import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'api';
import { ChargeCodeReferenceTable } from './ChargeCodeReferenceTable';
import { PageContainer } from 'app';

export const ChargeCodeReferencePage = () => {
  const { currentMenuItem } = useAppSelector(state => state.menuItem);

  return (
    <PageContainer>
      <Box m="20px 100px 0 100px" flex="1 1 auto">
        <Typography variant="subtitle1">{currentMenuItem?.text}</Typography>
        <ChargeCodeReferenceTable />
      </Box>
    </PageContainer>
  );
};
