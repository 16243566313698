export type PatientMappingStepState = {
  step: number;
  data:
    | PatientMappingFirstStepState
    | PatientMappingSecondStepState
    | PatientMappingThirdStepState;
};

export type PatientMappingFirstStepState = {
  unmappedPatients: UnmappedPatient[];
  searchParams: UnmappedPatientSearchParams;
};

export type UnmappedPatient = {
  sourcePatientKey: string;
  ooPatientIKey: number;
  mrn: string;
  firstName: string;
  lastName: string;
  ssn: string;
  dob: Date;
};

export type GetUnmappedPatientsResponse = {
  ooClientKey: string;
  ooDataPartnerKey: string;
  srcPatientKey: string;
  ooPatientKey: string;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
  ooPatientIKey: number;
  firstName: string;
  lastName: string;
  ssn: string;
  mrn: string;
  mapRuleKey: string;
  mapVerifiedBy: string;
  srcPatientStatus: string;
  srcLastUpdateDate: Date;
  ownershipScore: number;
  dob: Date;
};

export type GetOneOncologyPatientsResponse = {
  fullName: string;
  mrn: string;
  ooPatientIKey: number;
  ooClientKey: string;
  dob: Date;
  ssn: string;
};

export type UnmappedPatientSearchParams = {
  client: string;
  dataPartner: string;
  sourcePatientKey: string;
  firstName: string;
  lastName: string;
  ssn: string;
  dob: Date;
  error: { [key: string]: string };
  hasError: boolean;
};

export type PatientMappingSecondStepState = {
  oneOncologyPatients: OneOncologyPatient[];
  searchParams: OneOncologyPatientSearchParams;
  sourcePatient: UnmappedPatient;
};

export type OneOncologyPatientSearchParams = {
  ooPatientIKey: number;
  mrn: string;
  firstName: string;
  lastName: string;
  error: { [key: string]: string };
  hasError: boolean;
};

export type PatientMappingThirdStepState = {
  sourcePatient: UnmappedPatient;
  ooPatient: OneOncologyPatient;
};

export type UnmappedPatientSearchRequest = {
  ooClientKey: string;
  anchorOoClientGroupKey: string;
  ooDataPartnerKey: string;
  srcPatientKey?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  ssn?: string | null;
  dob?: Date | null;
  searchLimit?: number | null;
};

export type OneOncologyPatientSearchRequest = {
  ooClientKey: string;
  anchorOoClientGroupKey: string;
  ooPatientIKey?: number | null;
  mrn?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  searchLimit?: number | null;
};

export type OneOncologyPatient = {
  fullName: string;
  mrn: string;
  ooPatientIKey: number;
  ooClientKey: string;
};

export type PatientMappingOptions = {
  clients: OneOncologyClient[];
  dataPartners: OoDataPartner[];
};

export type OneOncologyClient = {
  ooClientKey: string;
  anchorOOClientGroupKey: string;
};

export type OoDataPartner = {
  ooDataPartnerKey: string;
};

export type UpdateMapPatientRequest = {
  ooPatientIKey: number;
  ooClientKey: string;
  ooDataPartnerKey: string;
  srcPatientKey: string;
};

export const PatientType = {
  UnmappedPatient: 'UnmappedPatient',
  OneOncologyPatient: 'OneOncologyPatient',
};

export function generatePatientMappingColumns(
  list: any[],
  patientType: string
) {
  let keys = [''];
  switch (patientType) {
    case PatientType.UnmappedPatient:
      keys = Object.keys(list[0]) as Array<keyof UnmappedPatient>;
      break;
    case PatientType.OneOncologyPatient:
      keys = Object.keys(list[0]) as Array<keyof OneOncologyPatient>;
      break;

    default:
      break;
  }

  const columns = keys!.map(key => {
    switch (key) {
      case 'sourcePatientKey':
        return 'Source Patient Key';
      case 'ooPatientIKey':
        if (patientType === PatientType.OneOncologyPatient)
          return 'OOPatientIKey';

        return 'Mapped to OOPatientIKey';
      case 'mrn':
        return 'MRN';
      case 'firstName':
        return 'First Name';
      case 'lastName':
        return 'Last Name';
      case 'fullName':
        return 'Full Name';
      case 'ssn':
        return 'SSN';
      case 'dob':
        return 'Date of Birth';
      case 'ooClientKey':
        return 'Client Key';
      default:
        return '';
    }
  });

  return columns;
}
