import { createSlice } from '@reduxjs/toolkit';

type LayoutState = {
  drawerWidth: number;
  isDrawerOpen: boolean;
};

const DrawerWidth = 280;

const initialState: LayoutState = {
  drawerWidth: DrawerWidth,
  isDrawerOpen: true,
};

export const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    toggleDrawer: state => {
      state.drawerWidth = state.drawerWidth ? 0 : DrawerWidth;
      state.isDrawerOpen = state.drawerWidth > 0;
    },
  },
});

export const { toggleDrawer } = layoutSlice.actions;
