import { createApi } from '@reduxjs/toolkit/query/react';
import { ReactNode } from 'react';
import { convertRecentUpdatesContentToReactElement } from '.';
import { axiosBaseQuery } from '../..';

export const homeApi = createApi({
  reducerPath: 'homeApi',
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    getRecentUpdatesContent: builder.query<ReactNode, void>({
      query: () => ({
        url: `home/recentUpdates`,
        method: 'GET',
      }),
      transformResponse: (recentUpdatesContent: string) => {
        if (!recentUpdatesContent) return null;
        return convertRecentUpdatesContentToReactElement(recentUpdatesContent);
      },
    }),
  }),
});

export const { useGetRecentUpdatesContentQuery } = homeApi;
