import { Box, Tab, Tabs } from '@mui/material';
import { FavoritesTabPanel } from './tabPanels/FavoritesTabPanel';
import { RecentlyViewedTabPanel } from './tabPanels/RecentlyViewedTabPanel';
import { useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarIcon from '@mui/icons-material/Star';

export const MenuItemNavigationTabContainer: React.FC = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab
            icon={<StarIcon />}
            iconPosition="start"
            label="Favorites"
            {...a11yProps(0)}
          />
          <Tab
            icon={<AccessTimeIcon />}
            iconPosition="start"
            label="Recently Viewed"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <FavoritesTabPanel value={value} index={0} />
      <RecentlyViewedTabPanel value={value} index={1} />
    </Box>
  );
};
