import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuItem } from './menuItem';

type MenuItemState = {
  selectedMenuItem: MenuItem | undefined; // This menu item represents the last menu item clicked by the user
  currentMenuItem: MenuItem | undefined; // This menu item represents the menu item based on the menu item id in the url(i.e. /powerbi/4)
};

export const defaultMenuItem: MenuItem = {
  id: -1,
  parentId: -1,
  parentMenuItemText: '',
  description: '',
  displayOrder: -1,
  text: '',
  iconClass: '',
  type: -1,
  url: '',
  roles: [],
};

const initialState: MenuItemState = {
  selectedMenuItem: defaultMenuItem,
  currentMenuItem: defaultMenuItem,
};

interface CurrentMenuItemActionState {
  menuItems: MenuItem[];
  mid: string;
}

export const menuItemSlice = createSlice({
  name: 'menuItemSlice',
  initialState,
  reducers: {
    setSelectedMenuItem: (
      state,
      action: PayloadAction<MenuItem | undefined>
    ) => {
      state.selectedMenuItem = action.payload;
    },
    setCurrentMenuItemById: (
      state,
      action: PayloadAction<CurrentMenuItemActionState>
    ) => {
      const menuItems = action.payload.menuItems;
      const mid = action.payload.mid;
      const currentMenuItem = menuItems.find(
        (mi: MenuItem) => mi.id === parseInt(mid)
      );

      state.currentMenuItem = currentMenuItem;
    },
    setCurrentMenuItem: (
      state,
      action: PayloadAction<MenuItem | undefined>
    ) => {
      state.currentMenuItem = action.payload;
    },
    resetMenuItemState: state => {
      state.selectedMenuItem = undefined;
      state.currentMenuItem = undefined;
    },
  },
});

export const {
  setSelectedMenuItem,
  setCurrentMenuItemById,
  setCurrentMenuItem,
  resetMenuItemState,
} = menuItemSlice.actions;
