import { createTheme } from '@mui/material';

const OneOncColors = {
  green: '#1b806d',
};

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Basis Grotesque',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    mode: 'light',
    primary: {
      main: OneOncColors.green,
    },
    secondary: {
      main: '#326eb3',
    },
  },
  components: {
    // MuiToolbar: {
    //   styleOverrides: {
    //     root: {
    //       height: 70,
    //     },
    //   },
    // },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: OneOncColors.green,
        },
      },
    },
  },
  //custom theme variables
  bg: {
    main: '#fff',
    light: '#F4F5F7',
  },
  text: {
    main: '#172B4D',
    light: '#262930',
  },
});
