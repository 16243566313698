import { GridRowModel } from '@mui/x-data-grid-pro';
import {
  useAppDispatch,
  resetTableData,
  TableData,
  generateColumns,
  initializeTableData,
  MenuItem,
  useGetMenuItemsByTypeQuery,
  ValueOption,
} from 'api';
import { DataGridTable, ActionType } from 'app/common';
import { useEffect } from 'react';
import { AddEditMenuItemModal } from '.';

export const MenuItemMaintenanceTable = () => {
  // Hooks
  const { data, isLoading, isFetching } = useGetMenuItemsByTypeQuery(1);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetTableData());
    let defaultValueTmp: Record<
      string,
      string | number | boolean | ValueOption
    > = {};
    data?.columns.reduce((data, col) => {
      if (!data[col.name]) {
        data[col.name] = col.defaultValue;
      }
      return data;
    }, defaultValueTmp);

    const tableData: TableData = {
      rows: data?.rows,
      columns: generateColumns(data?.columns),
      defaultValues: defaultValueTmp,
    };
    dispatch(initializeTableData(tableData));

    // eslint-disable-next-line
  }, [data]);

  const processRowUpdate = async (rowUpdate: GridRowModel) => {
    const updatedRow: MenuItem = { ...rowUpdate } as MenuItem;

    return updatedRow;
  };

  return (
    <DataGridTable
      cellActions={[ActionType.Edit]}
      processRowUpdate={processRowUpdate}
      isBusy={isFetching || isLoading}
      toolbarProps={{
        enableFilter: true,
        enableAddButton: true,
        newRowModal: <AddEditMenuItemModal />,
      }}
      enableEditModal
    />
  );
};
