import { ColumnConfiguration, GridData, ValueOption } from 'api';
import { v4 as uuidv4 } from 'uuid';

export type Provider = {
  id: string;
  isNew: boolean;
  ooProviderKey: string;
  ooClientKeyList: number;
  npi: string;
  providerFullName: string;
  taxonomyCode: string;
  taxonomyDescription: string;
  ooProviderCertification: ValueOption;
  ooProviderSpecialty: ValueOption;
  providerType: string;
  createdDate: Date;
};

export type ProviderResponse = {
  ooProviders: Provider[];
  ooProviderSpecialties: ValueOption[];
  ooProviderCertifications: ValueOption[];
};

//TODO: Return columns from API endpoint
const providerColumns: ColumnConfiguration[] = [
  {
    name: 'ooClientKeyList',
    displayName: 'Practice(s)',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    flex: 1,
  },
  {
    name: 'npi',
    displayName: 'NPI',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    flex: 1,
  },
  {
    name: 'providerFullName',
    displayName: 'Name',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: true,
    flex: 1,
  },
  {
    name: 'ooProviderCertification',
    displayName: 'Certification',
    type: 'singleSelect',
    required: true,
    defaultValue: '',
    editable: true,
    flex: 1,
  },
  {
    name: 'ooProviderSpecialty',
    displayName: 'Specialization',
    type: 'singleSelect',
    required: true,
    defaultValue: '',
    editable: true,
    flex: 1,
  },
  {
    name: 'providerType',
    displayName: 'Provider Type',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    flex: 1,
  },
  {
    name: 'actions',
    type: 'actions',
    displayName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    required: true,
    editable: false,
    defaultValue: '',
  },
];

export const convertProvidersToDataGrid = (
  providerResponse: ProviderResponse
): GridData => {
  const rows = providerResponse.ooProviders.map((provider: any) => ({
    ...provider,
    id: uuidv4(),
    isNew: false,
  }));

  const columns = providerColumns.map(column => {
    if (column.name === 'ooProviderSpecialty')
      return {
        ...column,
        valueOptions: providerResponse.ooProviderSpecialties,
      };
    if (column.name === 'ooProviderCertification') {
      return {
        ...column,
        valueOptions: providerResponse.ooProviderCertifications,
      };
    }
    return column;
  });

  return {
    columns: columns,
    rows: rows,
  };
};
