import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppUser } from './appUser';
import { appUserApi } from './appUser.api';

type AppUserState = {
  user: AppUser | null;
  mockUser: AppUser | null;
  isImpersonating: boolean;
};

const initialState: AppUserState = {
  user: null,
  mockUser: null,
  isImpersonating: false,
};

export const appUserSlice = createSlice({
  name: 'appUserSlice',
  initialState,
  reducers: {
    logoutUser: state => {
      state.user = null;
    },
    setMockUser: (state, action: PayloadAction<AppUser>) => {
      state.mockUser = action.payload;
      state.isImpersonating = true;
    },
    exitImpersonation: state => {
      const user = state.user ? { ...state.user } : null;
      state.mockUser = user;
      state.isImpersonating = false;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      appUserApi.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        state.user = { ...payload };
        state.mockUser = { ...payload };
      }
    );
  },
});

export const { logoutUser, setMockUser, exitImpersonation } =
  appUserSlice.actions;
