import { useEffect } from 'react';
import { GridRowModel } from '@mui/x-data-grid-pro';
import {
  setAlert,
  useAppDispatch,
  useAppSelector,
  BaseRequestParams,
  TableData,
  initializeTableData,
  resetTableData,
  generateColumns,
  updateTableRows,
} from 'api';
import { AlertSeverity } from 'api/models/alert';
import {
  Ipa,
  useCreateIpaMutation,
  useGetIpaQuery,
} from 'api/models/databricks/ipa';
import { ActionType, DataGridTable } from 'app/common';

export const IpaTable = () => {
  // Hooks
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { rows } = useAppSelector(state => state.dataGridTable);
  const { mockUser } = useAppSelector(state => state.appUser);
  const mid = currentMenuItem?.id.toString() ?? '';
  const uid = mockUser?.id.toString() ?? '';
  const { data, isLoading, isFetching } = useGetIpaQuery(
    { mid: mid, uid },
    { skip: mid === '-1' || uid === '-1' }
  );

  const [createIpa, { isLoading: isCreating }] = useCreateIpaMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetTableData());

    const tableData: TableData = {
      rows: data?.rows,
      columns: generateColumns(data?.columns),
    };

    dispatch(initializeTableData(tableData));
  }, [dispatch, data]);

  const processRowUpdate = async (rowUpdate: GridRowModel) => {
    let updatedRow = { ...rowUpdate, isNew: false };

    if (rowExists(rowUpdate as Ipa)) {
      dispatch(
        setAlert({
          message: 'Row already exists.',
          severity: AlertSeverity.Error,
        })
      );
      return;
    }
    if (rowUpdate.isNew) {
      const reqBody = {
        request: { mid, uid } as BaseRequestParams,
        ipa: {
          ...(rowUpdate as Ipa),
          ipaName: rowUpdate.ipaName.trim(),
        },
      };
      try {
        await createIpa(reqBody)
          .unwrap()
          .then((response: any) => {
            dispatch(
              setAlert({
                message: 'Row added.',
                severity: AlertSeverity.Success,
              })
            );

            const updatedRows = [...rows]
              .concat(response)
              .filter(row => !row.isNew);

            dispatch(updateTableRows(updatedRows));
          });
      } catch (err: any) {
        dispatch(
          setAlert({
            message: err.response.data,
            severity: AlertSeverity.Error,
          })
        );
        return;
      }
    }

    return updatedRow;
  };

  function rowExists(newRow: Ipa) {
    let found = false;
    rows.forEach((row: Ipa) => {
      if (
        row.ipaName &&
        row.ipaName.toUpperCase() === newRow.ipaName.toUpperCase()
      ) {
        found = true;
        return;
      }
    });
    return found;
  }

  return (
    <DataGridTable
      cellActions={[ActionType.SaveOnly]}
      processRowUpdate={processRowUpdate}
      isBusy={isFetching || isLoading || isCreating}
      toolbarProps={{ enableAddButton: true, enableFilter: true }}
    />
  );
};
