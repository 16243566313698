import { Box, List, ListItem } from '@mui/material';
import { useAppSelector, useGetMenuItemsQuery } from '../../../api';
import { MenuItem, MenuItemHeader } from '.';

export const Menu = () => {
  // Hooks
  const { mockUser } = useAppSelector(state => state.appUser);
  const { data: menuItems } = useGetMenuItemsQuery(mockUser?.id, {
    skip: !mockUser,
  });

  return (
    <List>
      {menuItems?.menuItemsDisplay?.map((mi, index) => (
        <Box key={mi.id}>
          <ListItem key={mi.id} disablePadding sx={{ display: 'block' }}>
            <MenuItemHeader
              sx={{
                fontWeight: 600,
                padding: '12px 20px',
              }}
            >
              {mi.text}
            </MenuItemHeader>
          </ListItem>
          {mi.children.map(ci => (
            <MenuItem key={ci.id} menuItem={ci} />
          ))}
        </Box>
      ))}
    </List>
  );
};
