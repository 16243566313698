import { styled } from '@mui/material/styles';

export const MenuItemHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  fontSize: 15,
  fontVariantCaps: 'small-caps',
  color: '#dde5ed',
  fontWeight: 600,
  // // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));
