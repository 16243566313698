import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OaInsightPublication } from '.';

type OaInsightState = {
  selectedPublication: OaInsightPublication;
};

const initialState: OaInsightState = {
  selectedPublication: {
    oaInsightPublicationKey: 0,
    oaInsightkey: '',
    description: '',
    sendCronExpression: '',
    parms: '',
    ooClientKeyList: '',
    enabledFlag: false,
    createdDate: new Date(),
    createdBy: '',
    updatedDate: new Date(),
    updatedBy: '',
    oaInsightPublicationGroupKey: 0,
  },
};

export const oaInsightSlice = createSlice({
  name: 'oaInsightSlice',
  initialState,
  reducers: {
    setSelectedPublication: (
      state,
      action: PayloadAction<OaInsightPublication>
    ) => {
      state.selectedPublication = action.payload;
    },
  },
});

export const { setSelectedPublication } = oaInsightSlice.actions;
