import { Box, Typography } from '@mui/material';
import {
  AlertSeverity,
  PatientMappingThirdStepState,
  UpdateMapPatientRequest,
  resetPatientMappingState,
  setAlert,
  useAppDispatch,
  useAppSelector,
  useUpdateMapPatientMutation,
} from 'api';
import { useNavigate } from 'react-router-dom';
import { PatientCard } from './PatientCard';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

export const PatientMappingThirdStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { currentStep, firstStepState } = useAppSelector(
    state => state.patientMapping
  );
  const thirdStepState = currentStep?.data as PatientMappingThirdStepState;
  const { sourcePatient, ooPatient } = thirdStepState;
  const [updateMapPatient, { isLoading }] = useUpdateMapPatientMutation();
  const navigate = useNavigate();

  const handleConfirm = async () => {
    const request: UpdateMapPatientRequest = {
      ooPatientIKey: ooPatient.ooPatientIKey,
      ooClientKey: firstStepState?.searchParams.client!,
      ooDataPartnerKey: firstStepState?.searchParams.dataPartner!,
      srcPatientKey: sourcePatient.sourcePatientKey,
    };

    try {
      await updateMapPatient(request).unwrap();
      dispatch(resetPatientMappingState());
      navigate(`/datamaintenance/patientmapping/${currentMenuItem?.id}`, {
        state: {
          step: 1,
          data: undefined,
        },
        replace: true,
      });
      dispatch(
        setAlert({
          message: 'Patient mapping saved successfully.',
          severity: AlertSeverity.Success,
        })
      );
    } catch (e) {
      dispatch(
        setAlert({
          message: 'Something went wrong trying to save patient mapping.',
          severity: AlertSeverity.Error,
        })
      );
    }
  };

  return (
    <Box>
      <Typography variant="h6" textAlign="center" margin="3em">
        Are you sure you would like to map the following patient?
      </Typography>
      <Box maxWidth="600px" margin="auto">
        <Box display="flex" gap="2em">
          <Box width="100%">
            <Typography textAlign="center" variant="subtitle1" fontWeight={700}>
              Source Patient
            </Typography>
            <PatientCard
              sourcePatientKey={sourcePatient?.sourcePatientKey}
              name={`${sourcePatient?.firstName} ${sourcePatient?.lastName}`}
              mrn={sourcePatient.mrn}
            />
          </Box>
          <Box width="100%">
            <Typography textAlign="center" variant="subtitle1" fontWeight={700}>
              OneOncology Patient
            </Typography>
            <PatientCard
              ooPatientIKey={ooPatient?.ooPatientIKey}
              name={ooPatient?.fullName}
              mrn={ooPatient.mrn}
            />
          </Box>
        </Box>
      </Box>
      <LoadingButton
        variant="contained"
        size="small"
        sx={{
          height: '3em',
          margin: '2em auto 1em auto',
          padding: '0 1em 0 1em',
          display: 'flex',
        }}
        onClick={handleConfirm}
        loading={isLoading}
        loadingPosition="start"
        startIcon={<SaveIcon />}
      >
        <span style={{ paddingTop: '3px' }}>Confirm</span>
      </LoadingButton>
    </Box>
  );
};
