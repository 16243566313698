import {
  OneOncologyPatientSearchParams,
  UnmappedPatientSearchParams,
} from 'api/models';

export function validateUnmappedPatientSearch(
  params: UnmappedPatientSearchParams
): UnmappedPatientSearchParams {
  let paramsCpy: UnmappedPatientSearchParams = { ...params };
  paramsCpy.error = {};
  paramsCpy.hasError = false;

  const clientError = validateClient(paramsCpy.client);
  if (clientError) {
    paramsCpy.error = { ...paramsCpy.error, client: clientError };
    paramsCpy.hasError = true;
  }
  const dataPartnerError = validateDataPartner(paramsCpy.dataPartner);
  if (dataPartnerError) {
    paramsCpy.error = { ...paramsCpy.error, dataPartner: dataPartnerError };
    paramsCpy.hasError = true;
  }

  return paramsCpy;
}

export function validateOneOncologyPatientSearch(
  params: OneOncologyPatientSearchParams
): OneOncologyPatientSearchParams {
  let paramsCpy: OneOncologyPatientSearchParams = { ...params };
  paramsCpy.error = {};
  paramsCpy.hasError = false;

  //   const clientError = validateClient(paramsCpy.client);
  //   if (clientError) {
  //     paramsCpy.error = { ...paramsCpy.error, client: clientError };
  //     paramsCpy.hasError = true;
  //   }
  //   const dataPartnerError = validateDataPartner(paramsCpy.dataPartner);
  //   if (dataPartnerError) {
  //     paramsCpy.error = { ...paramsCpy.error, dataPartner: dataPartnerError };
  //     paramsCpy.hasError = true;
  //   }

  return paramsCpy;
}

export function validateSearchParam(param: string, value?: string | null) {
  switch (param) {
    case 'client':
      return validateClient(param);
    case 'dataPartner':
      return validateDataPartner(param);
  }
}

function validateClient(value: string) {
  if (!value) {
    return 'Client cannot be empty';
  }
}

function validateDataPartner(value: string) {
  if (!value) {
    return 'Data partner cannot be empty';
  }
}
