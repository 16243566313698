import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseRequestParams, GridData } from 'api';
import { axiosBaseQuery } from 'api/axiosBaseQuery';
import { convertPayerTypeToDataGrid, PayerType } from './payerType';

export const payerTypeApi = createApi({
  reducerPath: 'payerTypeApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['PayerTypeItems'],
  endpoints: builder => ({
    getPayerType: builder.query<GridData, BaseRequestParams>({
      query: request => ({
        url: `databricks/payertype/${request.mid}/${request.uid}`,
        method: 'GET',
      }),
      transformResponse: (payerTypes: PayerType[], meta, request) =>
        convertPayerTypeToDataGrid(payerTypes),
      providesTags: payerTypes => [{ type: 'PayerTypeItems', id: 'LIST' }],
    }),
    createPayerType: builder.mutation<
      void,
      { request: BaseRequestParams; payerType: PayerType }
    >({
      query: ({ request, payerType }) => ({
        url: `databricks/payertype/${request.mid}/${request.uid}`,
        method: 'POST',
        data: payerType,
      }),
      invalidatesTags: [{ type: 'PayerTypeItems', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetPayerTypeQuery,
  useCreatePayerTypeMutation,
  endpoints: {
    getPayerType: { useQueryState: useGetPayerTypeQueryState },
  },
} = payerTypeApi;
