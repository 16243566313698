export enum AlertSeverity {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}

export type AlertContent = {
  severity: AlertSeverity;
  message: string;
};
