import { Box, Typography } from '@mui/material';
import {
  TabPanelMenuItem,
  TabPanelMenuItemContent,
  TabPanelMenuItemIcon,
} from './TabPanel.styled';
import { grey } from '@mui/material/colors';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const DefaultTabPanelMenuItem: React.FC<{ title: string }> = ({
  title,
}) => {
  return (
    <TabPanelMenuItem
      sx={{ background: '#ffff', '&:hover': { cursor: 'default' } }}
    >
      <TabPanelMenuItemContent>
        <TabPanelMenuItemIcon></TabPanelMenuItemIcon>
        <Box
          display="flex"
          alignContent="center"
          flexDirection="column"
          alignItems="center"
          marginTop="1em"
        >
          <Typography
            sx={{
              color: grey[700],
              fontSize: '15px',
              fontWeight: 700,
              textAlign: 'center',
              margin: 'auto',
            }}
          >
            {title}
          </Typography>
          <VisibilityOffIcon
            sx={{ height: '4em', width: '4em', color: grey[700] }}
          />
        </Box>
      </TabPanelMenuItemContent>
    </TabPanelMenuItem>
  );
};
