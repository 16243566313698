import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { AppUser, setMockUser, useAppDispatch, useGetAllUsersQuery } from 'api';
import { useState, useMemo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { PageContainer } from 'app';

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export const ImpersonatePage = () => {
  // Hooks
  const { data: users } = useGetAllUsersQuery();
  const dispatch = useAppDispatch();

  const [selectedUser, setSelectedUser] = useState<AppUser | null | undefined>(
    users ? users[0] : null
  );
  const [searchText, setSearchText] = useState('');
  const displayedUsers = useMemo(
    () => users?.filter(user => containsText(user.displayName, searchText)),
    [searchText, users]
  );

  function handleChange(e: React.ChangeEvent<{ value: number }>) {
    const user = users?.find(user => user.id === e.target.value);
    setSelectedUser(user);
  }

  function handleImpersonate() {
    if (selectedUser) dispatch(setMockUser(selectedUser));
  }

  return (
    <PageContainer>
      <Typography variant="subtitle1" p={1}>
        Impersonate User
      </Typography>
      <Box
        width="600px"
        padding="20px"
        display="flex"
        alignItems="center"
        gap="10px"
      >
        <FormControl fullWidth>
          <InputLabel id="search-select-label">Users</InputLabel>
          <Select
            MenuProps={{ autoFocus: false }}
            labelId="search-select-label"
            id="search-select"
            value={selectedUser?.id}
            label="Users"
            onChange={e => handleChange(e as any)}
            onClose={() => setSearchText('')}
            renderValue={() => selectedUser?.displayName}
          >
            <ListSubheader>
              <TextField
                size="small"
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={e => setSearchText(e.target.value)}
                onKeyDown={e => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedUsers?.map((user, i) => (
              <MenuItem key={i} value={user.id}>
                {user.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          <Button
            variant="contained"
            sx={{ justifySelf: 'flex-end' }}
            onClick={handleImpersonate}
          >
            Impersonate
          </Button>
        </Box>
      </Box>
    </PageContainer>
  );
};
