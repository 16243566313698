import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface PatientTableRowProps extends BoxProps {
  columnCount?: number;
}

export const PatientTableRow = styled(Box, {
  shouldForwardProp: prop => prop !== 'noBorder',
})<PatientTableRowProps>(({ theme, columnCount }) => ({
  '&:hover': {
    backgroundColor: '#DDDDDD',
    cursor: 'pointer',
    boxShadow: '0px 0px 5px 3px lightblue',
  },
  transition: ['background-color 0.2s ease', 'box-shadow 0.1s ease'],
  borderRadius: '10px',
  background: '#EDEDED',
  display: 'grid',
  gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
  height: '50px',
  margin: '0.5em 0 0.5em 0',
}));

export interface PatientTableCellProps extends BoxProps {
  noBorder?: boolean;
}

export const PatientTableCell = styled(Box, {
  shouldForwardProp: prop => prop !== 'noBorder',
})<PatientTableCellProps>(({ theme, noBorder }) => ({
  margin: 'auto',
  position: 'relative',
  width: '-webkit-fill-available',
  overflow: 'hidden',
  paddingRight: '10px',
  paddingLeft: '10px',
  '&::before': !noBorder && {
    content: "''",
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    width: '0.1em',
    height: '0.9em',
    background: '#0EAA7C',
  },
}));

export interface PatientTableCellTextProps extends TypographyProps {}

export const PatientTableCellText = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  minHeight: '24px',
}));
