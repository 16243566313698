import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface CollapsedListItemButtonStyledProps extends ListItemButtonProps {
  popoverChild?: boolean;
  selected?: boolean;
}

export const CollapsedListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'popoverChild' && prop !== 'selected',
})<CollapsedListItemButtonStyledProps>(({ theme, popoverChild, selected }) => ({
  '&:hover': {
    backgroundColor: selected ? 'rgba(0, 0, 0, 0.14)' : 'rgba(0, 0, 0, 0.04)',
  },
  display: popoverChild ? 'block' : 'flex',
  minHeight: popoverChild ? 34 : 48,
  padding: '0',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  alignContent: 'center',
  alignItems: 'center',
  backgroundColor: selected ? 'rgba(0, 0, 0, 0.14) !important' : 'inherit',
}));

export const CollapsedMenuItemListItemStyled = styled(ListItemText)(
  ({ theme }) => ({
    fontSize: 13,
    color: '#fff',
    fontWeight: 400,
    padding: '0.2rem 1.5rem 0.2rem 1.85rem',
    fontFamily: 'arial',
    textDecorationThickness: 'auto',
    textSizeAdjust: '100%',
    lineHeight: '19.5px',
    whiteSpace: 'normal',
  })
);

export const CollapsedListItemIconStyled = styled(ListItemIcon)(
  ({ theme }) => ({
    minWidth: 0,
    justifyContent: 'flex-start',
    color: '#e7f2f0',
    transform: 'scale(1.5)',
  })
);

export const CollapsedMenuItemHeader = styled('div')(({ theme }) => ({
  backgroundColor: '#176f5f',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  fontSize: 13,
  color: '#dde5ed',
  fontWeight: 600,
  fontFamily: 'arial',
  // // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));
