import { ColumnConfiguration, GridData } from '../table';

export type FlexDataResponse = {
  columns: ColumnConfiguration[];
  rows: FlexData[];
};

export type FlexData = {
  id: number;
  practice: string;
  data: Record<string, string | number>;
  isNew: boolean;
};

// Transform the api returned flex data to a format that can be consumed by UI table/grid
export const convertFlexToGridData = (
  mid: string,
  flexDataResponse: FlexDataResponse
): GridData => {
  const rows = flexDataResponse.rows.map(row => {
    return {
      ...row.data,
      id: row.id,
      practice: row.practice,
    };
  });

  flexDataResponse.columns.push({
    name: 'actions',
    type: 'actions',
    displayName: 'Actions',
    width: 120,
    cellClassName: 'actions',
    required: true,
    editable: false,
    defaultValue: '',
  });

  return {
    mid,
    columns: flexDataResponse.columns,
    rows,
  };
};
