import { useOktaAuth } from '@okta/okta-react';
import { Outlet, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GlobalPageContainer, PageSkeleton, Sidebar, Topbar } from '.';
import { Box, Modal } from '@mui/material';
import { ImpersonationBanner, ReauthenticateBanner } from './common/banner';
import {
  useAppSelector,
  useGetFlexDataQueryState,
  useGetHelpPagesQueryState,
  useGetMenuItemsQueryState,
  useGetParentMenuItemsQueryState,
  useGetPowerBIQueryState,
  useGetPowerBIReportsQueryState,
  useGetRolesQueryState,
  useGetUserQueryState,
} from 'api';
import {
  BusyIndicatorBox,
  BusyIndicatorCircularProgress,
} from './common/busyIndicator/BusyIndicator.styled';

export const ProtectedRoute = () => {
  const { mid } = useParams<{ mid: string }>();
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(true);
  const { mockUser, isImpersonating } = useAppSelector(state => state.appUser);
  const uid = mockUser?.id?.toString() ?? '';

  const { isLoading: isUserLoading, isFetching: isUserFetching } =
    useGetUserQueryState();
  const { isLoading: isMenuItemsLoading, isFetching: isMenuItemsFetching } =
    useGetMenuItemsQueryState(mockUser?.id, {
      skip: !mockUser,
    });
  const { isLoading: isFlexDataLoading, isFetching: isFlexDataFetching } =
    useGetFlexDataQueryState({ mid: mid ?? '', uid }, { skip: !mid || !uid });
  const { isLoading: isPowerBILoading, isFetching: isPowerBIFetching } =
    useGetPowerBIQueryState({ mid: mid ?? '', uid }, { skip: !mid || !uid });
  const { loading: isPowerBIReportLoading } = useAppSelector(
    state => state.powerBi
  );

  const { isLoading: isHelpPagesLoading, isFetching: isHelpPagesFetching } =
    useGetHelpPagesQueryState();
  const {
    isLoading: isPowerBiReportsLoading,
    isFetching: isPowerBiReportsFetching,
  } = useGetPowerBIReportsQueryState();
  const {
    isLoading: isParentMenuItemsLoading,
    isFetching: isParentMenuItemsFetching,
  } = useGetParentMenuItemsQueryState(1);
  const { isLoading: isRolesLoading, isFetching: isRolesFetching } =
    useGetRolesQueryState();

  const showBusy =
    isUserLoading ||
    isUserFetching ||
    isMenuItemsLoading ||
    isMenuItemsFetching ||
    isFlexDataLoading ||
    isFlexDataFetching ||
    isPowerBILoading ||
    isPowerBIFetching ||
    isPowerBIReportLoading ||
    isHelpPagesLoading ||
    isHelpPagesFetching ||
    isPowerBiReportsLoading ||
    isPowerBiReportsFetching ||
    isParentMenuItemsLoading ||
    isParentMenuItemsFetching ||
    isRolesLoading ||
    isRolesFetching;

  useEffect(() => {
    const checkAuthentication = async () => {
      console.log(authState);
      if (!authState?.isAuthenticated) {
        await oktaAuth.signInWithRedirect();
      } else {
        setLoading(false);
      }
    };

    if (!authState) {
      setLoading(true);
    } else {
      checkAuthentication();
    }
  }, [authState, oktaAuth]);

  if (loading || !authState) {
    return <PageSkeleton />;
  }

  return (
    <>
      <Modal open={showBusy}>
        <BusyIndicatorBox>
          <BusyIndicatorCircularProgress />
        </BusyIndicatorBox>
      </Modal>
      <Box display="flex" flexDirection="row" height="100%">
        <Sidebar />
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          flex=" 1 1 auto"
          overflow="auto"
        >
          <Topbar />
          <GlobalPageContainer>
            {isImpersonating && (
              <ImpersonationBanner impersonateUser={mockUser?.displayName} />
            )}
            <ReauthenticateBanner />
            <Outlet />
          </GlobalPageContainer>
        </Box>
      </Box>
    </>
  );
};
