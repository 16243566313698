import { Box, Card, CardContent, Link, Typography } from '@mui/material';
import {
  resetMenuItemState,
  useAppDispatch,
  useGetRecentUpdatesContentQuery,
  useGetUserQuery,
} from 'api';
import { useEffect } from 'react';
import Logo from 'assets/one-oncology-logo-main.svg';
import { MenuItemNavigationTabContainer } from './menuItemNavigationTabs/MenuItemNavigationTabContainer';

export const HomePage: React.FC = () => {
  const { data: user } = useGetUserQuery();
  const { data: recentUpdates } = useGetRecentUpdatesContentQuery();
  const dispatch = useAppDispatch();

  const userRequesetUrl =
    'https://forms.monday.com/forms/989f3d1af16e7a86f37abfb53c997c45?r=use1';

  useEffect(() => {
    dispatch(resetMenuItemState());
  }, [dispatch]);

  return (
    <Box flex="1 1 auto">
      <Typography display="flex" justifyContent="flex-end">
        Click&nbsp;
        <Link href={userRequesetUrl} target="_blank">
          here
        </Link>
        &nbsp;to request OneCenter access for another user.
      </Typography>

      <Box
        display="grid"
        gridTemplateColumns="1.5fr 2fr"
        gridTemplateRows="5em 1fr"
        padding="1em"
        justifyItems="center"
        columnGap="1em"
      >
        <Box>
          <Card
            sx={{
              width: '28em',
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                padding: '0.5em 1em 0.5em 1em !important',
              }}
            >
              <Box
                component="img"
                sx={{ color: '#0EAA7C', height: '3em' }}
                src={Logo}
              />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Typography sx={{ fontSize: 20 }} color="text.secondary">
                  {user?.displayName
                    ? `Welcome to OneCenter, ${user?.displayName}!`
                    : 'Welcome to OneCenter!'}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box></Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="1em"
          width="27em"
          paddingLeft="1em"
        >
          <MenuItemNavigationTabContainer />
        </Box>
        <Box width="100%">
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <Typography
                alignSelf="flex-start"
                fontWeight={700}
                fontSize="x-large"
              >
                Recent Updates:
              </Typography>
              {recentUpdates}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};
