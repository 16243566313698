import { Box, Button, Typography } from '@mui/material';
import {
  setCurrentMenuItem,
  useAppDispatch,
  useAppSelector,
  useGetMenuItemsQuery,
  useGetWorklistV2Query,
} from 'api';
import { WorklistTable } from '.';
import { PageContainer } from 'app';
import { useNavigate } from 'react-router-dom';

export const WorklistPage = () => {
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { mockUser } = useAppSelector(state => state.appUser);
  const uid = mockUser?.id.toString() ?? '';
  const match = currentMenuItem?.url.match(/worklist\/([0-9]+)\/([0-9]+)/);
  const wlid = match ? match[2] : '';
  const { data } = useGetWorklistV2Query(
    { wlid, uid },
    { skip: !wlid || !uid }
  );
  const { data: menuItems } = useGetMenuItemsQuery(mockUser?.id, {
    skip: !mockUser,
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handlePowerBiClick = () => {
    if (data?.reportUrl) {
      const pathname = data?.reportUrl.split('?')[0];
      const pathParts = pathname.split('/');
      const menuItemId = pathParts[2].toLocaleLowerCase();

      const menuItem = menuItems?.originalMenuItems.find(
        x => x.id.toString() === menuItemId
      );

      navigate(data?.reportUrl);
      dispatch(setCurrentMenuItem(menuItem));
    }
  };

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="subtitle1">{currentMenuItem?.text}</Typography>
        {data?.reportUrl && (
          <Button
            variant="contained"
            size="small"
            sx={{ height: '3em' }}
            onClick={handlePowerBiClick}
          >
            Worklist Report
          </Button>
        )}
      </Box>
      <WorklistTable />
    </PageContainer>
  );
};
