import { createApi } from '@reduxjs/toolkit/query/react';
import {
  PowerBiEmbedProperties,
  PowerBiSlicerState,
  PowerBiSlicerStateRequest,
} from '.';
import { axiosBaseQuery, BaseRequestParams } from '../..';

export const powerBiApi = createApi({
  reducerPath: 'powerBiApi',
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    getPowerBi: builder.query<PowerBiEmbedProperties, BaseRequestParams>({
      query: request => ({
        url: `powerbi/${request.mid}/${request.uid}`,
        method: 'GET',
      }),
    }),
    getPowerBiReports: builder.query<PowerBiEmbedProperties[], void>({
      query: () => ({
        url: 'powerbi/reports',
        method: 'GET',
      }),
    }),
    getUnassignedPowerBiReports: builder.query<PowerBiEmbedProperties[], void>({
      query: () => ({
        url: 'powerbi/reports/unassigned',
        method: 'GET',
      }),
    }),
    upsertPowerBiSlicerState: builder.mutation<
      PowerBiSlicerState,
      PowerBiSlicerStateRequest
    >({
      query: request => ({
        url: `powerbi/${request.menuItemId}/${request.userId}/slicerState`,
        method: 'POST',
        data: { ...request },
      }),
    }),
  }),
});

export const {
  useGetPowerBiQuery,
  useGetPowerBiReportsQuery,
  useGetUnassignedPowerBiReportsQuery,
  useUpsertPowerBiSlicerStateMutation,
  endpoints: {
    getPowerBi: { useQueryState: useGetPowerBIQueryState },
    getPowerBiReports: { useQueryState: useGetPowerBIReportsQueryState },
  },
} = powerBiApi;
