import Divider from '@mui/material/Divider';
import {
  setSelectedMenuItem,
  useAppDispatch,
  useAppSelector,
} from '../../../api';
import { MenuContainerStyled, SidebarHeader, SidebarStyled } from '.';
import { CollapsedMenu, Menu } from '../../.';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import LogoWithName from 'assets/logoWithName.png';
import Logo from 'assets/logo.png';

export const Sidebar = () => {
  // Hooks
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { isDrawerOpen, drawerWidth } = useAppSelector(state => state.layout);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentMenuItem) {
      dispatch(setSelectedMenuItem(currentMenuItem));
    }
  }, [currentMenuItem, dispatch]);

  function handleHeaderClick() {
    navigate('/home');
  }

  return (
    <SidebarStyled
      variant="permanent"
      drawerWidth={drawerWidth}
      PaperProps={{ sx: { overflow: 'hidden' } }}
    >
      {/*minHeight meeds to be added here because not being applied in styled component*/}
      <SidebarHeader
        sx={{ minHeight: '70px !important' }}
        onClick={handleHeaderClick}
      >
        <img alt="logo" src={isDrawerOpen ? LogoWithName : Logo} />
      </SidebarHeader>
      <Divider />
      <MenuContainerStyled isDrawerOpen={isDrawerOpen}>
        {isDrawerOpen ? <Menu /> : <CollapsedMenu />}
      </MenuContainerStyled>
    </SidebarStyled>
  );
};
