import { useEffect } from 'react';
import { GridRowModel } from '@mui/x-data-grid-pro';
import {
  setAlert,
  useAppDispatch,
  useAppSelector,
  BaseRequestParams,
  TableData,
  initializeTableData,
  resetTableData,
  generateColumns,
} from 'api';
import { AlertSeverity } from 'api/models/alert';
import {
  PayerGroup,
  useCreatePayerGroupMutation,
  useGetPayerGroupQuery,
} from 'api/models/databricks/payerGroup';
import { ActionType, DataGridTable } from 'app/common';

export const PayerGroupTable = () => {
  // Hooks
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { rows } = useAppSelector(state => state.dataGridTable);
  const { mockUser } = useAppSelector(state => state.appUser);
  const mid = currentMenuItem?.id.toString() ?? '';
  const uid = mockUser?.id.toString() ?? '';
  const { data, isLoading, isFetching } = useGetPayerGroupQuery(
    { mid: mid, uid },
    { skip: mid === '-1' || uid === '-1' }
  );

  const [createPayerGroup, { isLoading: isCreating }] =
    useCreatePayerGroupMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetTableData());

    const tableData: TableData = {
      rows: data?.rows,
      columns: generateColumns(data?.columns),
    };

    dispatch(initializeTableData(tableData));
  }, [dispatch, data]);

  const processRowUpdate = async (rowUpdate: GridRowModel) => {
    let updatedRow = { ...rowUpdate, isNew: false };

    if (rowExists(rowUpdate as PayerGroup)) {
      dispatch(
        setAlert({
          message: 'Row already exists.',
          severity: AlertSeverity.Error,
        })
      );
      return;
    }

    if (rowUpdate.isNew) {
      const reqBody = {
        request: { mid, uid } as BaseRequestParams,
        payerGroup: {
          ...(rowUpdate as PayerGroup),
          payerGroup: rowUpdate.payerGroup.trim(),
        },
      };
      try {
        await createPayerGroup(reqBody)
          .unwrap()
          .then(() =>
            dispatch(
              setAlert({
                message: 'Row added.',
                severity: AlertSeverity.Success,
              })
            )
          );
      } catch (err: any) {
        dispatch(
          setAlert({
            message: err.response.data,
            severity: AlertSeverity.Error,
          })
        );
        return;
      }
    }

    return updatedRow;
  };

  function rowExists(newRow: PayerGroup) {
    let found = false;
    rows.forEach(row => {
      if (
        row.payerGroup &&
        row.payerGroup.toUpperCase() === newRow.payerGroup.toUpperCase()
      ) {
        found = true;
        return;
      }
    });
    return found;
  }

  return (
    <DataGridTable
      cellActions={[ActionType.SaveOnly]}
      processRowUpdate={processRowUpdate}
      isBusy={isFetching || isLoading || isCreating}
      toolbarProps={{ enableAddButton: true, enableFilter: true }}
    />
  );
};
