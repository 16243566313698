import { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { PowerBIReport } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { PowerBIReportHelpModal } from './PowerBIReportHelpModal';
import { useGetHelpContentQuery } from 'api/models/help';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

import {
  AlertSeverity,
  MenuItem,
  PowerBiSlicerState,
  PowerBiSlicerStateRequest,
  setAlert,
  setCurrentMenuItem,
  setPbiSlicerFilterStates,
  useAddUserFavoriteMenuItemMutation,
  useAppDispatch,
  useAppSelector,
  useDeleteUserFavoriteMenuItemMutation,
  useGetMenuItemsQuery,
  useGetPowerBiQuery,
  useUpsertPowerBiSlicerStateMutation,
} from 'api';
import { PageContainer } from 'app';
import { PowerBiPageDropdown } from './PowerBiPageDropdown';

export const PowerBIReportPage = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { pbiSlicerFilterStates } = useAppSelector(state => state.powerBi);
  const { mockUser } = useAppSelector(state => state.appUser);
  const mid = currentMenuItem?.id.toString() ?? '';
  const uid = mockUser?.id.toString() ?? '';
  const { data: helpContent } = useGetHelpContentQuery(
    { mid, uid },
    { skip: !mid || !uid }
  );
  const { data: menuItems, refetch } = useGetMenuItemsQuery(mockUser?.id, {
    skip: !mockUser,
  });
  const { data: powerBiData } = useGetPowerBiQuery(
    { mid, uid },
    { skip: !mid || !uid }
  );

  const [addFavorite] = useAddUserFavoriteMenuItemMutation();
  const [deleteFavorite] = useDeleteUserFavoriteMenuItemMutation();
  const [updateAction] = useUpsertPowerBiSlicerStateMutation();

  // States
  const [openModal, setOpenModal] = useState<boolean>(false);

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleFavorite() {
    if (currentMenuItem) {
      if (!checkIsFavorited(currentMenuItem))
        addFavorite(currentMenuItem.id)
          .unwrap()
          .then(resp => {
            const updatedMenuItem = { ...currentMenuItem, isFavorited: true };
            dispatch(setCurrentMenuItem(updatedMenuItem));
            refetch();

            dispatch(
              setAlert({
                message: 'Favorite added.',
                severity: AlertSeverity.Success,
              })
            );
          })
          .catch(() => {
            dispatch(
              setAlert({
                message: 'Adding favorite failed.',
                severity: AlertSeverity.Error,
              })
            );
          });
      else if (checkIsFavorited(currentMenuItem))
        deleteFavorite(currentMenuItem.id)
          .unwrap()
          .then(resp => {
            const updatedMenuItem = { ...currentMenuItem, isFavorited: false };
            dispatch(setCurrentMenuItem(updatedMenuItem));
            refetch();

            dispatch(
              setAlert({
                message: 'Favorite removed.',
                severity: AlertSeverity.Success,
              })
            );
          })
          .catch(() => {
            dispatch(
              setAlert({
                message: 'Adding favorite failed.',
                severity: AlertSeverity.Error,
              })
            );
          });
    }
  }

  function checkIsFavorited(menuItem: MenuItem | undefined) {
    if (menuItems && menuItems?.favorites?.length <= 0) return false;
    if (menuItem) {
      return menuItems?.favorites.some((mi: MenuItem) => mi.id === menuItem.id);
    }
  }

  const handleSaveSlicers = async () => {
    if (powerBiData?.reportId && mockUser?.id && currentMenuItem?.id) {
      const powerBiSlicerStateRequest: PowerBiSlicerStateRequest = {
        powerBiReportId: powerBiData?.reportId,
        menuItemId: currentMenuItem?.id,
        userId: mockUser?.id,
        powerBiSlicerState: JSON.stringify(pbiSlicerFilterStates),
      };

      try {
        await updateAction(powerBiSlicerStateRequest)
          .unwrap()
          .then((response: PowerBiSlicerState) => {
            const powerBiSlicerStates = JSON.parse(response.slicerState);

            dispatch(setPbiSlicerFilterStates(powerBiSlicerStates));
          });
        dispatch(
          setAlert({
            message: 'Filters saved.',
            severity: AlertSeverity.Success,
          })
        );
      } catch (e: any) {
        dispatch(
          setAlert({
            message: `Something went wrong: ${e.message}`,
            severity: AlertSeverity.Error,
          })
        );
      }
    }
  };

  return (
    <PageContainer powerBi>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" p={1}>
          {currentMenuItem?.text}
        </Typography>
        <Box display="flex" gap="1em">
          <Button variant="outlined" onClick={handleSaveSlicers}>
            Save Filters
          </Button>
          {checkIsFavorited(currentMenuItem) ? (
            <Tooltip
              title="Favorited"
              PopperProps={{ style: { marginTop: '0 !important' } }}
            >
              <IconButton onClick={handleFavorite}>
                <StarIcon sx={{ color: '#1b806d' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title="Favorite"
              PopperProps={{ style: { marginTop: '0 !important' } }}
            >
              <IconButton onClick={handleFavorite}>
                <StarBorderIcon sx={{ color: '#1b806d' }} />
              </IconButton>
            </Tooltip>
          )}
          {helpContent && (
            <Tooltip
              title="Info"
              PopperProps={{ style: { marginTop: '0 !important' } }}
            >
              <Link
                component="button"
                mr="10px !important"
                onClick={handleOpenModal}
              >
                <FontAwesomeIcon icon={faCircleQuestion} size="2x" />
              </Link>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box sx={{ height: 'calc(100% - 105px)' }}>
        <PowerBIReport />
      </Box>
      <PowerBiPageDropdown />

      <PowerBIReportHelpModal
        open={openModal}
        handleCloseModal={handleCloseModal}
      />
    </PageContainer>
  );
};
