import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ErrorPageContainer = styled(Box)(() => ({
  m: '20px 100px 0 100px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '50px',
  height: '100%',
}));

export const MessageContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
