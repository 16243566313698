import { ColumnConfiguration, GridData } from 'api/models/table';
import { v4 as uuidv4 } from 'uuid';

export type PayerType = {
  id: string;
  isNew: boolean;
  ooPayerTypeKey: string;
  payerType: string;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
};

//TODO: Return columns from API endpoint
const payerTypeColumns: ColumnConfiguration[] = [
  {
    name: 'payerType',
    displayName: 'Payer Type Name',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: true,
    flex: 1,
  },
  {
    name: 'updatedBy',
    displayName: 'Last Updated By',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    flex: 1,
  },
  {
    name: 'updatedDate',
    displayName: 'Last Updated Date',
    type: 'date',
    required: true,
    defaultValue: new Date().toUTCString(),
    editable: false,
    flex: 1,
  },
  {
    name: 'actions',
    type: 'actions',
    displayName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    required: true,
    editable: false,
    defaultValue: '',
  },
];

// Transform the api returned flex data to a format that can be consumed by UI table/grid
export const convertPayerTypeToDataGrid = (
  payerTypes: PayerType[]
): GridData => {
  const rows = payerTypes.map(payerType => ({
    ...payerType,
    id: uuidv4(),
    isNew: false,
  }));

  return {
    columns: payerTypeColumns,
    rows: rows,
  };
};
