import { Box, Skeleton, Divider } from '@mui/material';
import { TopbarStyled, ToolbarStyled } from 'app/layout/topbar/Topbar.styled';

export const TopbarSkeleton = () => {
  return (
    <Box>
      <TopbarStyled isDrawerOpen={true} position="fixed">
        <ToolbarStyled>
          <Box width="348px">
            <Skeleton height={50} sx={{ m: 1 }} />
          </Box>

          <Box width="350px">
            <Skeleton height={50} sx={{ m: 1 }} />
          </Box>

          <Box display="flex" alignItems="center">
            <Skeleton height={50} width={100} sx={{ m: 1 }} />
          </Box>
        </ToolbarStyled>
        <Divider />
      </TopbarStyled>
    </Box>
  );
};
