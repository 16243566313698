import { BoxProps } from '@mui/material';
import {
  getMenuItemType,
  useAppSelector,
  useCreateUserAuditLogMutation,
  UserAuditLog,
  UserAuditLogAction,
} from 'api';
import { Page } from 'app';
import { ReactNode, useEffect } from 'react';

interface PageContainerProps extends BoxProps {
  children: ReactNode;
  powerBi?: boolean;
}

export const PageContainer: React.FC<PageContainerProps> = props => {
  const { mockUser } = useAppSelector(state => state.appUser);
  const uid = mockUser?.id.toString() ?? '';
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const [createAction, { isLoading: isUpdating }] =
    useCreateUserAuditLogMutation();
  const menuItemType = getMenuItemType(currentMenuItem?.type);

  useEffect(() => {
    if (currentMenuItem?.text && currentMenuItem?.id && uid && !isUpdating) {
      const auditLog: UserAuditLog = {
        action: UserAuditLogAction.View,
        target: window.location.pathname,
        metadata: `${menuItemType} page viewed: ${currentMenuItem?.text}`,
      };
      createAction({ auditLog: auditLog });
    }
    // eslint-disable-next-line
  }, [currentMenuItem]);

  return (
    <Page {...props} powerBi={props.powerBi} sx={{ ...props.sx }}>
      {props.children}
    </Page>
  );
};
