import { Box, Skeleton } from '@mui/material';

export const BasicTableSkeleton = () => {
  const renderRows = () => {
    const items = [];
    for (let i = 0; i < 4; i++) {
      items.push(
        <Skeleton
          variant="rounded"
          sx={{
            m: '0.5em 0 0.5em 0',
            minHeight: '30px',
            background: '#EDEDED',
          }}
        />
      );
    }
    return items;
  };
  return (
    <Box minWidth="800px">
      <Skeleton
        variant="rounded"
        sx={{
          m: '1em 0 0.5em 0',
          background: '#47A493',
          height: '35px',
          borderRadius: '7px 7px var(--none, 0px) 7px',
        }}
      />
      {renderRows()}
    </Box>
  );
};
