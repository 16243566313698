export const powerBiTheme = {
  themeJson: {
    name: 'Custom',
    dataColors: [
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
      '#3AA178',
      '#414D4B',
      '#8CB9E8',
      '#AFAEEE',
      '#F2DAE3',
      '#ED8255',
      '#9BD0C4',
      '#165444',
      '#115193',
      '#6262A2',
      '#F279AA',
      '#AB4216',
      '#EDAD55',
      '#AB6C16',
      '#E2EB1E',
    ],
    minimum: '#E5DA58',
    center: '#782A66',
    maximum: '#FB79DC',
  },
};
