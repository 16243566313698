import { ColumnConfiguration, GridData, ValueOption } from '../table';

export type Worklist = {
  id: number;
  status?: ValueOption;
  lastNote: string;
  lastNoteUpdatedDate: Date;
  practice: string;
  data: Record<string, string | number>;
  isNew: boolean;
  isTerminalState: boolean;
};

export type WorklistV2 = {
  worklistID: number;
  worklistDescr: string;
  worklistHeader?: string;
  reportUrl?: string;
};

export type WorklistResponse = {
  columns: ColumnConfiguration[];
  rows: Worklist[];
};

export const convertWorklistToGridData = (
  worklistResponse: WorklistResponse
): GridData => {
  const rows = worklistResponse.rows.map(row => ({
    id: row.id,
    ...row.data,
    status: row.status,
    lastNote: row.lastNote,
    lastNoteUpdatedDate: row.lastNoteUpdatedDate,
    practice: row.practice,
    isTerminalState: row.isTerminalState,
  }));

  worklistResponse.columns.unshift({
    name: 'actions',
    type: 'actions',
    displayName: 'Actions',
    width: 120,
    cellClassName: 'actions',
    required: true,
    editable: false,
    defaultValue: '',
  });

  return {
    columns: worklistResponse.columns,
    rows,
  };
};
