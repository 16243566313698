import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseRequestParams, GridData } from 'api';
import { axiosBaseQuery } from 'api/axiosBaseQuery';
import { convertPayerGroupToDataGrid, PayerGroup } from './payerGroup';

export const payerGroupApi = createApi({
  reducerPath: 'payerGroupApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['PayerGroupItems'],
  endpoints: builder => ({
    getPayerGroup: builder.query<GridData, BaseRequestParams>({
      query: request => ({
        url: `databricks/payergroup/${request.mid}/${request.uid}`,
        method: 'GET',
      }),
      transformResponse: (payerGroups: PayerGroup[], meta, request) =>
        convertPayerGroupToDataGrid(payerGroups),
      providesTags: payerGroups => [{ type: 'PayerGroupItems', id: 'LIST' }],
    }),
    createPayerGroup: builder.mutation<
      void,
      { request: BaseRequestParams; payerGroup: PayerGroup }
    >({
      query: ({ request, payerGroup }) => ({
        url: `databricks/payergroup/${request.mid}/${request.uid}`,
        method: 'POST',
        data: payerGroup,
      }),
      invalidatesTags: [{ type: 'PayerGroupItems', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetPayerGroupQuery,
  useCreatePayerGroupMutation,
  endpoints: {
    getPayerGroup: { useQueryState: useGetPayerGroupQueryState },
  },
} = payerGroupApi;
