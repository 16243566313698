import { Box, Divider, List, ListItem, Skeleton } from '@mui/material';
import { useAppSelector } from 'api';
import { SidebarStyled, SidebarHeader, MenuContainerStyled } from 'app/layout';
import LogoWithName from 'assets/logoWithName.png';

export const SidebarSkeleton = () => {
  const { drawerWidth } = useAppSelector(state => state.layout);

  return (
    <SidebarStyled
      variant="permanent"
      drawerWidth={drawerWidth}
      PaperProps={{ sx: { overflow: 'hidden' } }}
    >
      <SidebarHeader sx={{ minHeight: '70px !important' }}>
        <img alt="logo" src={LogoWithName} />
      </SidebarHeader>
      <Divider />
      <MenuContainerStyled isDrawerOpen={true}>
        <List>
          <Box>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Skeleton variant="rectangular" height={50} sx={{ m: 1 }} />
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Skeleton variant="rectangular" height={50} sx={{ m: 1 }} />
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Skeleton variant="rectangular" height={50} sx={{ m: 1 }} />
            </ListItem>
          </Box>
        </List>
      </MenuContainerStyled>
    </SidebarStyled>
  );
};
