import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormHelperText,
  TextField,
  Box,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isEmptyString } from 'api/helper';
import dayjs from 'dayjs';
import { PatientMappingFormContainer } from './PatientMappingWizard.styled';
import { SocialSecurityTextField } from './SocialSecurityTextField';
import {
  useAppSelector,
  PatientMappingFirstStepState,
  setCurrentStep,
  useAppDispatch,
  useGetPatientMappingOptionsQuery,
  UnmappedPatientSearchRequest,
  UnmappedPatientSearchParams,
  setFirstStepState,
  useGetUnmappedPatientsMutation,
  setLoadingState,
} from 'api';
import {
  validateSearchParam,
  validateUnmappedPatientSearch,
} from 'api/validators/patientMappingalidator';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from 'react';

export const PatientMappingFirstStepForm = () => {
  const dispatch = useAppDispatch();
  const { currentStep } = useAppSelector(state => state.patientMapping);
  const firstStepState = currentStep?.data as PatientMappingFirstStepState;
  const { data: patientMappingOptions } = useGetPatientMappingOptionsQuery();
  const [getUnmappedPatients, { isLoading }] = useGetUnmappedPatientsMutation();

  useEffect(() => {
    dispatch(setLoadingState(isLoading));
  }, [isLoading, dispatch]);

  const handleChange = (value: string, keyName: string) => {
    let currentStepCpy = {
      ...firstStepState,
      searchParams: {
        ...firstStepState?.searchParams,
        [keyName]: value,
      },
    };

    const error = validateSearchParam(keyName, value);
    if (error) {
      currentStepCpy.searchParams.error = {
        ...currentStepCpy.searchParams.error,
        [keyName]: error,
      };
      currentStepCpy.searchParams.hasError = true;
    } else {
      currentStepCpy.searchParams.error = {
        ...currentStepCpy.searchParams.error,
        [keyName]: '',
      };
      currentStepCpy.searchParams.hasError = false;
    }

    dispatch(
      setCurrentStep({
        step: 1,
        data: currentStepCpy,
      })
    );
  };

  const handleDateChange = (value: string, keyName: string) => {
    const newDate = value ? new Date(value) : '';
    dispatch(
      setCurrentStep({
        step: 1,
        data: {
          ...firstStepState,
          searchParams: {
            ...firstStepState?.searchParams,
            [keyName]: newDate,
          },
        },
      })
    );
  };

  const handleSearch = async () => {
    const searchParams: UnmappedPatientSearchParams =
      firstStepState?.searchParams;

    const validation = validateUnmappedPatientSearch(searchParams);

    if (validation.hasError) {
      dispatch(
        setCurrentStep({
          step: 1,
          data: {
            ...firstStepState,
            searchParams: validation,
          },
        })
      );
    } else {
      const request: UnmappedPatientSearchRequest = {
        ooClientKey: searchParams.client,
        anchorOoClientGroupKey: searchParams.client,
        ooDataPartnerKey: searchParams.dataPartner,
        srcPatientKey: searchParams.sourcePatientKey,
        firstName: searchParams.firstName,
        lastName: searchParams.lastName,
        ssn: searchParams.ssn
          ? searchParams.ssn.replace(/[()-]|\s+/g, '')
          : searchParams.ssn,
        dob: searchParams.dob,
      };

      const response = await getUnmappedPatients(request).unwrap();
      dispatch(
        setCurrentStep({
          step: 1,
          data: {
            ...firstStepState,
            unmappedPatients: response,
          },
        })
      );
      dispatch(
        setFirstStepState({
          unmappedPatients: response,
          searchParams: searchParams,
        })
      );
    }
  };

  return (
    <PatientMappingFormContainer>
      <FormControl
        variant="filled"
        sx={{ m: 1, minWidth: 120 }}
        size="small"
        error={isEmptyString(firstStepState?.searchParams.error.client)}
        required
      >
        <InputLabel id="client-select-label">Client</InputLabel>
        <Select
          labelId="client-select-label"
          id="client-select"
          value={firstStepState?.searchParams.client}
          onChange={(e: SelectChangeEvent) =>
            handleChange(e.target.value, 'client')
          }
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {patientMappingOptions?.clients.map(client => (
            <MenuItem value={client.ooClientKey}>{client.ooClientKey}</MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {firstStepState?.searchParams.error.client}
        </FormHelperText>
      </FormControl>
      <FormControl
        variant="filled"
        sx={{ m: 1, minWidth: 120 }}
        size="small"
        error={isEmptyString(firstStepState?.searchParams.error.dataPartner)}
        required
      >
        <InputLabel id="data-partner-select-label">Data Partner</InputLabel>
        <Select
          labelId="data-partner-select-label"
          id="data-partner-select"
          value={firstStepState?.searchParams.dataPartner}
          onChange={(e: SelectChangeEvent) =>
            handleChange(e.target.value, 'dataPartner')
          }
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {patientMappingOptions?.dataPartners.map(dp => (
            <MenuItem value={dp.ooDataPartnerKey}>
              {dp.ooDataPartnerKey}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {firstStepState?.searchParams.error.dataPartner}
        </FormHelperText>
      </FormControl>
      <FormControl
        variant="filled"
        sx={{ m: 1, minWidth: 120 }}
        size="small"
        required
      >
        <TextField
          id="source-patient-text"
          label="Source Patient Key"
          defaultValue=""
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.value, 'sourcePatientKey')
          }
          value={firstStepState?.searchParams.sourcePatientKey}
          variant="filled"
          size="small"
        />
      </FormControl>
      <FormControl
        variant="filled"
        sx={{ m: 1, minWidth: 120 }}
        size="small"
        required
      >
        <TextField
          id="first-name-text"
          label="First Name"
          defaultValue=""
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.value, 'firstName')
          }
          value={firstStepState?.searchParams.firstName}
          variant="filled"
          size="small"
        />
      </FormControl>
      <FormControl
        variant="filled"
        sx={{ m: 1, minWidth: 120 }}
        size="small"
        required
      >
        <TextField
          id="last-name-text"
          label="Last Name"
          defaultValue=""
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.value, 'lastName')
          }
          value={firstStepState?.searchParams.lastName}
          variant="filled"
          size="small"
        />
      </FormControl>
      <SocialSecurityTextField />
      <FormControl
        variant="filled"
        sx={{ m: 1, minWidth: 120 }}
        size="small"
        required
      >
        <DatePicker
          label="Date of Birth"
          slotProps={{
            field: {
              clearable: true,
              onClear: () => handleDateChange('', 'dob'),
            },
          }}
          onChange={(newValue: any) => handleDateChange(newValue, 'dob')}
          value={
            firstStepState?.searchParams.dob
              ? dayjs(firstStepState?.searchParams.dob)
              : null
          }
        />
      </FormControl>
      <Box></Box> {/* This is used to fill in the gap */}
      <Box justifySelf="flex-end">
        <LoadingButton
          variant="contained"
          size="small"
          sx={{
            height: '3em',
            padding: '0 1em 0 1em',
            display: 'flex',
          }}
          onClick={handleSearch}
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SearchIcon />}
        >
          <span style={{ paddingTop: '3px' }}>Search</span>
        </LoadingButton>
      </Box>
    </PatientMappingFormContainer>
  );
};
