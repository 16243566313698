import { ColumnConfiguration, GridData } from 'api';
import { v4 as uuidv4 } from 'uuid';

export type ChargeCodeReference = {
  chargeCode: string;
  description: string;
  createdDate: Date;
  source: string;
};

//TODO: Return columns from API endpoint
const chargeCodeReferenceColumns: ColumnConfiguration[] = [
  {
    name: 'chargeCode',
    displayName: 'Charge Code',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: true,
    width: 150,
  },
  {
    name: 'description',
    displayName: 'Description',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: true,
    flex: 1,
  },
  {
    name: 'source',
    displayName: 'Source',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    width: 150,
  },
  {
    name: 'createdDate',
    displayName: 'Date Created',
    type: 'date',
    required: true,
    defaultValue: new Date().toUTCString(),
    editable: false,
    width: 200,
  },
  {
    name: 'actions',
    type: 'actions',
    displayName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    required: true,
    editable: false,
    defaultValue: '',
  },
];

export const convertChargeCodeReferencesToDataGrid = (
  chargeCodeReferences: ChargeCodeReference[]
): GridData => {
  const rows = chargeCodeReferences.map(chargeCode => ({
    ...chargeCode,
    id: uuidv4(),
    isNew: false,
  }));

  return {
    columns: chargeCodeReferenceColumns,
    rows: rows,
  };
};
