import { Typography } from '@mui/material';
import {
  useAppDispatch,
  useGetAllRoleClaimsQuery,
  useGetRoleClaimsForRoleQuery,
  useAppSelector,
  setCurrentRoleClaims,
} from 'api';
import { useEffect } from 'react';
import { PageContainer } from 'app';
import { RoleMaintenanceTabContainer } from './tabs/RoleMaintenanceTabContainer';
import { RoleMaintenanceAutoComplete } from './RoleMaintenanceAutoComplete';

export const RoleMaintenancePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { selectedRole } = useAppSelector(state => state.roleMaintenance);
  const { data: roleClaims } = useGetRoleClaimsForRoleQuery(selectedRole?.id, {
    skip: !selectedRole,
  });
  const { data: allRoleClaims } = useGetAllRoleClaimsQuery();

  useEffect(() => {
    if (allRoleClaims) dispatch(setCurrentRoleClaims(allRoleClaims));
  }, [allRoleClaims, dispatch]);

  useEffect(() => {
    if (roleClaims && allRoleClaims && allRoleClaims.length > 0) {
      const updateRoleClaims = allRoleClaims
        .map(rc => {
          const foundRoleClaim = roleClaims.find(
            c => rc.claimValue === c.claimValue
          );

          if (foundRoleClaim)
            return {
              ...foundRoleClaim,
              description: rc.description,
              active: true,
            };
          return { ...rc, active: false };
        })
        .sort((a, b) => a.claimType.localeCompare(b.claimType));
      dispatch(setCurrentRoleClaims(updateRoleClaims));
    }
    // eslint-disable-next-line
  }, [roleClaims]);

  return (
    <PageContainer>
      <Typography variant="subtitle1" p={1}>
        Role Maintenance
      </Typography>
      <RoleMaintenanceAutoComplete />
      {selectedRole && <RoleMaintenanceTabContainer />}
    </PageContainer>
  );
};
