import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BaseRequestParams,
  ChargeCodeReference,
  GridData,
  convertChargeCodeReferencesToDataGrid,
} from 'api';
import { axiosBaseQuery } from 'api/axiosBaseQuery';

export const chargeCodeReferenceApi = createApi({
  reducerPath: 'chargeCodeReferenceApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ChargeCodeReferenceItems'],
  endpoints: builder => ({
    getChargeCodeReferences: builder.query<GridData, BaseRequestParams>({
      query: request => ({
        url: `databricks/chargeCodeReference/${request.mid}/${request.uid}`,
        method: 'GET',
      }),
      transformResponse: (
        chargeCodeReferences: ChargeCodeReference[],
        meta,
        request
      ) => convertChargeCodeReferencesToDataGrid(chargeCodeReferences),
      providesTags: ipas => [{ type: 'ChargeCodeReferenceItems', id: 'LIST' }],
    }),
    createChargeCodeReference: builder.mutation<
      void,
      { request: BaseRequestParams; chargeCodeReference: ChargeCodeReference }
    >({
      query: ({ request, chargeCodeReference }) => ({
        url: `databricks/chargeCodeReference/${request.mid}/${request.uid}`,
        method: 'POST',
        data: chargeCodeReference,
      }),
      invalidatesTags: [{ type: 'ChargeCodeReferenceItems', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetChargeCodeReferencesQuery,
  useCreateChargeCodeReferenceMutation,
  endpoints: {
    getChargeCodeReferences: {
      useQueryState: useGetChargeCodeReferencesQueryState,
    },
  },
} = chargeCodeReferenceApi;
