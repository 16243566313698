export enum ColumnType {
  Actions,
}

export enum ActionType {
  Add,
  Edit,
  Delete,
  SaveOnly,
}
