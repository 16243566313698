import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

export const FooterStyled = styled(Paper)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'white',
  boxShadow: 'none',
  color: 'black',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  margin: 'auto',
  width: 'auto',
  height: '40px',
}));
