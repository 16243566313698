import { createApi } from '@reduxjs/toolkit/query/react';
import { ReactNode } from 'react';
import { convertHelpContentToReactElement, HelpPage } from '.';
import { axiosBaseQuery, BaseRequestParams } from '../..';

export const helpApi = createApi({
  reducerPath: 'helpApi',
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    getHelpContent: builder.query<ReactNode, BaseRequestParams>({
      query: request => ({
        url: `help/menuitem/${request.mid}`,
        method: 'GET',
      }),
      transformResponse: (helpContent: string) => {
        if (!helpContent) return null;
        return convertHelpContentToReactElement(helpContent);
      },
    }),
    getHelpPages: builder.query<HelpPage[], void>({
      query: () => ({
        url: 'help/pages',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  endpoints: {
    getHelpPages: { useQueryState: useGetHelpPagesQueryState },
  },
  useGetHelpPagesQuery,
  useGetHelpContentQuery,
} = helpApi;
