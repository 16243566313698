export type AppRole = {
  id: number;
  name: string;
  normalizedName: string;
  concurrencyStamp: Date;
  groupDescription: string;
  oktaGroupId: string;
  clientFilter: string;
  clientGroupKey: string;
  roleClaims: any[]; // Not used
  userRoles: any[]; // Not used
  active: boolean;
};

export const addActiveStatusToUserRoles = (userRoles: AppRole[]): AppRole[] =>
  userRoles.map(ur => {
    const userRole: AppRole = {
      ...ur,
      active: false,
    };
    return userRole;
  });

export type Role = {
  id: number;
  name: string;
  normalizedName: string;
};

export type UpdateRoleRequest = {
  rid: number;
  roleClaims: RoleClaim[];
};

export type RoleClaim = {
  id: number;
  roleId: number;
  claimType: string;
  claimValue: string;
  description: string;
  active: boolean;
};

export const addActiveStatusToRoleClaims = (
  roleClaims: RoleClaim[]
): RoleClaim[] =>
  roleClaims.map(rc => {
    const roleClaim: RoleClaim = {
      ...rc,
      active: false,
    };
    return roleClaim;
  });
