import {
  MenuItem,
  setCurrentMenuItem,
  useAppDispatch,
  useAppSelector,
  useGetMenuItemsQuery,
} from 'api';
import { TabPanelContainer } from 'app/common';
import { useNavigate } from 'react-router';
import {
  TabPanelList,
  TabPanelMenuItem,
  TabPanelMenuItemContent,
  TabPanelMenuItemIcon,
  TabPanelMenuItemText,
  TabPanelMenuItemTextContainer,
  TabPanelMenuItemTitle,
  TabPanelTitle,
} from './TabPanel.styled';
import { DefaultTabPanelMenuItem } from './DefaultTabPanelMenuItem';

export const FavoritesTabPanel: React.FC<{ value: number; index: number }> = ({
  value,
  index,
}) => {
  const { mockUser } = useAppSelector(state => state.appUser);
  const { data: menuItems } = useGetMenuItemsQuery(mockUser?.id, {
    skip: !mockUser,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <TabPanelContainer index={index} value={value}>
      {menuItems && menuItems.favorites?.length > 0 && (
        <TabPanelTitle>Favorited Reports:</TabPanelTitle>
      )}
      <TabPanelList>
        {menuItems && menuItems.favorites.length > 0 ? (
          menuItems.favorites.map((fmi: MenuItem) => {
            const originalMenuItem = menuItems.originalMenuItems.find(
              omi =>
                omi.id === fmi.id &&
                omi.parentMenuItemText.toLowerCase() !== 'favorites'
            );
            const originalParentMenuItem = menuItems.originalMenuItems.find(
              omi => omi.id === originalMenuItem?.parentId
            );

            return (
              <TabPanelMenuItem
                key={fmi.id}
                component="a"
                onClick={() => {
                  navigate(`/powerbi/${fmi.id}`);
                  dispatch(setCurrentMenuItem(originalMenuItem));
                }}
              >
                <TabPanelMenuItemContent>
                  <TabPanelMenuItemIcon>
                    <i
                      className={originalParentMenuItem?.iconClass}
                      style={{ fontSize: '24px' }}
                    />
                  </TabPanelMenuItemIcon>
                  <TabPanelMenuItemTextContainer>
                    <TabPanelMenuItemTitle>{fmi.text}</TabPanelMenuItemTitle>
                    <TabPanelMenuItemText>
                      {originalParentMenuItem?.text}
                    </TabPanelMenuItemText>
                  </TabPanelMenuItemTextContainer>
                </TabPanelMenuItemContent>
              </TabPanelMenuItem>
            );
          })
        ) : (
          <DefaultTabPanelMenuItem title={'No Favorites At This Time'} />
        )}
      </TabPanelList>
    </TabPanelContainer>
  );
};
