import { TabPanelContainer } from 'app';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import {
  useAppDispatch,
  useAppSelector,
  RoleClaim,
  setCurrentRoleClaims,
} from 'api';
import { RoleMaintenanceSaveButton } from './RoleMaintenanceSaveButton';

export const RoleClaimsTabPanel: React.FC<{ value: number; index: number }> = ({
  value,
  index,
}) => {
  const dispatch = useAppDispatch();
  const { selectedRole, currentRoleClaims } = useAppSelector(
    state => state.roleMaintenance
  );

  function handleCheckboxChange(
    e: React.ChangeEvent<HTMLInputElement>,
    roleClaim: RoleClaim
  ) {
    const updatedRoleClaims = currentRoleClaims?.map(ur => {
      return ur.claimValue === roleClaim.claimValue
        ? { ...ur, active: !ur.active }
        : { ...ur };
    });
    dispatch(setCurrentRoleClaims(updatedRoleClaims));
  }
  return (
    <TabPanelContainer index={index} value={value}>
      <Box sx={{ columnCount: 5, minWidth: '800px' }}>
        <FormGroup>
          {currentRoleClaims &&
            selectedRole &&
            currentRoleClaims.map(roleClaim => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={roleClaim.active}
                    onChange={e => handleCheckboxChange(e, roleClaim)}
                  />
                }
                label={roleClaim.description}
              />
            ))}
        </FormGroup>
      </Box>
      <RoleMaintenanceSaveButton />
    </TabPanelContainer>
  );
};
