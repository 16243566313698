import { ReactNode } from 'react';
import parse, { DOMNode, HTMLReactParserOptions } from 'html-react-parser';
import { ISlicerFilter } from 'powerbi-models';

export type PowerBiEmbedProperties = {
  type: string;
  reportId: string;
  reportName: string;
  embedUrl: string;
  embedToken: PowerBiEmbedToken;
  pages: PowerBiReportPage[];
  powerBISlicerState?: PowerBiSlicerState;
};

export type PowerBiReportPage = {
  name: string;
  displayName: string;
  order?: number;
  visibility: number;
};

export type PowerBiEmbedToken = {
  token: string;
  tokenId: string;
  expiration: string;
};

export enum PowerBiPageVisibility {
  Visible,
  Hidde,
}

export type SlicerTarget = {
  table: string;
  column: string;
};

export type PowerBiSlicerState = {
  powerBISlicerStateId: number;
  powerBIReportId: string;
  userId: number;
  slicerState: string;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
  sysStartTime: Date;
  sysEndTime: Date;
  name?: string;
  description?: string;
};

export type PowerBiSlicerFilterState = {
  VisualName: string;
  FilterState?: ISlicerFilter;
};

export type PowerBiSlicerStateRequest = {
  powerBiSlicerStateId?: number;
  powerBiReportId: string;
  menuItemId: number;
  userId: number;
  powerBiSlicerState: string;
  name?: string;
  description?: string;
};

export const convertPowerBiHelpContentToReactElement = (
  helpContent: string
): ReactNode => {
  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      if (domNode.cloneNode.name === 'br') {
        return null;
      }
    },
  };
  return parse(helpContent, options);
};
