import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Drawer, { DrawerProps } from '@mui/material/Drawer';

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const SidebarHeader = styled('div')(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
  },
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface SidebarStyledProps extends DrawerProps {
  drawerWidth: number;
}

export const SidebarStyled = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'drawerWidth',
})<SidebarStyledProps>(({ theme, drawerWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(drawerWidth > 0 && {
    ...openedMixin(theme, drawerWidth),
    '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
  }),
  ...(drawerWidth === 0 && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export interface MenuContainerStyledProps extends BoxProps {
  isDrawerOpen: boolean;
}

export const MenuContainerStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'isDrawerOpen',
})<MenuContainerStyledProps>(({ theme, isDrawerOpen }) => ({
  width: isDrawerOpen ? undefined : '70px',
  '&::-webkit-scrollbar': {
    width: '15px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#d6dee1',
    borderRadius: '20px',
    border: '6px solid transparent',
    backgroundClip: 'content-box',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#a8bbbf',
  },
  '&:hover': {
    overflowY: 'auto',
  },
  overflow: 'hidden',
  height: '100%',
}));
