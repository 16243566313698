import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/axiosBaseQuery';
import {
  GetOneOncologyPatientsResponse,
  GetUnmappedPatientsResponse,
  OneOncologyPatient,
  OneOncologyPatientSearchRequest,
  PatientMappingOptions,
  UnmappedPatient,
  UnmappedPatientSearchRequest,
  UpdateMapPatientRequest,
} from '.';

export const patientMappingApi = createApi({
  reducerPath: 'patientMappingApi',
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    getPatientMappingOptions: builder.query<PatientMappingOptions, void>({
      query: () => ({
        url: `databricks/mapping/patient/options`,
        method: 'GET',
      }),
    }),
    getUnmappedPatients: builder.mutation<
      UnmappedPatient[],
      UnmappedPatientSearchRequest
    >({
      query: UnmappedPatientSearchRequest => {
        return {
          url: `databricks/mapping/patient/unmapped/search`,
          method: 'POST',
          data: UnmappedPatientSearchRequest,
        };
      },
      transformResponse: (
        unmappedPatients: GetUnmappedPatientsResponse[],
        meta,
        request
      ) => {
        const newUnmappedPatients: UnmappedPatient[] = unmappedPatients.map(
          patient =>
            ({
              sourcePatientKey: patient.srcPatientKey,
              ooPatientIKey: patient.ooPatientIKey,
              mrn: patient.mrn,
              firstName: patient.firstName,
              lastName: patient.lastName,
              ssn: patient.ssn,
              dob: patient.dob,
            } as UnmappedPatient)
        );
        return newUnmappedPatients;
      },
    }),
    getOneOncologyPatients: builder.mutation<
      OneOncologyPatient[],
      OneOncologyPatientSearchRequest
    >({
      query: OneOncologyPatientSearchRequest => {
        return {
          url: `databricks/mapping/patient/search`,
          method: 'POST',
          data: OneOncologyPatientSearchRequest,
        };
      },
      transformResponse: (
        oneOncologyPatients: GetOneOncologyPatientsResponse[],
        meta,
        request
      ) => {
        const newOneOncologyPatients: OneOncologyPatient[] =
          oneOncologyPatients.map(
            patient =>
              ({
                fullName: patient.fullName,
                mrn: patient.mrn,
                ooPatientIKey: patient.ooPatientIKey,
                ooClientKey: patient.ooClientKey,
              } as OneOncologyPatient)
          );

        return newOneOncologyPatients;
      },
    }),
    updateMapPatient: builder.mutation<void, UpdateMapPatientRequest>({
      query: UpdateMapPatientRequest => {
        return {
          url: `databricks/mapping/patient`,
          method: 'PUT',
          data: UpdateMapPatientRequest,
        };
      },
    }),
  }),
});

export const {
  useGetPatientMappingOptionsQuery,
  useGetUnmappedPatientsMutation,
  useGetOneOncologyPatientsMutation,
  useUpdateMapPatientMutation,
} = patientMappingApi;
