import { ReactNode } from 'react';
import parse, { DOMNode, HTMLReactParserOptions } from 'html-react-parser';

export type HelpPage = {
  title: string;
  url: string;
  content: string;
};

export const convertHelpContentToReactElement = (
  helpContent: string
): ReactNode => {
  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      if (domNode.cloneNode.name === 'br') {
        return null;
      }
    },
  };
  return parse(helpContent, options);
};
