import { Box, TextField } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { ChangeEventHandler } from 'react';

export const TextInputValue = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;

  const handleFilterChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >
      <TextField
        placeholder="Filter value"
        value={item.value}
        onChange={handleFilterChange}
      />
    </Box>
  );
};
