import { Box, Skeleton } from '@mui/material';
import { GlobalPageContainer } from 'app/App.styled';
import { SidebarSkeleton, TopbarSkeleton } from './';

export const PageSkeleton = () => {
  return (
    <>
      <Box display="flex" flexDirection="row" height="100%">
        <SidebarSkeleton />
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          flex=" 1 1 auto"
          overflow="auto"
        >
          <TopbarSkeleton />
          <GlobalPageContainer>
            <Skeleton
              width="80%"
              height={600}
              sx={{ m: 5, alignSelf: 'center' }}
            />
          </GlobalPageContainer>
        </Box>
      </Box>
    </>
  );
};
