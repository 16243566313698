export type UserAuditLog = {
  action: UserAuditLogAction;
  target: string;
  metadata?: string;
};

export enum UserAuditLogAction {
  Create = 'Create',
  Update = 'Update',
  View = 'View',
}
