import { FormControl, TextField, Box } from '@mui/material';
import { PatientMappingFormContainer } from './PatientMappingWizard.styled';
import {
  useAppDispatch,
  useAppSelector,
  PatientMappingSecondStepState,
  useGetOneOncologyPatientsMutation,
  setCurrentStep,
  OneOncologyPatientSearchParams,
  OneOncologyPatientSearchRequest,
  setSecondStepState,
  setLoadingState,
} from 'api';
import { validateSearchParam } from 'api/validators/patientMappingalidator';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from 'react';

export const PatientMappingSecondStepForm = () => {
  const dispatch = useAppDispatch();
  const { currentStep, firstStepState } = useAppSelector(
    state => state.patientMapping
  );
  const secondStepState = currentStep?.data as PatientMappingSecondStepState;
  const [getOneOncologyPatients, { isLoading }] =
    useGetOneOncologyPatientsMutation();

  useEffect(() => {
    dispatch(setLoadingState(isLoading));
  }, [isLoading, dispatch]);

  const handleChange = (value: string, keyName: string) => {
    let currentStepCpy = {
      ...secondStepState,
      searchParams: {
        ...secondStepState?.searchParams,
        [keyName]: value,
      },
    };

    const error = validateSearchParam(keyName, value);
    if (error) {
      currentStepCpy.searchParams.error = {
        ...currentStepCpy.searchParams.error,
        [keyName]: error,
      };
      currentStepCpy.searchParams.hasError = true;
    } else {
      currentStepCpy.searchParams.error = {
        ...currentStepCpy.searchParams.error,
        [keyName]: '',
      };
      currentStepCpy.searchParams.hasError = false;
    }

    dispatch(
      setCurrentStep({
        step: 2,
        data: currentStepCpy,
      })
    );
  };

  const handleSearch = async () => {
    const searchParams: OneOncologyPatientSearchParams =
      secondStepState?.searchParams;

    const request: OneOncologyPatientSearchRequest = {
      ooClientKey: firstStepState?.searchParams.client!,
      anchorOoClientGroupKey: firstStepState?.searchParams.dataPartner!,
      ooPatientIKey: searchParams?.ooPatientIKey
        ? searchParams?.ooPatientIKey
        : null,
      mrn: searchParams?.mrn,
      firstName: searchParams?.firstName,
      lastName: searchParams?.lastName,
    };

    const response = await getOneOncologyPatients(request).unwrap();
    dispatch(
      setCurrentStep({
        step: 2,
        data: {
          ...secondStepState,
          oneOncologyPatients: response,
        },
      })
    );
    dispatch(
      setSecondStepState({
        oneOncologyPatients: response,
        searchParams: searchParams,
        sourcePatient: secondStepState?.sourcePatient,
      })
    );
  };
  return (
    <PatientMappingFormContainer>
      <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }} size="small">
        <TextField
          id="OOPatientIKey-text"
          label="OOPatientIKey"
          defaultValue=""
          variant="filled"
          type="number"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.value, 'ooPatientIKey')
          }
          value={
            secondStepState?.searchParams?.ooPatientIKey
              ? secondStepState?.searchParams?.ooPatientIKey
              : null
          }
          size="small"
        />
      </FormControl>
      <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }} size="small">
        <TextField
          id="mrn-text"
          label="MRN"
          defaultValue=""
          variant="filled"
          size="small"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.value, 'mrn')
          }
          value={secondStepState?.searchParams?.mrn}
        />
      </FormControl>
      <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }} size="small">
        <TextField
          id="first-name-text"
          label="First Name"
          defaultValue=""
          variant="filled"
          size="small"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.value, 'firstName')
          }
          value={secondStepState?.searchParams?.firstName}
        />
      </FormControl>
      <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }} size="small">
        <TextField
          id="last-name-text"
          label="Last Name"
          defaultValue=""
          variant="filled"
          size="small"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.value, 'lastName')
          }
          value={secondStepState?.searchParams?.lastName}
        />
      </FormControl>
      <Box></Box> {/* This is used to fill in the gap */}
      <Box justifySelf="flex-end">
        <LoadingButton
          variant="contained"
          size="small"
          sx={{
            height: '3em',
            padding: '0 1em 0 1em',
            display: 'flex',
          }}
          onClick={handleSearch}
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SearchIcon />}
        >
          <span style={{ paddingTop: '3px' }}>Search</span>
        </LoadingButton>
      </Box>
    </PatientMappingFormContainer>
  );
};
