import {
  FilterOptionsState,
  Box,
  FormControl,
  createFilterOptions,
} from '@mui/material';
import {
  Role,
  setSelectedRole,
  setAlert,
  AlertSeverity,
  useAddRoleMutation,
  useAppDispatch,
  useGetRolesQuery,
  useAppSelector,
} from 'api';
import { AutocompleteField } from 'app/common/autocomplete';
import { useMemo, useState } from 'react';

const filter = createFilterOptions<Role>();

const defaultRole: Role = {
  id: 0,
  name: '',
  normalizedName: '',
};

export const RoleMaintenanceAutoComplete = () => {
  const dispatch = useAppDispatch();
  const { selectedRole } = useAppSelector(state => state.roleMaintenance);
  const { data: roles, refetch: refetchRoles } = useGetRolesQuery();

  // eslint-disable-next-line
  const displayedRoles = useMemo(() => roles || [defaultRole], [roles]);

  const [addRole] = useAddRoleMutation();
  const [newRoleName, setNewRoleName] = useState('');

  const handleAddNewRole = (newRoleName: string | null) => {
    if (!newRoleName) {
      return;
    }

    const newRole: Role = {
      id: 0,
      name: newRoleName,
      normalizedName: newRoleName,
    };

    addRole(newRole)
      .unwrap()
      .then(resp => {
        refetchRoles();
        dispatch(setSelectedRole(resp));
        dispatch(
          setAlert({
            message: 'Role added.',
            severity: AlertSeverity.Success,
          })
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            message: 'Adding role failed.',
            severity: AlertSeverity.Error,
          })
        );
      });
  };

  const handleFilterOptions = (
    options: Role[],
    params: FilterOptionsState<Role>
  ) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    const isExisting = options.some(
      option => inputValue.toLowerCase() === option.name.toLowerCase()
    );

    if (inputValue !== '' && !isExisting) {
      filtered.push({
        id: -1,
        name: `Add "${inputValue}"`,
        normalizedName: inputValue,
      });
    }

    return filtered;
  };

  // Returns true to indicate a new item is being added
  const handleAutocompleteChange = (
    newValue: string | Role | null
  ): boolean => {
    if (newValue === null) {
      dispatch(setSelectedRole(null));
      return false;
    } else if (typeof newValue === 'string') {
      return false;
    } else if (newValue && newValue.id) {
      if (newValue.id <= 0) {
        newValue.name = newValue.normalizedName;
        setNewRoleName(newValue.name);
        return true;
      }

      const role = roles?.find(r => r.id === newValue.id);
      dispatch(setSelectedRole(role || null));
      return false;
    }

    return false;
  };

  const handleNewRoleNameChange = (newValue: string) => {
    setNewRoleName(newValue);
  };

  return (
    <Box
      width="600px"
      padding="20px"
      display="flex"
      alignItems="center"
      gap="10px"
      margin="auto"
    >
      <FormControl fullWidth>
        <AutocompleteField<Role>
          nameLabel="role"
          optionKeys={['name']}
          selectedValue={selectedRole}
          displayOptions={displayedRoles}
          handleAutocompleteChange={handleAutocompleteChange}
          handleFilterOptions={handleFilterOptions}
          allowCreate={true}
          handleCreateNewItem={handleAddNewRole}
          newItemValue={newRoleName}
          handleNewItemValueChange={handleNewRoleNameChange}
        />
      </FormControl>
    </Box>
  );
};
