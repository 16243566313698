import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import OktaAuth from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import React from 'react';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER
    ? process.env.REACT_APP_OKTA_ISSUER
    : 'https://dev-290965.okta.com/oauth2/default',
  clientId: process.env.REACT_APP_OKTA_CLIENTID
    ? process.env.REACT_APP_OKTA_CLIENTID
    : '0oa19yqlbduAlr6k6358',
  redirectUri: window.location.origin + '/login/callback',
  pkce: true,
});

export const OktaSecurity: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
      replace: true,
    });
  };
  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };
  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();

    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      await triggerLogin();
    }
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      {children}
    </Security>
  );
};
