import { Box, Collapse, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import {
  MenuItemDisplay,
  MenuItemTypes,
  useAppDispatch,
  useAppSelector,
  setSelectedMenuItem,
  defaultMenuItem,
  setCurrentMenuItem,
  useGetMenuItemsQuery,
  MenuItem as MenuItemType,
} from 'api';
import {
  MenuItemListItemStyled,
  ListItemButtonStyled,
  ListItemIconStyled,
} from '.';
import { useNavigate } from 'react-router-dom';

type MenuItemProps = {
  menuItem: MenuItemDisplay;
  child?: boolean;
};

export const MenuItem = ({ menuItem, child }: MenuItemProps) => {
  // Hooks
  const { selectedMenuItem, currentMenuItem } = useAppSelector(
    state => state.menuItem
  );
  const { mockUser } = useAppSelector(state => state.appUser);
  const { data: menuItems } = useGetMenuItemsQuery(mockUser?.id, {
    skip: !mockUser,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isSelected = checkIsSelected();

  function checkIsSelected() {
    if (menuItem.text.toLowerCase() === 'favorites') {
      return selectedMenuItem?.id === menuItem.id;
    } else if (
      menuItems &&
      menuItems.favorites.length > 0 &&
      menuItem.id !== selectedMenuItem?.id
    ) {
      const menuItemFavorites = menuItems?.originalMenuItems.filter(omi =>
        menuItems.favorites.some(
          (fmi: MenuItemType) =>
            fmi.id === omi.id &&
            omi.parentMenuItemText.toLowerCase() !== 'favorites'
        )
      );

      const favoriteMenuItem = menuItemFavorites
        ? menuItemFavorites.find(fmi => fmi.parentId === menuItem.id)
        : undefined;

      if (favoriteMenuItem && favoriteMenuItem?.parentId === menuItem?.id) {
        const originalMenuItemChildren = menuItems.originalMenuItems.filter(
          omi => omi.parentId === menuItem.id
        );

        if (originalMenuItemChildren) {
          const hasSelectedChild = originalMenuItemChildren.find(
            (child: MenuItemType) =>
              child.id === selectedMenuItem?.id ||
              child.id === currentMenuItem?.id
          );

          return (favoriteMenuItem?.parentId === menuItem?.id &&
            hasSelectedChild) as boolean;
        }
      }
    }

    return (
      selectedMenuItem?.id === menuItem.id ||
      selectedMenuItem?.parentId === menuItem.id ||
      currentMenuItem?.id === menuItem.id ||
      currentMenuItem?.parentId === menuItem.id
    );
  }

  const menuItemClicked = () => {
    dispatch(
      setSelectedMenuItem(
        menuItem.children.length > 0 && menuItem.id === selectedMenuItem?.id
          ? defaultMenuItem
          : menuItem
      )
    );

    if (menuItem.type === MenuItemTypes.powerBI) {
      navigate(`/powerbi/${menuItem.id}`);
      dispatch(setCurrentMenuItem(menuItem));
    } else if (menuItem.type === MenuItemTypes.flexData) {
      navigate(`/flexdata/${menuItem.id}`);
      dispatch(setCurrentMenuItem(menuItem));
    } else if (
      menuItem.type === MenuItemTypes.generic ||
      menuItem.type === MenuItemTypes.worklist
    ) {
      if (menuItem.url.startsWith('https://')) {
        window.open(menuItem.url, '_blank');
      } else {
        navigate(menuItem.url);
        dispatch(setCurrentMenuItem(menuItem));
      }
    }
  };

  return (
    <Box>
      <ListItemButtonStyled
        onClick={menuItemClicked}
        child={child}
        selected={isSelected}
      >
        {!child && (
          <ListItemIconStyled>
            <i className={menuItem.iconClass}></i>
          </ListItemIconStyled>
        )}

        <MenuItemListItemStyled
          primary={menuItem.text}
          disableTypography={true}
        />

        {menuItem.children.length > 0 &&
          (isSelected ? (
            <ExpandLess sx={{ justifySelf: 'end', color: '#e7f2f0' }} />
          ) : (
            <ExpandMore sx={{ justifySelf: 'end', color: '#e7f2f0' }} />
          ))}
      </ListItemButtonStyled>
      {menuItem.children.length > 0 && (
        <Collapse in={isSelected} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menuItem.children.map(ci => (
              <MenuItem key={ci.id} menuItem={ci} child />
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  );
};
