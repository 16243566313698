import moment from 'moment';

export function formatDate(date: Date, formatStr: string) {
  // TODO: Update to use format string from the api instead of hardcoded format string
  formatStr = 'yyyy-MM-DD';

  return moment.utc(date).format(formatStr);
}

export function formatSSN(ssn: string) {
  if (!ssn) return '';
  ssn = ssn.replace(/\D/g, '');

  return ssn.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
}
