import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  ListItemIcon,
  ListItemText,
  Autocomplete,
  TextField,
  MenuItem as MuiMenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BiLogInCircle, BiLogOut, BiExit } from 'react-icons/bi';
import { StyledMenu, ToolbarStyled, TopbarStyled } from './Topbar.styled';
import {
  exitImpersonation,
  logoutUser,
  resetMenuItemState,
  ServerApiUrl,
  setCurrentMenuItem,
  setSelectedMenuItem,
  toggleDrawer,
  useAppDispatch,
  useAppSelector,
  useGetMenuItemsQuery,
  useGetUserQuery,
} from '../../../api';
import { MenuItem, MenuItemTypes } from '../../../api/models/menuItem/menuItem';
import { useNavigate } from 'react-router-dom';
import { orange, red } from '@mui/material/colors';
import SearchIcon from '@mui/icons-material/Search';
import { matchSorter } from 'match-sorter';
import { useOktaAuth } from '@okta/okta-react';

export const Topbar = () => {
  // Hooks
  const { oktaAuth } = useOktaAuth();
  const { data: user } = useGetUserQuery();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { mockUser } = useAppSelector(state => state.appUser);
  const { data: menuItems } = useGetMenuItemsQuery(mockUser?.id, {
    skip: !mockUser,
  });
  const expandRef = useRef(null);
  const dispatch = useAppDispatch();
  const { isDrawerOpen } = useAppSelector(state => state.layout);
  const { isImpersonating } = useAppSelector(state => state.appUser);
  const navigate = useNavigate();
  const [childMenuItems, setChildMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    if (menuItems) {
      const menuItemChildren: MenuItem[] = [];
      const getMenuItemChildren = (mi: any) => {
        if (
          mi.children.length === 0 &&
          mi.parentMenuItemText?.toLowerCase() !== 'favorites'
        ) {
          menuItemChildren.push(mi);
        } else {
          mi.children.forEach(getMenuItemChildren);
        }
      };
      menuItems?.menuItemsDisplay.forEach(getMenuItemChildren);

      setChildMenuItems(menuItemChildren);
    }
  }, [menuItems]);

  const filterOptions = (options: MenuItem[], { inputValue }: any) =>
    matchSorter(options, inputValue, {
      keys: ['text', 'tags', 'parentMenuItemText'],
      threshold: matchSorter.rankings.CONTAINS,
    });

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(expandRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };

  const handleLogout = async () => {
    await oktaAuth.signOut();
    localStorage.removeItem('authToken');
    logoutUser();
  };

  const handleImpersonate = () => {
    navigate('/impersonate');
    dispatch(setCurrentMenuItem(undefined));
    dispatch(setSelectedMenuItem(undefined));
  };

  const handleExitImpersonation = () => {
    dispatch(exitImpersonation());
    dispatch(resetMenuItemState());
    navigate('/home');
  };

  const handleUserGuide = () => {
    window.open(ServerApiUrl + 'pdf/oneAnalyticsUserGuide', '_blank');
  };

  const handleSearchMenuItemClick = (menuItem: MenuItem) => {
    if (menuItem.type === MenuItemTypes.powerBI) {
      navigate(`/powerbi/${menuItem.id}`);
      dispatch(setCurrentMenuItem(menuItem));
    } else if (menuItem.type === MenuItemTypes.flexData) {
      navigate(`/flexdata/${menuItem.id}`);
      dispatch(setCurrentMenuItem(menuItem));
    } else if (menuItem.type === MenuItemTypes.generic) {
      navigate(menuItem.url);
      dispatch(setCurrentMenuItem(menuItem));
    }
  };

  return (
    <Box>
      <TopbarStyled isDrawerOpen={isDrawerOpen} position="fixed">
        <ToolbarStyled>
          <Box width="348px">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleToggleDrawer}
              edge="start"
              sx={{ ml: '11px' }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box width="350px">
            <Autocomplete
              forcePopupIcon={false}
              options={childMenuItems}
              size="small"
              filterOptions={filterOptions}
              getOptionLabel={option => option.text || ''}
              renderOption={(props, option) => {
                const parentMenuItem = menuItems?.originalMenuItems.find(
                  mi => mi.id === option.parentId
                );

                return (
                  <Box
                    component="li"
                    display="block !important"
                    flexDirection="column"
                    p=".5em"
                    {...props}
                    key={option.text + option.description}
                    onClick={() => handleSearchMenuItemClick(option)}
                  >
                    <Typography variant="subtitle2" align="left">
                      {option.text}
                    </Typography>
                    <Typography variant="caption" align="left">
                      {parentMenuItem?.text}
                    </Typography>
                  </Box>
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <IconButton size="small">
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              size="small"
              sx={{ height: '3em', minWidth: '100px', margin: '1em' }}
              onClick={handleUserGuide}
            >
              User Guide
            </Button>

            <Button
              onClick={handleMenu}
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                pr: '10px',
                mr: '10px',
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                ref={expandRef}
              >
                <AccountCircle />
              </IconButton>

              <Typography variant="h6" component="div" ref={expandRef}>
                {user?.displayName}
              </Typography>
              <ExpandMoreIcon />
            </Button>
          </Box>

          <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 2,
                mr: 2,
              },
            }}
          >
            {user?.isAdmin && !isImpersonating && (
              <>
                <Box>
                  <MuiMenuItem onClick={handleImpersonate}>
                    <ListItemIcon>
                      <BiLogInCircle fontSize={20} />
                    </ListItemIcon>
                    <ListItemText>Impersonate User</ListItemText>
                  </MuiMenuItem>
                  <Divider />
                </Box>
              </>
            )}
            {!isImpersonating && (
              <MuiMenuItem onClick={handleLogout} sx={{ color: red[400] }}>
                <ListItemIcon sx={{ color: red[400] }}>
                  <BiLogOut fontSize={20} />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MuiMenuItem>
            )}
            {isImpersonating && (
              <MuiMenuItem
                onClick={handleExitImpersonation}
                sx={{ color: orange[400] }}
              >
                <ListItemIcon sx={{ color: orange[400] }}>
                  <BiExit fontSize={20} />
                </ListItemIcon>
                <ListItemText>Exit Impersonation</ListItemText>
              </MuiMenuItem>
            )}
          </StyledMenu>
        </ToolbarStyled>
        <Divider />
      </TopbarStyled>
    </Box>
  );
};
