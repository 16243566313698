import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEditDateCell,
  GridEditInputCell,
  GridEventListener,
  GridRenderEditCellParams,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  MuiEvent,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { ActionType } from './TableTypes';
import {
  ValueOption,
  resetTableModal,
  setOpenTableModal,
  setSelectedRow,
  updateTableRows,
  useAppDispatch,
  useAppSelector,
} from 'api';
import { Autocomplete, Modal, TextField } from '@mui/material';
import { StyledTooltip } from '../tooltip';
import { ModalContainerStyled } from '../modal';

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
    toolbarProps: ToolbarProps;
  }
}

interface ToolbarProps {
  enableAddButton?: boolean;
  enableFilter?: boolean;
  newRowModal?: ReactNode;
}

interface GridToolbarProps {
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
  toolbarProps: ToolbarProps;
}

function GridToolbar({
  setRowModesModel,
  toolbarProps: { enableAddButton, enableFilter, newRowModal },
}: GridToolbarProps) {
  const { rows, defaultValues, openTableModal } = useAppSelector(
    state => state.dataGridTable
  );
  const dispatch = useAppDispatch();

  const handleAddClick = () => {
    if (newRowModal) {
      dispatch(resetTableModal());
      dispatch(setOpenTableModal(true));
    } else handleAddRecord();
  };

  const handleAddRecord = () => {
    const newId = uuidv4();
    dispatch(
      updateTableRows([{ id: newId, isNew: true, ...defaultValues }, ...rows])
    );
    setRowModesModel(oldModel => ({
      ...oldModel,
      [newId]: { mode: GridRowModes.Edit },
    }));
  };

  return (
    <GridToolbarContainer>
      {enableAddButton && (
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddClick}
        >
          Add record
        </Button>
      )}
      {enableFilter && (
        <>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </>
      )}
      <GridToolbarExport />
      <Modal
        open={openTableModal}
        onClose={() => dispatch(setOpenTableModal(false))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainerStyled sx={{ boxShadow: 3 }}>
          {newRowModal}
        </ModalContainerStyled>
      </Modal>
    </GridToolbarContainer>
  );
}

interface DataGridTableProps {
  processRowUpdate: (row: GridRowModel) => Promise<any | undefined>;
  handleDelete?: (row: GridRowModel) => () => Promise<void>;
  isBusy?: boolean;
  enableFilter?: boolean;
  enableAddButton?: boolean;
  toolbarProps?: ToolbarProps;
  cellActions?: ActionType[];
  isRefetching?: boolean;
  enableEditModal?: boolean;
  fullWidth?: boolean;
}

export const DataGridTable: React.FC<DataGridTableProps> = ({
  processRowUpdate,
  handleDelete,
  isBusy,
  cellActions,
  isRefetching,
  toolbarProps,
  enableEditModal,
  fullWidth,
}) => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [gridColumns, setGridColumns] = useState<GridColDef[]>([]);
  const { rows, columns } = useAppSelector(state => state.dataGridTable);
  const dispatch = useAppDispatch();
  const [sortModel, setSortModel] =
    useState<GridSortModel | undefined>(undefined);
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const [muiTableKey, setMuiTableKey] = useState<any>(1);

  const resetFilters = async () => {
    setMuiTableKey(muiTableKey + 1);
  };

  useEffect(() => {
    setSortModel(undefined);
  }, [currentMenuItem]);

  useEffect(() => {
    if (!isRefetching && !sortModel && columns.length) {
      setSortModel(generateSortModel(columns));
      resetFilters();
    }
    // eslint-disable-next-line
  }, [rows, gridColumns, columns, sortModel, isRefetching]);

  const getCellActions = useCallback(
    (row: GridRowModel) => {
      const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onClick={handleSave(row)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancel(row)}
            color="inherit"
          />,
        ];
      }

      let gridActions = [];
      if (cellActions?.find(action => action === ActionType.Edit))
        gridActions.push(
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEdit(row)}
            color="inherit"
          />
        );

      if (handleDelete) {
        gridActions.push(
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDelete(row)}
            color="inherit"
          />
        );
      }
      return gridActions;
    },
    // eslint-disable-next-line
    [rowModesModel]
  );

  useEffect(() => {
    if (columns) {
      setGridColumns(
        cellActions
          ? columns.map((column: GridColDef) => {
              if (column.type === 'actions') {
                return {
                  ...column,
                  getActions: getCellActions,
                  renderEditCell: renderEditCellWithValidation,
                };
              } else if (column.renderEditCell) {
                return { ...column };
              }
              return {
                ...column,
                renderEditCell: renderEditCellWithValidation,
              };
            })
          : columns
      );
    }

    // eslint-disable-next-line
  }, [cellActions, columns, getCellActions]);

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params: any,
    event: any
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEdit = (editedRow: GridRowModel) => () => {
    if (enableEditModal) {
      dispatch(setOpenTableModal(true));
      dispatch(setSelectedRow(editedRow));
    } else {
      setRowModesModel({
        ...rowModesModel,
        [editedRow.id]: { mode: GridRowModes.Edit },
      });
    }
  };

  const handleSave = (newRow: GridRowModel) => () => {
    setRowModesModel({
      ...rowModesModel,
      [newRow.id]: { mode: GridRowModes.View },
    });
  };

  const handleCancel = (cancelRow: GridRowModel) => () => {
    setRowModesModel({
      ...rowModesModel,
      [cancelRow.id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row.id === cancelRow.id);
    if (editedRow!.isNew || editedRow!.isEdited) {
      dispatch(
        updateTableRows(rows.filter((row: any) => row.id !== editedRow?.id))
      );
    }
  };

  function generateSortModel(columns: any[]): GridSortModel {
    let sortModel: GridSortModel = [];
    columns.forEach(column => {
      if (column.sortDirection) {
        sortModel.push({
          field: column.field,
          sort: column.sortDirection === 'asc' ? 'asc' : null,
        });
      }
    });

    return sortModel;
  }

  function renderEditCellWithValidation(params: GridRenderEditCellParams) {
    return <EditInputCell {...params} />;
  }

  const EditInputCell = (props: GridRenderEditCellParams) => {
    const apiRef = useGridApiContext();
    const { id, field, error, colDef, row } = props;

    const handleValueChange = (
      event: React.SyntheticEvent,
      valueOption: any
    ) => {
      const newValue = valueOption
        ? (colDef as any).valueOptions.find(
            (opt: ValueOption) => opt.value === valueOption.value
          )
        : (colDef as any).valueOptions.find(
            (opt: ValueOption) => opt.value === ''
          );

      apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue ? newValue.value : undefined,
      });
    };

    if (props.colDef.type === 'singleSelect') {
      let valueOption;

      if ((colDef as any).valueOptions?.length > 0 && row[colDef.field]) {
        valueOption = (colDef as any).valueOptions.find(
          (opt: ValueOption) =>
            opt.value.toString().toUpperCase() ===
            row[colDef.field].toString().toUpperCase()
        );
      } else {
        valueOption = row[colDef.field];
      }

      return (
        <StyledTooltip open={!!error} title={error}>
          <Autocomplete
            disablePortal
            options={(colDef as any).valueOptions}
            value={valueOption}
            onChange={handleValueChange}
            getOptionLabel={option => {
              let label = '';
              if (
                option &&
                !option.hasOwnProperty('label') &&
                (colDef as any).valueOptions.length > 0
              ) {
                const optionVal = (colDef as any).valueOptions.find(
                  (opt: ValueOption) => opt.value === option
                );
                label = optionVal.label;
              } else if (option && option.label) {
                label = option.label;
              }

              return label;
            }}
            sx={{ width: '100%' }}
            renderInput={props => <TextField {...props} />}
          />
        </StyledTooltip>
      );
    } else if (colDef.type === 'date') {
      return (
        <StyledTooltip open={!!error} title={error}>
          <GridEditDateCell {...props} />
        </StyledTooltip>
      );
    }
    return (
      <StyledTooltip open={!!error} title={error}>
        <GridEditInputCell {...props} />
      </StyledTooltip>
    );
  };

  return (
    <Box
      sx={{
        height: 600,
        '& .actions': {
          color: 'text.secondary',
          width: '100px',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
        // maxWidth: '1200px',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: fullWidth ? '100%' : undefined,
      }}
    >
      <DataGridPro
        slots={{
          toolbar: toolbarProps ? GridToolbar : undefined,
        }}
        slotProps={{
          toolbar: {
            setRowModesModel,
            toolbarProps,
          },
        }}
        rows={rows}
        columns={gridColumns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel: GridRowModesModel) => {
          setRowModesModel(newModel);
        }}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(err: any) => {
          console.log(err);
        }}
        sortModel={sortModel}
        onSortModelChange={newSortModel => {
          setSortModel(newSortModel);
        }}
        initialState={{
          sorting: {
            sortModel: !isRefetching
              ? generateSortModel(gridColumns)
              : undefined,
          },
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        key={muiTableKey}
        sx={{
          mt: '20px',
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            // py: '15px',
            minHeight: '58px !important',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
        getRowHeight={() => 'auto'}
        loading={isBusy}
        pagination
      />
    </Box>
  );
};
