import { createApi } from '@reduxjs/toolkit/query/react';
import { AppUser } from '.';
import { AppRole, addActiveStatusToUserRoles, axiosBaseQuery } from '../../.';

export const appUserApi = createApi({
  reducerPath: 'appUserApi',
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    getUser: builder.query<AppUser, void>({
      query: () => ({
        url: 'users',
        method: 'GET',
      }),
    }),
    getAllUsers: builder.query<AppUser[], void>({
      query: () => ({
        url: 'users/all',
        method: 'GET',
      }),
    }),
    getAllUserRoles: builder.query<AppRole[], void>({
      query: () => ({
        url: 'users/roles',
        method: 'GET',
      }),
      transformResponse: (userRoles: AppRole[], meta, request) =>
        addActiveStatusToUserRoles(userRoles),
    }),
    getRolesForUser: builder.query<AppRole[], number | undefined | null>({
      query: uid => ({
        url: `users/roles/${uid}`,
        method: 'GET',
      }),
    }),
    updateRolesForUser: builder.mutation<
      void,
      { uid: number; roleIds: number[] }
    >({
      query: ({ uid, roleIds }) => {
        return {
          url: `users/roles/${uid}`,
          method: 'PUT',
          data: roleIds,
        };
      },
    }),
    getUsersByRole: builder.query<
      AppUser[],
      { uid: number | undefined | null; rid: number | undefined | null }
    >({
      query: request => ({
        url: `users/${request.uid}/role/${request.rid}/all`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetAllUsersQuery,
  useGetAllUserRolesQuery,
  useGetRolesForUserQuery,
  useUpdateRolesForUserMutation,
  useGetUsersByRoleQuery,
  endpoints: {
    getUser: { useQueryState: useGetUserQueryState },
  },
} = appUserApi;
