import { Card, CardContent, Typography } from '@mui/material';

type PatientCardProps = {
  ooPatientIKey?: string | number;
  sourcePatientKey?: string | number;
  name: string;
  mrn: string;
};

export const PatientCard: React.FC<PatientCardProps> = ({
  ooPatientIKey,
  sourcePatientKey,
  name,
  mrn,
}) => {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {ooPatientIKey
            ? `OOPatientIKey: ${ooPatientIKey}`
            : sourcePatientKey
            ? `Source Patient Key: ${sourcePatientKey}`
            : ''}
        </Typography>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {`MRN: ${mrn}`}
        </Typography>
      </CardContent>
    </Card>
  );
};
