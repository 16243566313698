import { Typography } from '@mui/material';
import { useAppSelector } from 'api';
import { PayerTypeTable } from './PayerTypeTable';
import { PageContainer } from 'app';

export const PayerTypePage = () => {
  const { currentMenuItem } = useAppSelector(state => state.menuItem);

  return (
    <PageContainer>
      <Typography variant="subtitle1">{currentMenuItem?.text}</Typography>
      <PayerTypeTable />
    </PageContainer>
  );
};
