import * as React from 'react';
import { IMaskInput } from 'react-imask';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
  PatientMappingFirstStepState,
  setCurrentStep,
  useAppDispatch,
  useAppSelector,
} from 'api';

interface MaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const SocialSecurityMask = React.forwardRef<HTMLInputElement, MaskProps>(
  function SocialSecurityMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000-00-0000"
        definitions={{
          '0': /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

export const SocialSecurityTextField: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentStep } = useAppSelector(state => state.patientMapping);

  const [values, setValues] = React.useState({
    socialSecurityNumber: 'XXX-XX-XXXX',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    dispatch(
      setCurrentStep({
        step: 1,
        data: {
          ...(currentStep?.data as PatientMappingFirstStepState),
          searchParams: {
            ...(currentStep?.data as PatientMappingFirstStepState)
              ?.searchParams,
            ssn: event.target.value,
          },
        },
      })
    );
  };

  return (
    <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel
        htmlFor="formatted-social-security-number-input"
        sx={{ transform: 'translate(12px, 2px) scale(0.75)' }}
      >
        Social Security Number
      </InputLabel>
      <Input
        value={values.socialSecurityNumber}
        onChange={handleChange}
        name="socialSecurityNumber"
        id="formatted-social-security-number-input"
        inputComponent={SocialSecurityMask as any}
        size="small"
        sx={{ marginTop: '20px !important', paddingLeft: '0.7em' }}
      />
    </FormControl>
  );
};
