import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  setSelectedConceptDomain,
  useAppDispatch,
  useAppSelector,
  useGetConceptDomainsQuery,
} from 'api';
import { useState } from 'react';
import { DatabricksMappingTable } from '.';
import { PageContainer } from 'app';

export const DatabricksMappingPage = () => {
  // Hooks
  const { currentMenuItem } = useAppSelector(state => state.menuItem);
  const { mockUser } = useAppSelector(state => state.appUser);
  const uid = mockUser?.id.toString() ?? '';
  const { data: conceptDomains } = useGetConceptDomainsQuery(uid, {
    skip: !uid,
  });
  const { selectedConceptDomain } = useAppSelector(state => state.databricks);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleChange = (event: SelectChangeEvent<number>) => {
    const found = conceptDomains?.find(
      domain => domain.ooConceptDomainKey === event.target.value
    );
    if (found) dispatch(setSelectedConceptDomain(found));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <PageContainer>
      <Typography variant="subtitle1">{currentMenuItem?.text}</Typography>
      <Box display="flex" flexDirection="column">
        <Box
          sx={{
            width: '100%',
            maxWidth: '1200px',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <FormControl sx={{ marginTop: '20px', width: '300px' }}>
            <InputLabel id="domain-concept-dropdown-label">
              Domain Concept
            </InputLabel>
            <Select
              labelId="domain-concept-dropdown-label"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={
                selectedConceptDomain?.ooConceptDomainKey
                  ? selectedConceptDomain?.ooConceptDomainKey
                  : 0
              }
              label="Concept Domain"
              onChange={handleChange}
            >
              <MenuItem value={0}>None</MenuItem>
              {conceptDomains &&
                conceptDomains.map(domain => (
                  <MenuItem value={domain.ooConceptDomainKey}>
                    {domain.conceptDomainName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <DatabricksMappingTable />
      </Box>
    </PageContainer>
  );
};
