import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/axiosBaseQuery';
import { GridData } from 'api/models/table';
import {
  convertOaInsightSubscribersToDataGrid,
  OaInsightPublication,
  OaInsightSubscriber,
} from '.';

export const oaInsightApi = createApi({
  reducerPath: 'oaInsightApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['OaInsightItems'],
  endpoints: builder => ({
    getOaInsightPublications: builder.query<OaInsightPublication[], void>({
      query: () => ({
        url: `databricks/insight/subscriber/publications`,
        method: 'GET',
      }),
    }),
    getOaInsightSubscribers: builder.query<
      GridData,
      { publicationKey: number }
    >({
      query: ({ publicationKey }) => ({
        url: `databricks/insight/subscriber/publication/${publicationKey}`,
        method: 'GET',
      }),
      transformResponse: (response: OaInsightSubscriber[], meta, arg) =>
        convertOaInsightSubscribersToDataGrid(response),
      providesTags: subscribers => [{ type: 'OaInsightItems', id: 'LIST' }],
    }),
    upsertOaInsightSubscriber: builder.mutation<
      void,
      { subscriber: OaInsightSubscriber }
    >({
      query: ({ subscriber }) => ({
        url: 'databricks/insight/subscriber',
        method: 'POST',
        data: { ...subscriber },
      }),
    }),
    deleteOaInsightSubscriber: builder.mutation<
      void,
      { subscriberKey: number; publicationKey: number }
    >({
      query: ({ subscriberKey, publicationKey }) => ({
        url: `databricks/insight/subscriber/${subscriberKey}/publication/${publicationKey}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetOaInsightPublicationsQuery,
  useGetOaInsightSubscribersQuery,
  useUpsertOaInsightSubscriberMutation,
  useDeleteOaInsightSubscriberMutation,
} = oaInsightApi;
