import { TabPanelContainer } from 'app';
import { Box } from '@mui/material';
import {
  ColumnConfiguration,
  generateColumns,
  useAppSelector,
  useGetUsersByRoleQuery,
} from 'api';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

const assignedUsersColumns: ColumnConfiguration[] = [
  {
    name: 'displayName',
    displayName: 'Display Name',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    flex: 1,
  },
  {
    name: 'userName',
    displayName: 'User Name',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    flex: 1,
  },
  {
    name: 'email',
    displayName: 'Email',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    flex: 1,
  },
];

export const AssignedUsersTabPanel: React.FC<{ value: number; index: number }> =
  ({ value, index }) => {
    const { mockUser } = useAppSelector(state => state.appUser);
    const { selectedRole } = useAppSelector(state => state.roleMaintenance);
    const { data: usersByRole } = useGetUsersByRoleQuery(
      {
        uid: mockUser?.id,
        rid: selectedRole?.id,
      },
      { skip: !mockUser?.id || !selectedRole?.id }
    );

    return (
      <TabPanelContainer index={index} value={value}>
        <Box height="500px" width="800px">
          <DataGridPro
            slots={{ toolbar: GridToolbar }}
            rows={usersByRole ? usersByRole : []}
            columns={generateColumns(assignedUsersColumns)}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            sx={{
              mt: '20px',
              width: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                py: 1,
              },
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                // py: '15px',
                minHeight: '58px !important',
              },
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                py: '22px',
              },
            }}
            getRowHeight={() => 'auto'}
            pagination
          />
        </Box>
      </TabPanelContainer>
    );
  };
