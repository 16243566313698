import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/axiosBaseQuery';
import { UserAuditLog } from '.';

export const userAuditLogApi = createApi({
  reducerPath: 'userAuditLogApi',
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    createUserAuditLog: builder.mutation<void, { auditLog: UserAuditLog }>({
      query: ({ auditLog }) => {
        return {
          url: `log`,
          method: 'POST',
          data: auditLog,
        };
      },
    }),
  }),
});

export const { useCreateUserAuditLogMutation } = userAuditLogApi;
