import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAppDispatch, exitImpersonation, resetMenuItemState } from 'api';
import { ImpersonateButton } from 'app/App.styled';
import { useNavigate } from 'react-router';

export const ImpersonationBanner: React.FC<{ impersonateUser?: string }> = ({
  impersonateUser,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function handleExitImpersonate() {
    dispatch(exitImpersonation());
    dispatch(resetMenuItemState());
    navigate('/home');
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p="20px 40px 20px 40px"
      borderRadius="7px"
      m="10px 30px 10px 30px"
      sx={{ backgroundColor: '#fcf0db' }}
    >
      <Typography>
        You are currently impersonating {impersonateUser}.
      </Typography>
      <Box>
        <ImpersonateButton variant="contained" onClick={handleExitImpersonate}>
          Exit Impersonation
        </ImpersonateButton>
      </Box>
    </Box>
  );
};
