import { Typography } from '@mui/material';
import { useAppSelector } from 'api';
import { MenuItemMaintenanceTable, PageContainer } from 'app';

export const MenuItemMaintenancePage: React.FC = () => {
  const { currentMenuItem } = useAppSelector(state => state.menuItem);

  return (
    <PageContainer>
      <Typography variant="subtitle1">{currentMenuItem?.text}</Typography>
      <MenuItemMaintenanceTable />
    </PageContainer>
  );
};
