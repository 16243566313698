import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OoConceptDomain } from './ooConceptDomain';

type DatabricksState = {
  selectedConceptDomain: OoConceptDomain;
};

const initialState: DatabricksState = {
  selectedConceptDomain: {
    ooConceptDomainKey: 0,
    conceptDomainName: '',
    // createdDate: new Date(),
    createdBy: '',
    // updatedDate: new Date(),
    updatedBy: '',
  },
};

export const databricksSlice = createSlice({
  name: 'databricksSlice',
  initialState,
  reducers: {
    setSelectedConceptDomain: (
      state,
      action: PayloadAction<OoConceptDomain>
    ) => {
      state.selectedConceptDomain = action.payload;
    },
  },
});

export const { setSelectedConceptDomain } = databricksSlice.actions;
