import {
  Box,
  Typography,
  FormControl,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  CircularProgress,
} from '@mui/material';
import {
  useGetAllUsersQuery,
  useAppDispatch,
  AppUser,
  useGetAllUserRolesQuery,
  useGetRolesForUserQuery,
  AppRole,
  useUpdateRolesForUserMutation,
  AlertSeverity,
  setAlert,
} from 'api';
import { useState, useMemo, useEffect } from 'react';
import { grey } from '@mui/material/colors';
import { Check, Clear } from '@mui/icons-material';
import { PageContainer } from 'app';
import { AutocompleteField } from 'app/common/autocomplete';
import { matchSorter } from 'match-sorter';

export const UserMaintenancePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data: users } = useGetAllUsersQuery();
  const [selectedUser, setSelectedUser] = useState<AppUser | null>(null);
  const { data: allUserRoles } = useGetAllUserRolesQuery();
  const { data: userRoles, refetch } = useGetRolesForUserQuery(
    selectedUser?.id
  );

  const defaultUser: AppUser = {
    id: 0,
    firstName: '',
    lastName: '',
    displayName: '',
    isAdmin: false,
    authTimestamp: 0,
    authTimeoutTimestamp: 0,
    email: '',
    userName: '',
  };
  // eslint-disable-next-line
  const displayedUsers = useMemo(() => users || [defaultUser], [users]);

  const [userRolesState, setUserRolesState] = useState<AppRole[]>();
  const [updateAction, { isLoading: isUpdating, isSuccess, isError }] =
    useUpdateRolesForUserMutation();

  useEffect(() => {
    if (allUserRoles) setUserRolesState(allUserRoles);
  }, [allUserRoles]);

  useEffect(() => {
    if (userRoles && allUserRoles && allUserRoles.length > 0) {
      const updateAllUserRoles = allUserRoles
        .map(ur => {
          const foundRole = userRoles.find(r => ur.id === r.id);
          if (foundRole) return { ...foundRole, active: true };
          return { ...ur, active: false };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      setUserRolesState(updateAllUserRoles);
    }
    // eslint-disable-next-line
  }, [userRoles]);

  function handleCheckboxChange(
    e: React.ChangeEvent<HTMLInputElement>,
    role: AppRole
  ) {
    const updatedUserRoles = userRolesState?.map(ur =>
      ur.id === role.id ? { ...ur, active: !ur.active } : { ...ur }
    );
    setUserRolesState(updatedUserRoles);
  }

  async function handleSave() {
    const roleIds: number[] | undefined = userRolesState
      ?.map(ur => (ur.active ? ur.id : undefined))
      .filter((x): x is number => x !== undefined);

    if (roleIds && selectedUser) {
      await updateAction({ uid: selectedUser?.id, roleIds })
        .unwrap()
        .then(() => {
          dispatch(
            setAlert({
              message: 'Roles saved.',
              severity: AlertSeverity.Success,
            })
          );
          refetch();
        })
        .catch(() => {
          dispatch(
            setAlert({
              message: 'Something went wrong.',
              severity: AlertSeverity.Error,
            })
          );
        });
    }
  }

  const getStartIcon = () => {
    if (isUpdating)
      return <CircularProgress size={20} sx={{ color: grey[50] }} />;
    else if (isSuccess) return <Check sx={{ color: grey[50] }} />;
    else if (isError) return <Clear sx={{ color: grey[50] }} />;
  };

  const filterOptions = (options: AppUser[], { inputValue }: any) =>
    matchSorter(options, inputValue, {
      keys: ['email', 'displayName', 'firstName', 'lastName', 'userName'],
      threshold: matchSorter.rankings.CONTAINS,
    });

  // Returns true to indicate a new item is being added
  const handleAutocompleteChange = (
    newValue: string | AppUser | null
  ): boolean => {
    if (newValue === null) {
      setSelectedUser(null);
      return false;
    } else if (typeof newValue === 'string') {
      return false;
    } else if (newValue && newValue.id) {
      const user = users?.find(u => u.id === newValue.id);
      setSelectedUser(user || null);
      return false;
    }

    return false;
  };

  return (
    <PageContainer>
      <Typography variant="subtitle1" p={1}>
        User Maintenance
      </Typography>
      <Box
        width="600px"
        padding="20px"
        display="flex"
        alignItems="center"
        gap="10px"
        margin="auto"
      >
        <FormControl fullWidth>
          <AutocompleteField<AppUser>
            nameLabel="user"
            optionKeys={['displayName', 'email', 'userName']}
            selectedValue={selectedUser}
            displayOptions={displayedUsers}
            handleAutocompleteChange={handleAutocompleteChange}
            handleFilterOptions={filterOptions}
            allowCreate={false}
            handleCreateNewItem={null}
            newItemValue={null}
            handleNewItemValueChange={null}
          />
        </FormControl>
      </Box>
      <Box sx={{ columnCount: 5 }}>
        <FormGroup>
          {userRolesState &&
            selectedUser &&
            userRolesState.map(role => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={role.active}
                    onChange={e => handleCheckboxChange(e, role)}
                  />
                }
                label={role.name}
              />
            ))}
        </FormGroup>
      </Box>
      <Box display="flex" justifyContent="center" paddingTop="5px">
        {userRolesState && selectedUser && (
          <Button
            variant="contained"
            size="large"
            sx={{ width: '300px', display: 'flex', alignItems: 'center' }}
            onClick={handleSave}
            startIcon={getStartIcon()}
          >
            <Typography>Save</Typography>
          </Button>
        )}
      </Box>
    </PageContainer>
  );
};
