import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { Button, ButtonProps } from '@mui/material';
import { orange } from '@mui/material/colors';

export const GlobalPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '70px 10px 0px 10px',
  height: '100%',
}));

interface PageProps extends BoxProps {
  powerBi?: boolean;
}

export const Page = styled(Box, {
  shouldForwardProp: prop => prop !== 'powerBi',
})<PageProps>(({ theme, powerBi }) => ({
  margin: powerBi ? '20px 0px 0px 0px' : '20px 100px 0 100px',
  flex: '1 1 auto',
}));

export const ImpersonateButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(orange[500]),
  backgroundColor: orange[500],
  '&:hover': {
    backgroundColor: orange[700],
  },
}));
